import React, {useEffect, useState} from 'react';
import style from './TransactionItem.module.scss'
import {TableCell, TableRow} from "@mui/material";
import incomeIcon from "../../../../assets/images/income.png"
import withdraw from "../../../../assets/images/withdraw.png"
import {Link} from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

import ReplayIcon from '@mui/icons-material/Replay';
import Rating from "../../profile-transactions/profile-transaction-item/Rating";
import {useSelector} from "react-redux";
import GoodFood from "../../profile-edit/survey-icon/GoodFood";
import GoodDrink from "../../profile-edit/survey-icon/GoodDrink";
import GoodMusic from "../../profile-edit/survey-icon/GoodMusic";
import GoodService from "../../profile-edit/survey-icon/GoodService";
import LikeEuro from "../../profile-edit/survey-icon/LikeEuro";
import CleanIcon from "../../profile-edit/survey-icon/CleanIcon";

function TransactionItem({amount, date, time, status, rating, comment, type, goodDrink, cleanPremises, goodFood, goodMusic, goodService, likeEuro}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const newDate = new Date(date);
    const optionsDate = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    // const formattedDate = newDate.toLocaleDateString('en-US', optionsDate);
    const formattedDate = `${newDate.getDate().toString().padStart(2, '0')}/${(newDate.getMonth() + 1).toString().padStart(2, '0')}/${newDate.getFullYear().toString().slice(-2)}`;
    const newTime = new Date(time);
    const optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
    const formattedTime = newTime.toLocaleTimeString('en-US', optionsTime);

    return (
        <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            sx={{ '&:last-child td, &:last-child th': { border: 0} }}
            className={darkMode ? style.transaction_item + " dark-block-bg" : style.transaction_item}
        >
            <TableCell align="left" className={style.transaction_item_icon}><img src={type === "tip" ? incomeIcon : withdraw} alt="incomeIcon"/></TableCell>
            <TableCell align="left" className={darkMode && "light-text"}>
                <span>{formattedDate}</span>
                <br/>
                <span>{formattedTime}</span>
            </TableCell>
            {/*<TableCell align="left" className={darkMode && "light-text"}></TableCell>*/}
            <TableCell align="left" className={type === "transfer" ? style.transaction_item_amount + " " + style.payout : style.transaction_item_amount + " " + style.payIn}>{type === "transfer" ? "-" + amount : "+" + amount}</TableCell>
            <TableCell align="left" className={darkMode ? style.transaction_item_rating + " light-text" : style.transaction_item_rating}><Rating rating={rating}/></TableCell>
            {/*<TableCell align="left" className={darkMode ? style.transaction_item_comment + " light-text" : style.transaction_item_comment}>{comment ? comment : "-"}</TableCell>*/}
            <TableCell align="left" className={darkMode ? style.transaction_item_comment + " light-text" : style.transaction_item_comment}>
                {goodFood === 1 ? <GoodFood active="true" width={30}/> : " "}&nbsp;
                {goodDrink === 1 ? <GoodDrink active="true" width={30}/> : " "}&nbsp;
                {goodMusic === 1 ? <GoodMusic active="true" width={30}/> : " "}&nbsp;
                {goodService === 1 ? <GoodService active="true" width={30}/> : " "}&nbsp;
                {likeEuro === 1 ? <LikeEuro active="true" width={30}/> : " "}&nbsp;
                {cleanPremises === 1 ? <CleanIcon active="true" width={30}/> : " "}&nbsp;
            </TableCell>
            {/*<TableCell align="left" className={style.transaction_item_status}>*/}
            {/*    <span className={status === "SUCCEEDED" ? style.success : status === "PENDING" ? style.pending : style.failed}>*/}
            {/*        {status === "FAILED" || status === "PaymentCanceled" ? <CancelSharpIcon/> : <CheckCircleIcon/>}*/}
            {/*    </span>*/}
            {/*</TableCell>*/}
            {/*<TableCell align="left"  className={style.transaction_repeat}><Link to="/profile/transfer">{type === "transfer" ? <ReplayIcon color="#0666EB"/> : "-"}</Link></TableCell>*/}
        </TableRow>
    );
}

export default TransactionItem;
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    user: [],
    refreshUser: [],
    auth: false,
    smsSendUrl: "",
    forgotUser: [],
    isLoading: false,
    imagePath: "",
    companyImagePath: "",
    firstName: "",
    lastName: "",
    userName: "",
    userEmail: "",
    birthDate: "",
    profession: "",
    companyName: "",
    userCountry: "",
    userCity: "",
    region: "",
    streetLine1: "",
    streetLine2: "",
    welcomePhrase: "",
    thankYouPhrase: "",
    isEmailNotification: false,
    isReviewInPaymentPage: false,
    isRatingInPaymentPage: false,
    isCleanPremisesPaymentPage: false,
    isGoodDrinksPaymentPage: false,
    isGoodFoodPaymentPage: false,
    isGoodMusicPaymentPage: false,
    isGoodServicePaymentPage: false,
    isLikeEuroPaymentPage: false,
    userRating: 4.9,
    userFacebook: "",
    userInstagram: "",
    userWhatsapp: "",
    userLinkedIn: "",
    userTikTok: "",
    userYoutube: "",
    userPhone: "",
    avatarModal: false,
    companyLogoModal: false,
    userVerification: 0,
    userCollected: 0,
    userTotalAmount: "",
    userMinimumAmount: "1"
}
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addUser: (state, action) => {
            // state.user = [...state.user, action.payload];
            state.user = action.payload;
        },
        refreshAddUser: (state, action) => {
            state.refreshUser = action.payload;
        },
        forgotUser: (state, action) => {
            state.forgotUser = [...state.forgotUser, action.payload];
        },
        RemoveUser: (state) => {
            state.user = [];
        },
        Auth:(state, action) => {
            state.auth = action.payload
        },
        SMSUrl:(state, action) => {
            state.smsSendUrl = action.payload
        },
        IsEditAvatar: (state, action) => {
            state.isEdit = action.payload
        },
        ImagePath: (state, action) => {
            state.imagePath = action.payload;
        },
        CompanyImagePath: (state, action) => {
            state.companyImagePath = action.payload;
        },
        UserName: (state, action) => {
            state.userName = action.payload;
        },
        FirstName: (state, action) => {
            state.firstName = action.payload;
        },
        LastName: (state, action) => {
            state.lastName = action.payload;
        },
        BirthDate: (state, action) => {
            state.birthDate = action.payload;
        },
        Profession: (state, action) => {
            state.profession = action.payload;
        },
        CompanyName: (state, action) => {
            state.companyName = action.payload;
        },
        Country: (state, action) => {
            state.userCountry = action.payload;
        },
        City: (state, action) => {
            state.userCity = action.payload;
        },
        Region: (state, action) => {
            state.region = action.payload;
        },
        StreetLine1: (state, action) => {
            state.streetLine1 = action.payload;
        },
        StreetLine2: (state, action) => {
            state.streetLine2 = action.payload;
        },
        Email: (state, action) => {
            state.userEmail = action.payload;
        },
        WelcomePhrase: (state, action) => {
            state.welcomePhrase = action.payload;
        },
        ThankYouPhrase: (state, action) => {
            state.thankYouPhrase = action.payload;
        },
        IsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        EmailNotification: (state, action) => {
            state.isEmailNotification = action.payload;
        },
        IsReview: (state, action) => {
            state.isReviewInPaymentPage = action.payload;
        },
        IsRating: (state, action) => {
            state.isRatingInPaymentPage = action.payload;
        },
        UserRating: (state, action) => {
            state.userRating = action.payload;
        },
        UserFacebook: (state, action) => {
            state.userFacebook = action.payload;
        },
        UserInstagram: (state, action) => {
            state.userInstagram = action.payload;
        },
        UserWhatsapp: (state, action) => {
            state.userWhatsapp = action.payload;
        },
        UserLinkedin: (state, action) => {
            state.userLinkedIn = action.payload;
        },
        UserTikTok: (state, action) => {
            state.userTikTok = action.payload;
        },
        UserYoutube: (state, action) => {
            state.userYoutube = action.payload;
        },
        AvatarModal: (state, action) => {
            state.avatarModal = action.payload;
        },
        CompanyLogoModal: (state, action) => {
            state.companyLogoModal = action.payload;
        },
        UserPhone: (state, action) => {
            state.userPhone = action.payload;
        },
        UserVerification: (state, action) => {
            state.userVerification = action.payload;
        },
        UserCollected: (state, action) => {
            state.userCollected = action.payload;
        },
        UserTotalAmount: (state, action) => {
            state.userTotalAmount = action.payload;
        },
        UserMinimumAmount: (state, action) => {
            state.userMinimumAmount = action.payload;
        },
        IsGoodDrinksPaymentPage: (state, action) => {
            state.isGoodDrinksPaymentPage = action.payload;
        },
        IsCleanPremisesPaymentPage: (state, action) => {
            state.isCleanPremisesPaymentPage = action.payload;
        },
        IsGoodFoodPaymentPage: (state, action) => {
            state.isGoodFoodPaymentPage = action.payload;
        },
        IsGoodMusicPaymentPage: (state, action) => {
            state.isGoodMusicPaymentPage = action.payload;
        },
        IsGoodServicePaymentPage: (state, action) => {
            state.isGoodServicePaymentPage = action.payload;
        },
        IsLikeEuroPaymentPage: (state, action) => {
            state.isLikeEuroPaymentPage = action.payload;
        },
    }
})

export const {
    IsLikeEuroPaymentPage,
    IsGoodServicePaymentPage,
    IsGoodMusicPaymentPage,
    IsGoodFoodPaymentPage,
    IsCleanPremisesPaymentPage,
    addUser,
    Auth,
    RemoveUser,
    SMSUrl,
    forgotUser,
    IsGoodDrinksPaymentPage,
    refreshAddUser,
    ImagePath,
    UserName,
    FirstName,
    LastName,
    BirthDate,
    Profession,
    CompanyName,
    Country,
    City,
    Region,
    StreetLine1,
    StreetLine2,
    Email,
    WelcomePhrase,
    ThankYouPhrase,
    IsLoading,
    EmailNotification,
    IsReview,
    UserRating,
    UserFacebook,
    UserInstagram,
    UserWhatsapp,
    UserLinkedin,
    UserTikTok,
    UserYoutube,
    CompanyImagePath,
    AvatarModal,
    CompanyLogoModal,
    UserPhone,
    UserVerification,
    UserCollected,
    UserTotalAmount,
    IsRating,
    UserMinimumAmount
} = userSlice.actions;

export default userSlice.reducer
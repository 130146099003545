import React from 'react';
import style from './FrequentlyAskedQuestions.module.scss';
import {Accordion} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
function FrequentlyAskedQuestions(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();

    return (
        <section className={darkMode ? style.frequently_asked_questions + " dark-section-bg light-text" : style.frequently_asked_questions}>
            <div className="container">
                <div className="row">
                    <div className="section_small_title text-center" data-aos="fade-down" data-aos-duration="1500">{t("Frequently asked questions")}</div>
                    <Accordion defaultActiveKey="0" flush className={style.accordeon}>
                        <Accordion.Item eventKey="0" data-aos="fade-right" data-aos-duration="1500" className={darkMode ? " dark-block-bg light-text" : ""}>
                            <Accordion.Header className={darkMode ? "light-text" : ""}>{t("How are funds withdrawn from the service?")}</Accordion.Header>
                            <Accordion.Body>{t("Withdrawal of funds is making from the User Account Control Panel. Select menu Transfer to sending the money to your personal bank account / IBAN without restrictions.")}</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" data-aos="fade-right" data-aos-duration="1500" className={darkMode ? " dark-block-bg light-text" : ""}>
                            <Accordion.Header className={darkMode ? "light-text" : ""}>{t("TipsSi service tariffs")}</Accordion.Header>
                            <Accordion.Body>{t("At the moment we have set a commission of 5% of amount and only for the withdrawal of cash funds to your personal account (IBAN).")}</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" data-aos="fade-right" data-aos-duration="1500" className={darkMode ? " dark-block-bg light-text" : ""}>
                            <Accordion.Header className={darkMode ? "light-text" : ""}>{t("How do I get business cards with a QR-code?")}</Accordion.Header>
                            <Accordion.Body>{t("You need to login and inside your Control Panel you will find the PRINT menu section where you can choose a different card design and download it for printing.")}</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" data-aos="fade-right" data-aos-duration="1500" className={darkMode ? " dark-block-bg light-text" : ""}>
                            <Accordion.Header className={darkMode ? "light-text" : ""}>{t("Can I transfer my tip to my colleague?")}</Accordion.Header>
                            <Accordion.Body>{t("You can transfer tips between users without commission, for this you need to know the user number of the recipient of the tip.")}</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4" data-aos="fade-right" data-aos-duration="1500" className={darkMode ? " dark-block-bg light-text" : ""}>
                            <Accordion.Header className={darkMode ? "light-text" : ""}>{t("What is the minimum withdrawal amount?")}</Accordion.Header>
                            <Accordion.Body>{t("The minimum withdrawal amount is 10 euros per withdrawal. There is no maximum limit.")}</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5" data-aos="fade-right" data-aos-duration="1500" className={darkMode ? " dark-block-bg light-text" : ""}>
                            <Accordion.Header className={darkMode ? "light-text" : ""}>{t("Do I have to pay tax on e-tips?")}</Accordion.Header>
                            <Accordion.Body>{t("Tips in any form are not taxable. It is a gratuitous and voluntary giving (transferring) of money by customers of establishments directly to recipients / users as part of a gift contract. And there is no tax on gifts.")}</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6" data-aos="fade-right" data-aos-duration="1500" className={darkMode ? " dark-block-bg light-text" : ""}>
                            <Accordion.Header className={darkMode ? "light-text" : ""}>{t("Refund and Cancellation Tips.")}</Accordion.Header>
                            <Accordion.Body>{t("To cancel and refund payments, please contact us via the Contact menu.")}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default FrequentlyAskedQuestions;
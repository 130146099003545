import React from 'react';
import style from './Privacy.module.scss';
import {useSelector} from "react-redux";
function PrivacyPolicyComponent(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);

    return (
        <div className={darkMode ? style.policy + " dark-block-bg light-text col-12" :  style.policy + " col-12"}>
            <div className={style.big_title}>POLÍTICA DE PRIVACIDAD</div>
            <p className={style.text}>Bienvenido al Aviso de Privacidad de TipsSi.</p>
            <p className={style.text}>Este Aviso de privacidad le informará sobre cómo cuidamos sus datos personales
                cuando visita nuestro sitio web, utiliza los productos y servicios que ofrecemos, incluso a través de
                nuestras Plataformas, le informamos sobre sus derechos de privacidad y cómo lo protege la ley.</p>
            <p className={style.text}>Su acceso y uso de nuestras Plataformas, incluida la página de su cuenta y
                cualquier área segura, está sujeto en todo momento a nuestros Términos de uso. Si paga propinas a
                nuestros Clientes, su uso estará sujeto a nuestros Términos del Cliente Si recopila propinas a través de
                nuestra Plataforma, estará sujeto a nuestros Términos del cliente Si otorga permiso a sus empleados para
                usar nuestro software en el curso de su empleo y se integran con nosotros, estará sujeto a nuestro
                Acuerdo de operador.</p>
            <div className={style.title}>1.Información</div>
            <p className={style.text}>importante y quiénes somos TipsSi respeta su privacidad y se compromete a proteger
                sus datos personales. Es importante que lea este Aviso de Privacidad.</p>
            <p className={style.text}>Esta versión es efectiva a partir del 01 de abril de 2023.</p>
            <p className={style.text}>Actualizaremos este Aviso de privacidad de vez en cuando para reflejar cualquier
                cambio o cambio propuesto en nuestro uso de sus datos personales, o para cumplir con los cambios en la
                ley aplicable o los requisitos reglamentarios. Es posible que le notifiquemos por correo electrónico
                sobre cualquier cambio significativo en este Aviso de privacidad, pero lo alentamos a que revise este
                Aviso de privacidad periódicamente para mantenerse actualizado sobre cómo usamos sus datos personales.
                Quiénes somos (Controlador de datos) Hay Conexion SL, sociedad constituida en España con número de
                registro B44872653, con domicilio social en Barcelona es el responsable del tratamiento y responsable de
                sus datos personales "nosotros", "nos", "nuestro" o “TipsSi” en este Aviso de Privacidad).</p>
            <p className={style.text}>Detalles de contacto</p>
            <p className={style.text}>Si tiene alguna pregunta sobre este Aviso de privacidad o nuestras prácticas de
                privacidad, comuníquese con nosotros de las siguientes maneras:</p>
            <p className={style.text}>Dirección postal: Calle Consell de Cent 383, 1-1, 08009.</p>
            <p className={style.text}>Dirección de correo electrónico: tipssi@tipssi.net</p>
            <p className={style.text}>1.1 La Plataforma de gestión es proporcionada por HAY CONEXION, S.L. (En adelante TipsSI) con domicilio social en Barcelona, Consell de Cent 383, 1-1 08009, con CIF B44872653.</p>
            <div className={style.title}>2. Los datos que recopilamos sobre usted</div>
            <p className={style.text}>Datos personales, o información personal, significa cualquier información sobre un individuo a partir de la cual se puede identificar a esa persona. No incluye datos donde se ha eliminado la identidad (datos anónimos).</p>
            <p className={style.text}>Podemos recopilar, usar, almacenar y transferir diferentes tipos de datos personales sobre usted que hemos agrupado de la siguiente manera:</p>
            <ul>
                <li className={style.text}>Datos de identidad: incluye, entre otros, nombre, apellido de soltera, apellido, nombre de usuario o identificador similar.</li>
                <li className={style.text}>Datos de contacto: incluye dirección de correo electrónico y números de teléfono.</li>
                <li className={style.text}>Datos financieros: incluye detalles de la cuenta bancaria y detalles de la tarjeta de pago tokenizada. No almacenamos los datos de su tarjeta de crédito directamente.</li>
                <li className={style.text}>Datos de transacción: incluye detalles sobre los pagos realizados entre usted y nosotros; detalles de los productos comprados de nosotros.</li>
                <li className={style.text}>Datos técnicos: incluye la dirección del protocolo de Internet (IP), sus datos de inicio de sesión, el tipo y la versión del navegador, la configuración y la ubicación de la zona horaria, los tipos y versiones de los complementos del navegador, el sistema operativo y la plataforma, y otra tecnología en los dispositivos que utiliza para acceder. este sitio web.</li>
                <li className={style.text}>Datos de perfil: incluye el nombre de usuario de la cuenta; contraseña; foto de perfil o avatar; sus preferencias de contactos; el contenido de cualquier mensaje que envíe utilizando cualquier formulario de consulta o función de chat en el sitio.</li>
                <li className={style.text}>Datos de marketing y comunicaciones: incluye sus preferencias para recibir marketing de nuestra parte.</li>
            </ul>
            <p className={style.text}>También recopilamos, usamos y compartimos datos agregados, como datos estadísticos o para cualquier propósito. Los datos agregados pueden derivarse de sus datos personales, pero no se consideran datos personales por ley, ya que estos datos no revelarán directa o indirectamente su identidad. Todos los datos que acumulemos serán gestionados por TipsS</p>
            <p className={style.text}>No recopilamos ninguna categoría especial de datos personales sobre usted (esto incluye detalles sobre su raza o etnia, creencias religiosas o filosóficas, vida sexual, orientación sexual, opiniones políticas, afiliación sindical, información sobre su salud y datos genéticos y biométricos). ). Tampoco recopilamos información sobre condenas y delitos penales.</p>
            <p className={style.text}>Si no proporciona sus datos personales</p>
            <p className={style.text}>Cuando la ley nos exija recopilar sus datos personales, o necesitemos recopilar sus datos personales según los términos de un contrato que tengamos con usted, y usted no proporcione esos datos personales cuando los solicitemos, es posible que no podamos ejecutar el contrato que tenemos o estamos tratando de celebrar con usted. Esto puede aplicarse cuando no proporciona los datos personales que necesitamos para proporcionar los Productos y Servicios que nos ha solicitado o para procesar una solicitud para registrar una cuenta. En estas circunstancias, es posible que tengamos que cancelar su solicitud o la provisión de los Productos y Servicios correspondientes, en cuyo caso se lo notificaremos.</p>
            <div className={style.title}>3. ¿Cómo se recopilan sus datos personales?</div>
            <p className={style.text}>Utilizamos diferentes métodos para recopilar datos de usted y sobre usted, incluso a través de:</p>
            <ul>
                <li className={style.text}>Interacciones directas. Puede proporcionarnos su identidad, contacto y datos financieros cuando solicita nuestros productos o servicios, crea una cuenta en nuestra plataforma, se suscribe a nuestro servicio o publicaciones, nos da su opinión o se pone en contacto con nosotros.</li>
                <li className={style.text}>Tecnologías o interacciones automatizadas. A medida que interactúa con nuestro sitio web, recopilaremos automáticamente datos técnicos sobre su equipo, acciones de navegación y patrones. Recopilamos estos datos personales mediante el uso de cookies y otras tecnologías similares. Esto incluye proveedores de análisis como Google, como describe nuestra Plataforma de gestión de consentimiento.</li>
                <li className={style.text}>Podemos recopilar algunos datos a través de Su acceso y uso de nuestras Plataformas, incluida la página de su cuenta, sujeto a nuestros Términos de uso, Términos del cliente, Términos del cliente y Acuerdo del operador.</li>
            </ul>
            <div className={style.title}>4. Sobre quién recopilamos datos personales</div>
            <p className={style.text}>Podemos recopilar y procesar sus datos personales si usted es uno de los siguientes:</p>
            <ul>
                <li className={style.text}>Clientes. Si se registra con nosotros para recopilar consejos, podemos recopilar y procesar sus datos personales para cumplir con nuestras obligaciones hacia usted en virtud de nuestros Términos del cliente.</li>
                <li className={style.text}>Clientes. Si da propina usando TipsSI, recopilamos y procesamos sus datos personales para recopilar su propina y pasarla a la persona o equipo al que le dio propina, e informarles sobre su propina, incluido cuándo y dónde dio propina y su nombre sujeto a nuestro Condiciones del cliente</li>
                <li className={style.text}>Operadores. Si decide integrar su software de administración de POS con nuestra plataforma, podemos recopilar y procesar sus datos personales en relación con el suministro de estos servicios sujeto a nuestro Acuerdo de Operador</li>
            </ul>
            <div className={style.title}>5. Cómo usamos sus datos personales</div>
            <p className={style.text}>Por lo general, utilizaremos sus datos personales en las siguientes circunstancias:</p>
            <ul>
                <li className={style.text}>Cuando necesitemos ejecutar el contrato que estamos a punto de celebrar o hemos celebrado con usted.</li>
                <li className={style.text}>Cuando sea necesario para nuestros intereses legítimos (o los de un tercero) y sus intereses y derechos fundamentales no anulen esos intereses.</li>
                <li className={style.text}>Cuando necesitemos cumplir con una obligación legal. Finalidades para las que utilizaremos sus datos personales Hemos establecido a continuación, en formato de tabla, una descripción de todas las formas en que planeamos utilizar sus datos personales, y en cuáles de las bases legales nos basamos para hacerlo.</li>
            </ul>
            <table className="col-12">
                <thead>
                    <tr className={style.text}>
                        <th>Proposito/Actividad</th>
                        <th>Tipo de base de datos</th>
                        <th>legal para el procesamiento, incluida la base del interés legítimo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={style.text}>Registrarse como nuevo cliente</td>
                        <td>
                            <p className={style.text}>(a) Identidad</p>
                            <p className={style.text}>(b) Contacto</p>
                        </td>
                        <td className={style.text}>Ejecución de un contrato con usted</td>
                    </tr>
                    <tr>
                        <td>
                            <p className={style.text}>Si compra Productos y Servicios en nuestro Sitio, incluidos:</p>
                            <p className={style.text}>(a) Administrar pagos, tarifas y cargos</p>
                            <p className={style.text}>(b) Cobrar y recuperar el dinero que se nos debe</p>
                            <p className={style.text}>(c) Verificar identidad</p>
                            <p className={style.text}>(d) Prevenir el fraude</p>
                        </td>
                        <td>
                            <p className={style.text}>(a) Identidad</p>
                            <p className={style.text}>(b) Contacto</p>
                            <p className={style.text}>(c) Financiero</p>
                            <p className={style.text}>(d) Transacción</p>
                        </td>
                        <td>
                            <p className={style.text}>(a) (Ejecución de un contrato con usted</p>
                            <p className={style.text}>(b) Necesario para nuestros intereses legítimos (para recuperar deudas
                                con nosotros)</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className={style.text}>Para gestionar nuestra relación con usted, que incluirá:</p>
                            <p className={style.text}>(a) Notificarle sobre cambios en nuestros términos o Aviso de
                                privacidad</p>
                            <p className={style.text}>(b) Pedirle que deje una reseña o responda una encuesta</p>
                        </td>
                        <td>
                            <p className={style.text}>(a) Identidad</p>
                            <p className={style.text}>(b) Contacto</p>
                            <p className={style.text}>(c) Perfil</p>
                            <p className={style.text}>(d) Mercadeo y Comunicaciones</p>
                        </td>
                        <td>
                            <p className={style.text}>(a) (Ejecución de un contrato con usted</p>
                            <p className={style.text}>(b) Necesario para cumplir con una obligación legal</p>
                            <p className={style.text}>(c) Necesario para nuestros intereses legítimos (para mantener
                                nuestros registros actualizados y para estudiar cómo los clientes usan nuestros
                                servicios)</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className={style.text}>Para administrar y proteger nuestro negocio y este sitio web (incluida
                                la resolución de problemas, el análisis de datos, las pruebas, el mantenimiento del sistema,
                                el soporte, la generación de informes y el alojamiento de datos)</p>
                        </td>
                        <td>
                            <p className={style.text}>(a) Identidad</p>
                            <p className={style.text}>(b) Contacto</p>
                            <p className={style.text}>(c) Técnico</p>
                        </td>
                        <td>
                            <p className={style.text}>(a) Necesario para nuestros intereses legítimos (para el
                                funcionamiento de nuestro negocio, la prestación de servicios de administración y TI, la
                                seguridad de la red, para prevenir el fraude y en el contexto de una reorganización
                                empresarial o un ejercicio de reestructuración de grupo)</p>
                            <p className={style.text}>(b) Necesario para cumplir con una obligación legal</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className={style.text}>Para utilizar el análisis de datos para mejorar nuestro sitio web, servicios, marketing, relaciones con los clientes y experiences o cuando se vincula a sitios de redes sociales e interactúa con nuestras páginas de redes sociales</p>
                        </td>
                        <td>
                            <p className={style.text}>(a) Técnica</p>
                            <p className={style.text}>(b) Uso</p>
                        </td>
                        <td>
                            <p className={style.text}>Necesario para nuestros intereses legítimos (para definir tipos de clientes para nuestros productos yservicios, para mantener nuestro sitio web actualizado y relevante, para desarrollar nuestro negocio ypara informar nuestra estrategia de marketing)</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className={style.text}>Nuestros boletines</p>
            <p className={style.text}>Recibirá nuestras comunicaciones de marketing y nuestro boletín si nos ha solicitado información o nos ha comprado servicios y no ha optado por no recibir ese marketing. Optar por no participar</p>
            <p className={style.text}>Cuando opte por no recibir estos mensajes, esto no se aplicará a los datos personales que nos proporcione como resultado de la compra de un servicio. Toma de decisiones automatizada</p>
            <p className={style.text}>Nuestros algoritmos antifraude pueden analizar los patrones de las propinas, como los montos, la frecuencia y los límites mensuales, tal como se describe en nuestros Términos del cliente y Términos del cliente.</p>
            <div className={style.title}>6. Divulgaciones de sus datos personales</div>
            <p className={style.text}>Algunos de sus datos pueden compartirse con las partes que se indican a continuación para los fines establecidos en la lista:</p>
            <ul>
                <li className={style.text}>Terceros internos, por ejemplo, miembros de su grupo profesional, como su superior jerárquico, pueden ver la cantidad de propinas que ha recibido, cuando usted les autorice a hacerlo.</li>
                <li className={style.text}>Terceros externos, como proveedores de servicios que actúan como procesadores con sede en la Unión Europea que brindan servicios de administración de sistemas y TI.</li>
            </ul>
            <div className={style.title}>7. Seguridad de los datos</div>
            <p className={style.text}>Hemos implementado las medidas de seguridad adecuadas para evitar que sus datos personales se pierdan, utilicen o accedan accidentalmente de forma no autorizada, se modifiquen o se divulguen. Además, limitamos el acceso a sus datos personales a aquellos empleados, agentes, contratistas y otros terceros que tienen una necesidad comercial de conocerlos. Solo tratarán sus datos personales siguiendo nuestras instrucciones y están sujetos a un deber de confidencialidad.</p>
            <p className={style.text}>Hemos implementado procedimientos para hacer frente a cualquier sospecha de violación de datos personales y le notificaremos a usted y a cualquier regulador aplicable de una violación cuando estemos legalmente obligados a hacerlo.</p>
            <div className={style.title}>8. Retención de datos</div>
            <p className={style.text}>¿Durante cuánto tiempo usarán mis datos personales?</p>
            <p className={style.text}>Solo conservaremos sus datos personales durante el tiempo que sea razonablemente necesario para cumplir con los fines para los que los recopilamos, incluso para cumplir con los requisitos legales, reglamentarios, fiscales, contables o de informes. Podemos conservar sus datos personales durante un período más largo en caso de una queja o si creemos razonablemente que existe la posibilidad de un litigio con respecto a nuestra relación con usted.</p>
            <p className={style.text}>Para determinar el período de retención adecuado para los datos personales, consideramos la cantidad, la naturaleza y la confidencialidad de los datos personales, el riesgo potencial de daño por el uso o la divulgación no autorizados de sus datos personales, los fines para los que procesamos sus datos personales y si los puede lograr esos fines a través de otros medios, y los requisitos legales, reglamentarios, fiscales, contables u otros aplicables.</p>
            <p className={style.text}>En algunas circunstancias, puede solicitarnos que eliminemos sus datos: consulte la sección 10 a continuación para obtener más información.</p>
            <p className={style.text}>En algunas circunstancias, anonimizaremos sus datos personales (para que ya no puedan asociarse con usted) con fines estadísticos o de investigación, en cuyo caso podemos usar esta información indefinidamente sin previo aviso.</p>
            <div className={style.title}>9. Sus derechos legales</div>
            <p className={style.text}>En determinadas circunstancias, tiene derechos en virtud de las leyes de protección de datos en relación con sus datos personales.</p>
            <p className={style.text}>Tiene derecho a:</p>
            <ul>
                <li className={style.text}>Solicitar acceso a sus datos personales (comúnmente conocido como "solicitud de acceso de sujeto de datos"). Esto le permite recibir una copia de los datos personales que tenemos sobre usted y verificar que los estamos procesando legalmente.</li>
                <li className={style.text}>Solicitar la corrección de los datos personales que tenemos sobre usted. Esto le permite corregir cualquier dato incompleto o inexacto que tengamos sobre usted, aunque es posible que necesitemos verificar la precisión de los nuevos datos que nos proporciona.</li>
                <li className={style.text}>Solicitar la eliminación de sus datos personales. Esto le permite solicitarnos que eliminemos o eliminemos datos personales cuando no haya una buena razón para que continuemos procesándolos. También tiene derecho a solicitarnos que eliminemos o eliminemos sus datos personales cuando haya ejercido con éxito su derecho a oponerse al procesamiento (ver a continuación), cuando hayamos procesado su información ilegalmente o cuando debamos borrar sus datos personales para cumplir con la ley local. Tenga en cuenta, sin embargo, que es posible que no siempre podamos cumplir con su solicitud de eliminación por razones legales específicas que se le notificarán, si corresponde, en el momento de su solicitud.</li>
                <li className={style.text}>Objetar el procesamiento de sus datos personales. Cuando confiamos en un interés legítimo (o en el de un tercero) y hay algo en su situación particular que le hace desear oponerse al procesamiento por este motivo, ya que cree que afecta sus derechos y libertades fundamentales. También tiene derecho a oponerse cuando procesamos sus datos personales con fines de marketing directo. En algunos casos, podemos demostrar que tenemos motivos legítimos convincentes para procesar su información que anulan sus derechos y libertades.</li>
                <li className={style.text}>Solicitar la restricción del tratamiento de sus datos personales. Esto le permite solicitarnos que suspendamos el procesamiento de sus datos personales en los siguientes escenarios:</li>
                <li className={style.text}>Si desea que establezcamos la exactitud de los datos.</li>
                <li className={style.text}>Cuando nuestro uso de los datos es ilegal pero no desea que los borremos.</li>
                <li className={style.text}>Cuando necesite que conservemos los datos, incluso si ya no los necesitamos, ya que los necesita para establecer, ejercer o defender reclamaciones legales.</li>
                <li className={style.text}>Se ha opuesto a que usemos sus datos, pero necesitamos verificar si tenemos motivos legítimos imperiosos para usarlos.</li>
                <li className={style.text}>Solicitar la transferencia de sus datos personales a usted oa un tercero. Le proporcionaremos a usted, o a un tercero que haya elegido, sus datos personales en un formato estructurado, de uso común y legible por máquina. Tenga en cuenta que este derecho solo se aplica a la información automatizada que inicialmente nos dio su consentimiento para usar o cuando usamos la información para realizar un contrato con usted.</li>
                <li className={style.text}>Presentar una queja ante la autoridad de control</li>
                <li className={style.text}>Retirar el consentimiento en cualquier momento en el que dependamos del consentimiento para procesar sus datos personales. Sin embargo, esto no afectará la legalidad de cualquier procesamiento realizado antes de que retire su consentimiento. Si retira su consentimiento, es posible que no podamos proporcionarle ciertos productos o servicios. Le informaremos si este es el caso en el momento en que retire su consentimiento.</li>
            </ul>
        </div>
    );
}

export default PrivacyPolicyComponent;
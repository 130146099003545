import React, {useEffect} from 'react';
import Banner from "../components/banner/Banner";
import bannerIMG from '../assets/images/get-started-for-business.png'
import HowItsWork from "../components/how-its-work/HowItsWork";
import HowToThePay from "../components/how-to-the-pay/HowToThePay";
import ItIsConvenient from "../components/it-is-convenient/ItIsConvenient";
import WithdrawalOfFunds from "../components/withdrawal-of-funds/WithdrawalOfFunds";
import HowMuchDoesItCost from "../components/how-much-it-cost/HowMuchDoesItCost";
import DoYouWantToAcceptTips from "../components/do-you-want-to-accept-tips/DoYouWantToAcceptTips";
import WhatDoesOurServiceDo from "../components/what-does-our-service-do/WhatDoesOurServiceDo";
import {useLocation} from "react-router-dom";
function Business(props) {
    const location = useLocation();

    useEffect(() => {
        document.title = "For Business - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);
    return (
        <div className="business">
            <Banner image={bannerIMG} bgc="#fff" title="Cashless tips for your business" subtitle="Paying by cashless is a guarantee that the tips per day are growing, which means that the motivation of the staff is increasing."/>
            <WhatDoesOurServiceDo/>
            <HowItsWork bgc="#fff"/>
            <HowToThePay bgc="#F0F2F9" contentBG="#fff" itemsBGC="#fff"/>
            <ItIsConvenient bgc="#fff" itemsBG="#F0F2F9"/>
            <WithdrawalOfFunds/>
            <HowMuchDoesItCost/>
            <DoYouWantToAcceptTips bgc="#F0F2F9"/>
        </div>
    );
}

export default Business;
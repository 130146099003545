import $api from "../http";

export default class UserService {
    static async fetchUser(id) {
        return $api.post("user", {id}, {withCredentials: true});
    }
    static async fetchUserByID(id) {
        return $api.post("user-by-id", {id}, {withCredentials: true});
    }
    static async deleteAvatar(id) {
        return $api.post("delete-avatar", {id}, {withCredentials: true});
    }

    static async updateUserImportantData(data) {
        return $api.post("update-user-important-data", data, {withCredentials: true});
    }

    static async updateUserSecondaryData(data) {
        return $api.post("update-user-secondary-data", data, {withCredentials: true});
    }

    static async updateUserTertiaryData(data) {
        return $api.post("update-user-tertiary-data", data, {withCredentials: true});
    }

    static async saveAvatar(file) {
        return $api.post("save-avatar", file, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    static async saveCompanyLogo(file) {
        return $api.post("save-company-logo", file, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static async confirmUser(url, data) {
        return $api.post(url, data, {withCredentials: true});
    }

    static async updateAmount(data) {
        return $api.post("update-amount", data, {withCredentials: true});
    }

    // static async isEmailNotification(data) {
    //     return $api.post("email-notification", data, {withCredentials: true});
    // }
    //
    static async emailActivate(data) {
        return $api.post("email-activate", data, {withCredentials: true});
    }

    static async settingsChange(data) {
        return $api.post("settings-change", data, {withCredentials: true});
    }
}
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isRegistration: false,
    isConfirmCode: false,
    isLogin: true,
    isForgetPass: false,
    isForgetPassInputs: false,
}

export const getStartedSlice = createSlice({
    name: 'getStartedSlice',
    initialState,
    reducers: {
        IsRegistration:(state, action) => {
            state.isRegistration = action.payload;
        },
        IsConfirmCode:(state, action) => {
            state.isConfirmCode = action.payload;
        },
        IsLogin:(state, action) => {
            state.isLogin = action.payload;
        },
        IsForgetPassGetUser:(state, action) => {
            state.isForgetPass = action.payload;
        },
        IsForgetPassInputs:(state, action) => {
            state.isForgetPassInputs = action.payload;
        },
    }
})

export const {
    IsRegistration,
    IsConfirmCode,
    IsLogin,
    IsForgetPassGetUser,
    IsForgetPassInputs,
} = getStartedSlice.actions;

export default getStartedSlice.reducer
import React from 'react';
import {useSelector} from "react-redux";

export default function CleanIcon({active, scale, width}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} style={{transform: `scale(${scale})`, transition: ".2s"}}>
            <g>
                <path className="st0" fill={darkMode ? "#fff" : "#1F3150"} d="M47.4,145.3l12.3-38.8c1.7-5.3,9.3-5.3,11,0L83,145.3c0.6,1.8,2,3.2,3.7,3.7l38.8,12.3c5.3,1.7,5.3,9.3,0,11   l-38.8,12.3c-1.8,0.6-3.2,2-3.7,3.7l-12.3,38.8c-1.7,5.3-9.3,5.3-11,0l-12.3-38.8c-0.6-1.8-2-3.2-3.7-3.7L4.9,172.3   c-5.3-1.7-5.3-9.3,0-11l38.8-12.3C45.5,148.5,46.9,147.1,47.4,145.3z"/>
                <path className="st0" fill={darkMode ? "#fff" : "#1F3150"} d="M429,374.7l12.3-38.8c1.7-5.3,9.3-5.3,11,0l12.3,38.8c0.6,1.8,2,3.2,3.7,3.7l38.8,12.3c5.3,1.7,5.3,9.3,0,11   L468.3,414c-1.8,0.6-3.2,2-3.7,3.7l-12.3,38.8c-1.7,5.3-9.3,5.3-11,0L429,417.7c-0.6-1.8-2-3.2-3.7-3.7l-38.8-12.3   c-5.3-1.7-5.3-9.3,0-11l38.8-12.3C427.1,377.9,428.5,376.5,429,374.7z"/>
                <path className="st1" fill={active ? "#0666EB" : "#A3ABB7"} d="M378.5,426.7c-13.4-4.2-22.3-16.5-22.3-30.5c0-14,9-26.3,22.3-30.5l28.7-9.1l9.1-28.7   c3.1-9.7,10.3-17,19.4-20.3V153.6c0-13.9-11.3-25.3-25.3-25.3c-13.9,0-25.3,11.3-25.3,25.3v88.2c0,7.3-5.9,13.2-13.2,13.2   c-7.3,0-13.2-5.9-13.2-13.2l-0.1-139.7c0-13.9-11.3-25.2-25.3-25.2c-13.9,0-25.3,11.3-25.3,25.2l0,139.1c0,7.6-6.1,13.7-13.7,13.7   c-7.6,0-13.7-6.1-13.7-13.7l0-164.6c0-13.9-11.3-25.3-25.3-25.3c-13.9,0-25.3,11.3-25.3,25.3l-0.1,165.6c0,7-5.7,12.7-12.7,12.7   c-7,0-12.7-5.7-12.7-12.7l0.1-140.6c0-13.9-11.3-25.3-25.3-25.3c-13.9,0-25.3,11.3-25.3,25.3v222.4c0,0.3,0.1,0.5,0.1,0.7   l-48.8-46.4c-12.5-11.8-32.1-11.3-44,1.1c-11.8,12.5-11.3,32.1,1.1,44l92.2,87.7h0.5c28.9,29.3,73.2,48.1,122.9,48.1   c45.5,0,80.1-13,105.2-31L378.5,426.7z"/>
                <path className="st2" fill="#FFFFFF" d="M243.7,382.2C243.7,382.2,243.7,382.2,243.7,382.2c-2.7,0-4.9-1.6-5.7-4.2l-6.9-21.9l-22.2-7.2   c-2.5-0.8-4.2-3.1-4.2-5.7c0-2.7,1.6-4.9,4.2-5.7l22.2-7.2l6.9-21.9c0.8-2.5,3.1-4.2,5.7-4.2c0,0,0,0,0,0c2.7,0,4.9,1.6,5.7,4.2   l6.9,21.9l22.2,7.2c2.5,0.8,4.2,3.1,4.2,5.7c0,2.7-1.6,4.9-4.2,5.7l-21.9,6.9l-7.2,22.2C248.6,380.6,246.4,382.2,243.7,382.2z"/>
            </g>
        </svg>
    );
}

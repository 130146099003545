import React, {useEffect, useState} from 'react';
import style from './ProfileQR.module.scss';
import {QRCode} from "react-qrcode-logo";
import {useSelector} from "react-redux";
import { AiOutlineShareAlt } from "react-icons/ai";
import config from "../../../config"
import { IoCopy } from "react-icons/io5";
import {QRCodeSVG} from 'qrcode.react';
import avatar from '../../../assets/images/avatars/default-avatar.png';
import {useTranslation} from "react-i18next";
import { FaCopy } from "react-icons/fa";

function ProfileQr() {
    const {user} = useSelector(state => state.user);
    const id = user ? user.id : "";
    const url = `${config.FRONTEND_URL}/payment/${id}`;
    const userImage = user && user.userImageName ? user.userImageName : avatar;
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(url);
            alert(t("Link copied to clipboard"))
        } catch (err) {
            alert('Error copying link');
        }
    };
    const share = async () => {
        try {
            await navigator.share({url})
        } catch (err) {
            alert('Error share link');
        }
    };
    return (
        <div className={darkMode ? style.profile_qr + " col-lg-4 col-md-12 col-12 dark-block-bg light-text" : style.profile_qr + " col-md-4 col-12"}>
            <div className={style.profile_qr_title}>{t("Your personal QR code")}</div>
            <QRCode
                value={url}
                size={236}
                bgColor={darkMode ? "#272727" : "#ffffff"}
                fgColor={darkMode ? "#fff" : "#000"}
                level={"M"}
                includeMargin={false}
                imageSettings={{
                    src: userImage,
                    x: undefined,
                    y: undefined,
                    height: 56,
                    width: 56,
                    excavate: true,
                }}
                style={{borderRadius: '8px'}}
            />
            <div className={style.profile_qr_numbers}><span>ID:</span>&nbsp;{id}</div>
            <div className={style.profile_qr_go_payment_page}><a href={url} target="_blank">{t("Go to the payment page")}</a></div>
            <div className={style.profile_qr_share}><span onClick={share}><AiOutlineShareAlt fontSize={15}/> {t("Share link to pay")}</span> <FaCopy onClick={copyToClipboard} fontSize={15}/></div>
            <div className={darkMode ? style.profile_qr_text + " light-text": style.profile_qr_text}>{t("Printing with delivery in your city: choose a layout (business card, sticker, etc.) for printing, and you will be brought ready-made materials.")}</div>
        </div>
    )
}

export default ProfileQr;
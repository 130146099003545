import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../assets/images/logo.png';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
    },
    centeredBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 16,
    },
    logo: {
        width: 100,
    },
    grayText: {
        color: '#8c8c8c',
        fontSize: 15,
        marginTop: 4,
    },
    dateText: {
        color: '#9e9e9e',
        fontSize: 15,
        marginTop: 2,
        borderBottom: '1px dashed #9e9e9e',
        width: '100%',
        textAlign: 'center',
        paddingBottom: 4,
    },
    box: {
        marginBottom: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    box2: {
        marginBottom: 25,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    title: {
        color: '#8c8c8c',
        fontSize: 15,
    },
    content: {
        color: '#000000c7',
        fontSize: 18,
        fontWeight: 600,
    },
});

const PDFDocument = ({ senderPhone, senderName, amount, recipientPhone, recipientName, comment, comicion, documentNumber }) => {
    const date = new Date();
    const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).replace(',', '');

    return (
        <Document>
            <Page size="A5" style={styles.container}>
                <View style={styles.centeredBox}>
                    <Image style={styles.logo} src={logo} alt="logo" />
                    <Text style={styles.grayText}>Recibo de la transacción</Text>
                    <Text style={styles.dateText}>{formattedDate}</Text>
                </View>
                <View style={styles.box}>
                    <Text style={styles.title}>Operación</Text>
                    <Text style={styles.content}>Transferir al cliente TipsSi</Text>
                </View>
                <View style={styles.box}>
                    <Text style={styles.title}>Recipiente</Text>
                    <Text style={styles.content}>{recipientName}</Text>
                </View>
                <View style={styles.box2}>
                    <Text style={styles.title}>Cuenta</Text>
                    <Text style={styles.content}>{recipientPhone}</Text>
                </View>
                <View style={styles.box}>
                    <Text style={styles.title}>Titular</Text>
                    <Text style={styles.content}>{senderName}</Text>
                </View>
                <View style={styles.box}>
                    <Text style={styles.title}>Cuenta</Text>
                    <Text style={styles.content}>{senderPhone}</Text>
                </View>
                <View style={styles.box}>
                    <Text style={styles.title}>Importe</Text>
                    <Text style={styles.content}>{amount}€</Text>
                </View>
                <View style={styles.box}>
                    <Text style={styles.title}>Comicion</Text>
                    <Text style={styles.content}>{comicion}</Text>
                </View>
                <View style={styles.box}>
                    <Text style={styles.title}>Numero de Documento</Text>
                    <Text style={styles.content}>{documentNumber}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default PDFDocument;

import React from 'react';
import SendSMSComponent from "../components/get-started-components/send-sms/SendSMSComponent";
import {useSelector} from "react-redux";

function SendSms(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    return (
        <div className={darkMode ? "get-started bg-white dark-section-bg light-text" : "get-started bg-white"}>
            <div className="container">
                <div className="row"><SendSMSComponent/></div>
            </div>
        </div>

    );
}

export default SendSms;
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import userService from "../services/UserService";
import PaymentService from "../services/PaymentService";
import ThankYouComponent from "../components/thank-you-component/ThankYouComponent";
import config from "../config";
import axios from "axios";

function ThankYou(props) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const userId = location.pathname.split("/")[2]?.split("=")[1];
    const amount = params.get('amount');
    const status = params.get('status');
    const id = params.get('id');
    const rating = location.pathname.split("/")[3]?.split("=")[1];
    const comment = location.pathname.split("/")[4]?.split("=")[1];
    const goodDrink = location.pathname.split("/")[5]?.split("=")[1];
    const cleanPremises = location.pathname.split("/")[6]?.split("=")[1];
    const goodFood = location.pathname.split("/")[7]?.split("=")[1];
    const goodMusic = location.pathname.split("/")[8]?.split("=")[1];
    const goodService = location.pathname.split("/")[9]?.split("=")[1];
    const likeEuro = location.pathname.split("/")[10]?.split("=")[1];
    const url = `${config.FRONTEND_URL}/payment/${userId}`;
    const [thankyou, setThankyou] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [tiktok, setTikTok] = useState("");
    const [youtube, setYoutube] = useState("");

    const getUserData = async () => {
        try {
            const result = await userService.fetchUser(userId);
            if (result.data.length > 0) {
                const userData = result.data[0];
                setThankyou(userData.userThankYouPhrase);
                setFacebook(userData.userFacebook);
                setInstagram(userData.userInstagram);
                setWhatsapp(userData.userWhatsapp);
                setLinkedin(userData.userLinkedin);
                setTikTok(userData.userTikTok);
                setYoutube(userData.userYoutube);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const amountUpdate = async () => {
            const data = {
                amount: amount,
                userId,
                rating
            };
            try {
                if (userId) {
                    await userService.updateAmount(data);
                }
            } catch (e) {
                console.log(e);
            }
        };
        const sendTransaction = async () => {
            try {
                const response = await axios.post(`${config.BACKEND_URL}/paymentMethod`, { id });
                const method = response.data.paymentMethod.card.tokenizationMethod
                const data = {
                    payID: id,
                    userId,
                    amount: amount / 100,
                    status,
                    rating,
                    comment,
                    type: "tip",
                    method,
                    goodDrink: parseInt(goodDrink),
                    cleanPremises: parseInt(cleanPremises),
                    goodFood: parseInt(goodFood),
                    goodMusic: parseInt(goodMusic),
                    goodService: parseInt(goodService),
                    likeEuro: parseInt(likeEuro)
                };
                await PaymentService.transaction(data);
            } catch (e) {
                console.log(e);
            }
        };
        const ratingUpdate = async () => {
            try {
                const data = {
                    userID: userId,
                };
                await PaymentService.rating(data);
            } catch (e) {
                console.log(e.response?.data?.message);
            }
        };
        const reactionUpdate = async () => {
            try {
                const data = {
                    userID: userId,
                    goodDrink: parseInt(goodDrink),
                    cleanPremises: parseInt(cleanPremises),
                    goodFood: parseInt(goodFood),
                    goodMusic: parseInt(goodMusic),
                    goodService: parseInt(goodService),
                    likeEuro: parseInt(likeEuro)
                };
                await PaymentService.reaction(data);
            } catch (e) {
                console.log(e.response?.data?.message);
            }
        };

        if (userId) {
            amountUpdate();
            sendTransaction();
            ratingUpdate();
            getUserData();
            reactionUpdate();
        }
    }, [userId, amount, rating, comment, id, status]);
    return (
        <section className="thank-you pt-0">
            <div className="container">
                <div className="d-flex justify-content-center align-items-center">
                    <ThankYouComponent
                        url={url}
                        thankyou={thankyou}
                        facebook={facebook}
                        instagram={instagram}
                        whatsapp={whatsapp}
                        linkedin={linkedin}
                        tiktok={tiktok}
                        youtube={youtube}
                    />
                </div>
            </div>
        </section>
    );
}

export default ThankYou;

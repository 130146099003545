import React from 'react';
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
function Rating({rating}) {

    switch (rating) {
        case 1:
           return <SentimentVeryDissatisfiedIcon color="error" />
        case 2:
            return <SentimentDissatisfiedIcon color="error" />
        case 3:
            return <SentimentSatisfiedIcon color="warning" />
        case 4:
            return <SentimentSatisfiedAltIcon color="success" />
        case 5:
            return <SentimentVerySatisfiedIcon color="success" />
        case null:
            return "-";
    }
}

export default Rating;
import React, {useState} from 'react';
import style from './ProfileHeader.module.scss';
import {useDispatch, useSelector} from "react-redux";
import avatar from "../../../assets/images/avatars/default-avatar.png";
import {Avatar, ClickAwayListener, Skeleton, Tooltip} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';
import icon from "../../../assets/images/balance-icon.png"
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {FixedMenuTabValue} from "../../../redux/slices/accountSettingsSlice";
function ProfileHeader() {
    const { user, userRating, userName, imagePath, userVerification, isLoading } = useSelector(state => state.user);
    const { userAmount, userFirstName, userLastName } = user || {};
    const name = userFirstName && userLastName ? userFirstName + " " + userLastName : userName;
    const darkMode = useSelector((state) => state.settings.darkMode);
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const avatarPath = `/media/${imagePath}`
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const handleClickAwayListener = () => {
        if(location.pathname === "/profile/edit"){
            dispatch(FixedMenuTabValue("1"))
        }else {
            dispatch(FixedMenuTabValue("0"))
        }
    }
    return (
        <div className={style.profile + " row"}>
            <div className="col-12">
                <div className={darkMode ? style.profile_data + " d-flex align-items-sm-center align-items-start flex-sm-row flex-column dark-block-bg light-text" : style.profile_data + " d-flex align-items-sm-center align-items-start flex-sm-row flex-column"}>
                    <div className={style.profile_data_content + " mb-sm-0 mb-3"}>
                        <div className={style.profile_data_content_avatar}>
                            {isLoading
                                ?
                                <Skeleton variant="circular" width={56} height={56} />
                                :
                                <Avatar src={avatarPath ? avatarPath : avatar} sx={{ width: 56, height: 56 }} alt="avatar" />
                            }
                            {userRating > 0 && (
                                <div className={style.profile_rating}>
                                    <StarIcon />
                                    <span style={{color: darkMode && "#000"}}>{userRating}</span>
                                </div>
                            )}
                        </div>
                        <div className={style.profile_data_content_text}>
                            <span>{name}</span>
                            &nbsp;
                            {userVerification === 0
                                ?
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={open}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title="To withdraw money, please verify"
                                    ><ErrorIcon sx={{color: "#ff9100", fontSize: 18}} onClick={handleTooltipOpen}/></Tooltip>
                                </ClickAwayListener>
                                :
                                <CheckCircleIcon sx={{color: "#519D62", fontSize: 18}}/>
                            }
                        </div>
                        <Link to={location.pathname === "/profile/edit" ? "/profile" : "/profile/edit"} className={style.profile_image_edit}><Button variant="contained">{location.pathname === "/profile/edit" ? <CloseIcon sx={{color: "#fff", fontSize: 18}}/> : <EditIcon sx={{color: "#fff", fontSize: 18}}/>}</Button></Link>
                    </div>
                    <div className={style.profile_data_content}>
                        <div className={style.profile_data_content_text + " d-flex align-items-center"}>
                            <img src={icon} alt=""/>&nbsp;<span className="fw-semibold">{userAmount} €</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ProfileHeader;
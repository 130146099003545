import React, {useEffect, useState} from 'react';
import style from './TipssiQRComponent.module.scss';
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import logo from "../../assets/images/tipssi-print-logo.jpg";
import {FormHelperText, TextField} from "@mui/material";
import InputMask from 'react-input-mask';
import {useNavigate} from "react-router-dom";
import UserService from "../../services/UserService";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {IsLoading} from "../../redux/slices/userSlice";

function TipssiQRComponent(props) {
    const [id, setId] = useState("");
    const navigate = useNavigate();
    const [userError, setUserError] = useState(false);
    const [userErrorText, setUserErrorText] = useState("");
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const handleInputChange = (e) => {
        setId(e.target.value);
    };
    const handleSubmit = async (e) => {
        dispatch(IsLoading(true));
        try {
            const response = await UserService.fetchUserByID(id)
            if(response.data.length > 0) {
                navigate(`/payment/${id}`)
            }
        }catch(e) {
            setUserError(true);
            setUserErrorText(e.response?.data?.message);
        }finally {
            dispatch(IsLoading(false));
        }
    }
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                handleSubmit();
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [handleSubmit]);
    return (
        <div className={style.tipssi_qr_component + " text-center col-sm-9 col-md-5 col-lg-4 col-12 bg-white"}>
            <div className={style.tipssi_qr_image}><img src={logo} alt=""/></div>
            <div className={style.tipssi_qr_title}>TIPEA MUY FÁCIL</div>
            <div className={style.tipssi_qr_subtitle}>SISTEMA DE PROPINAS DIGITALES</div>
            <div className={style.tipssi_qr_smallSubtitle}>
                Por favor, introduce el
                <br/>
                numero de cuenta del usuario
            </div>
            <InputMask
                mask="999-999-999"
                value={id}
                onChange={handleInputChange}
                inputMode="numeric"
                type="number"
            >
                {() => (
                    <TextField
                        className={style.field}
                        variant="outlined"
                        fullWidth
                        autoFocus={true}
                    />
                )}
            </InputMask>
            {userError && <FormHelperText error={userError} id="outlined-weight-helper-text" className="mt-1">{t(userErrorText)}</FormHelperText>}
            <Button
                onClick={handleSubmit}
                variant="contained"
                fullWidth
                sx={{fontSize: "19px", textTransform: "capitalize"}}
                className="btn mt-4"
            >
                <SearchIcon />&nbsp;Buscar
            </Button>
        </div>
    );
}

export default TipssiQRComponent;

import React, {useEffect, useState} from 'react';
import style from "../../../transactions-content/TransactionsContent.module.scss";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Box} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import dayjs from "dayjs";
import averageIcon from "../../../../../assets/images/average-rating.png";
import css from "../EmployeeRatingContent.module.scss";
function EmployeeRatingContentData(props) {
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());

    const [alignment, setAlignment] = useState('tip');

    const handleChangeFilter = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    useEffect(() => {
        setFromDate(dayjs());
        setToDate(dayjs());
    }, []);
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={style.calendar + " col-sm-12 col-md-6 col-lg-4 col-12 bg-white"}>
            <div className={css.average_rating}>
                <div className={css.average_rating_icon}><img src={averageIcon} alt="average-rating"/></div>
                <div className={css.average_rating_text}>Average rating for the period: <span>0</span></div>
            </div>
            <div className={style.calendar_title}>Date</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                        label="Date From"
                        value={fromDate}
                        onChange={(newValue) => setFromDate(newValue)}
                    />
                    <DatePicker
                        label="Date To"
                        value={toDate}
                        onChange={(newValue) => setToDate(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        className="transactions-filter"
                    >
                        <Tab label="Today" value="1"/>
                        <Tab label="Week" value="2"/>
                        <Tab label="Month" value="3"/>
                    </TabList>
                </TabContext>
            </Box>
        </div>
    );
}

export default EmployeeRatingContentData;
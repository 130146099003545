import React from 'react';
import {useSelector} from "react-redux";

export default function GoodMusic({active, scale, width}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const fillColor1 = darkMode ? '#fff' : '#1F3150';
    const fillColor2 = active ? "#0666EB" : '#A3ABB7';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} style={{transform: `scale(${scale})`, transition: ".2s"}}>
            <g>
                <path
                    style={{ fill: fillColor2 }}
                    d="M129.8,126.9V430h64.9V222.5l251.6-75.8v217.2h64.9V31.1c0-17.3-20.3-35.7-43.6-28.7L150.2,99.3   C135.3,104,129.8,113.1,129.8,126.9z"/>
                <ellipse
                    style={{ fill: fillColor1 }}
                    cx="97.8"
                    cy="433.5"
                    rx="96.9"
                    ry="77.6"/>
                <ellipse
                    style={{ fill: fillColor1 }}
                    cx="414.2"
                    cy="366.2"
                    rx="96.9"
                    ry="77.6"/>
            </g>
        </svg>
    );
}

import React, {useEffect} from 'react';
import ProfileHeader from "../components/profile-components/profile-header/ProfileHeader";
import ProfileContent from "../components/profile-components/profile-content/ProfileContent";
import {useLocation} from "react-router-dom";

function Profile() {
    const location = useLocation(); // Используйте useLocation для получения пути

    useEffect(() => {
        document.title = "Profile - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);

    return (
        <section className="profile pt-0">
            <div className="container">
                <ProfileHeader/>
                <ProfileContent/>
            </div>
        </section>
    );
}

export default Profile;
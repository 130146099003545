import React from 'react';
import style from './BusinessOwners.module.scss';
import image from '../../assets/images/business-owners.png'
import CustomLink from "../CustomLink";
import {useSelector} from "react-redux";
function BusinessOwners(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    return (
        <section className={darkMode ? style.business_owners + " dark-section-bg light-text" : style.business_owners}>
            <div className="container">
                <div className="row d-flex justify-content-between">
                    <div className={style.text_block + " col-md-6 col-12"} data-aos="fade-right" data-aos-duration="1500">
                        <div className={"section_title " + style.business_owners_title}>Business owners</div>
                        <div className={darkMode ? style.business_owners_subtitle + " section_description light-text" : style.business_owners_subtitle + " section_description"}>Do you want to
                            introduce the Tipssi tip service in your company? Sign up as an administrator and you'll be
                            able to:
                        </div>
                        <ul className={style.text_block_list}>
                            <li className={darkMode ? "section_description light-text" : "section_description"}>connect a few people at once</li>
                            <li className={darkMode ? "section_description light-text" : "section_description"}>to see the receipt of staff tips, and tips are an
                                indicator of the quality of an employee's work
                            </li>
                            <li className={darkMode ? "section_description light-text" : "section_description"}>print business cards. You don't have to wait for
                                employees to check in on their own
                            </li>
                        </ul>
                        <CustomLink className='custom_btn' link="/sign-in" text="Sign Up as an Administrator"/>
                        <div className={darkMode ? style.text_block_bottom + " light-text" : style.text_block_bottom}>With our service, the staff is guaranteed to get more tips, and it's free for you. We will take care of all accounting and legal issues.</div>
                    </div>
                    <div className={"d-flex align-items-end flex-column col-md-6 col-12 " + style.business_owners_image} data-aos="fade-left" data-aos-duration="1500"><img src={image} alt="Business owners"/></div>
                </div>
            </div>
        </section>
    );
}

export default BusinessOwners;
import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import config from "../../config";
import logo from "../../assets/images/tipssi-print-logo.jpg";
import QRCode from 'qrcode';
import {Svg, Path} from '@react-pdf/primitives';
import fontPath from '../../assets/fonts/Roboto-Bold.ttf'
Font.register({
    family: 'Roboto',
    fontWeight: 700,
    fonts: [{src: fontPath}]
});

const styles = StyleSheet.create({
    qrCode: {
        width: 750,
        height: 750,
    },
});

function BusinessCard({ id }) {
    const url = `${config.FRONTEND_URL}/payment/${id}`;
    const [qrCodeData, setQrCodeData] = useState('');

    const formatPhone = phone => {
        if (!phone) return ''; // Проверка на undefined
        const cleanedPhone = phone.startsWith('+34') ? phone.slice(3) : phone;
        return cleanedPhone.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3-$4');
    };

    useEffect(() => {
        QRCode.toDataURL(url, {
            errorCorrectionLevel: 'M',
            margin: 1,
            scale: 10
        }).then(data => {
            setQrCodeData(data);
        });
    }, [url]);

    return (
        <Document>
            <Page size={[750]} wrap={false}>
                <View>
                    {qrCodeData && <Image src={qrCodeData} style={styles.qrCode} />}
                </View>
            </Page>
        </Document>
    );
}

export default BusinessCard;
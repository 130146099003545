import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignInComponent from '../components/get-started-components/sign-in/SignInComponent';

function SignIn(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const location = useLocation(); // Используйте useLocation для получения пути

    useEffect(() => {
        document.title = "Login - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);

    return (
        <div className={darkMode ? "get-started dark-section-bg light-text" : "get-started"}>
            <div className="container">
                <div className="row">
                    <SignInComponent />
                </div>
            </div>
        </div>
    );
}

export default SignIn;

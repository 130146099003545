import React from 'react';
import {FaExclamationTriangle} from "react-icons/fa";
import {Link} from "react-router-dom";
import styles from "./Error404Component.module.scss";
import {useTranslation} from "react-i18next";
export default function Error404Component(props) {
    const {t} = useTranslation();
    return (
        <div className={styles.container}>
            <FaExclamationTriangle className={styles.icon}/>
            <h1 className={styles.heading}>404 - Página no encontrada</h1>
            <p className={styles.paragraph}>Lo sentimos, la página que estás buscando no existe. Es posible que haya
                ingresado una dirección incorrecta o que la página se haya movido.</p>
            <Link to="/" className={"btn " + styles.button} >{t("Home Page")}</Link>
        </div>
    );
}
import React from 'react';
import style from './HowItsWork.module.scss'
import registrationImage from '../../assets/images/how-it-works-registration.png'
import QRImage from '../../assets/images/how-it-works-qr-code.png'
import paymentImage from '../../assets/images/how-it-works-payment.png'
import arrow from '../../assets/images/arrow.png'
import {useSelector} from "react-redux";
import HowItsWorkItem from "./HowItsWorkItem";
import SectionSmallTitle from "../SectionSmallTitle";

function HowItsWork({bgc}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    return (
        <section className={darkMode ? style.how_its_work + " dark-section-bg light-text" : style.how_its_work} style={{backgroundColor: bgc}}>
            <div className="container">
                <div className="row">
                    <SectionSmallTitle title="How it works?" textAlign="center"/>
                    <div className={style.how_its_work_items}>
                        <HowItsWorkItem title="Registration" image={registrationImage} number="01" description="You register (1 minute) and get a personal QR-code."/>
                        <HowItsWorkItem title="QR-code" image={QRImage} number="02" description="Show the client / guest the QR code in a printed form or on the phone screen. You download layouts for printing or order delivery in your personal account."/>
                        <HowItsWorkItem title="Payment" image={paymentImage} number="03" description="The client scans the QR code, goes to your personal page, where he leaves a tip by bank transfer. The payment procedure takes a minute."/>
                    </div>
                </div>
            </div>
        </section>);
}

export default HowItsWork;
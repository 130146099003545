import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    ref: null
}

const pngDownloadRefSlice = createSlice({
    name: 'ref',
    initialState,
    reducers: {
        Ref:(state, action) => {
            state.ref = action.payload;
        }
    }
})

export const {
    Ref
} = pngDownloadRefSlice.actions

export default pngDownloadRefSlice.reducer

import React from 'react';
import style from './WhatDoesOurServiceDo.module.scss';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import SectionSmallTitle from "../SectionSmallTitle";
function WhatDoesOurServiceDo(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <section className={darkMode ? style.business + " dark-section-bg light-text" : style.business}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionSmallTitle title="What does our service do?" textAlign="center"/>
                        <div className={darkMode ? style.business_subtitle + " light-text" : style.business_subtitle} data-aos="fade-down" data-aos-duration="1500">{t("Often guests don't have cash or small denomination bills. With our service your staff can accept tips from guests by card or via QR payment systems.")}</div>
                        <div className={style.business_items}>
                            <div className={darkMode ? style.business_item + " d-flex flex-column align-center col-sm-6 col-lg-5 col-12 dark-block-bg":style.business_item + " d-flex flex-column align-center col-sm-6 col-lg-5 col-12"} data-aos="fade-right" data-aos-duration="1500">
                                <div className={style.business_item_title + " small_title text-center"}>{t("Easy to connect")}</div>
                                <div className={style.business_item_subtitle}>{t("Registration takes a couple of minutes and you don't need to install any applications. You can get tips and withdraw money immediately.")}</div>
                            </div>
                            <div  className={darkMode ? style.business_item + " d-flex flex-column align-center col-sm-6 col-lg-5 col-12 dark-block-bg":style.business_item + " d-flex flex-column align-center col-sm-6 col-lg-5 col-12"} data-aos="fade-left" data-aos-duration="1500">
                                <div className={style.business_item_title + " small_title text-center"}>{t("No cost")}</div>
                                <div className={style.business_item_subtitle}>{t("The service is free for owners: we only take a commission from the tip, no extra fees.")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhatDoesOurServiceDo;
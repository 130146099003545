import React from 'react';
import style from './Footer.module.scss';
import logo from '../../assets/images/logo.png';
import companyIcon from '../../assets/images/company.png';
import telIcon from '../../assets/images/tel.svg';
import envelopIcon from '../../assets/images/envelop.svg';
import locationIcon from '../../assets/images/location.svg';
import { FaWhatsapp, FaInstagram, FaFacebook, FaYoutube, FaTiktok } from "react-icons/fa";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import darkLogo from '../../assets/images/logo-dark-theme.png';

function Footer() {
    const date = new Date();
    const year = date.getFullYear();
    const darkMode = useSelector((state) => state.settings.darkMode);
    const { t } = useTranslation();
    const currentURL = window.location.pathname.split("/")[1];

    // Исправление для логического выражения URL
    const url = darkMode
        ? darkLogo
        : (currentURL === "qr" || currentURL === "thankyou" || currentURL === "payment")
            ? logo
            : logo;

    return (
        <footer
            className={`${style.footer} ${darkMode ? "dark-section-bg light-text" : ""}`}
            style={{ borderTopColor: darkMode ? "#272727" : "#F0F2F9" }}
        >
            <div className="container">
                <div className="row">
                    <div
                        className={`${style.main_footer} col-12 d-flex justify-content-between align-items-center`}
                        style={{ backgroundColor: darkMode ? "transparent" : "#fff" }}
                    >
                        <div className={style.logo}><img src={url} alt="Logo" /></div>
                        <div className={style.icon}><a href="https://hayconex.com/"><img src={companyIcon} alt="Company icon" />HAY CONEXION S.L.</a></div>
                        <div className={style.icon}><a href="tel:+34617145050"><img src={telIcon} alt="Phone icon" />+34 617-145-050</a></div>
                        <div className={style.icon}><a href="mailto:support@tipssi.net"><img src={envelopIcon} alt="Email icon" />support@tipssi.net</a></div>
                        <div className={style.icon}><a href="https://www.google.com/maps/place/C.+del+Consejo+de+Ciento,+383,+1-1,+Eixample,+08009+Barcelona,+Espa%C3%B1a/@41.3939871,2.1694203,144m/data=!3m1!1e3!4m6!3m5!1s0x12a4a2ec261dc557:0xf4245671faeee17b!8m2!3d41.3939724!4d2.1693334!16s%2Fg%2F11q1t0shky?hl=es&entry=ttu"><img src={locationIcon} alt="Location icon" />Barcelona, Spain</a></div>
                        <div className={style.social_icons}>
                            <div className={`${style.icon} ${style.whatsapp}`}><a href="https://wa.me/34617145050"><FaWhatsapp /></a></div>
                            <div className={`${style.icon} ${style.facebook}`}><a href="#"><FaFacebook /></a></div>
                            <div className={`${style.icon} ${style.instagram}`}><a href="#"><FaInstagram /></a></div>
                            <div className={`${style.icon} ${style.youtube}`}><a href="#"><FaYoutube /></a></div>
                            <div className={`${style.icon} ${style.tiktok}`}>
                                <a href="#"><FaTiktok /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.pre_footer} style={{ backgroundColor: darkMode ? "#272727" : "", color: darkMode ? "#fff" : "" }}>
                <div className="container">
                    <div className="row">
                        <div className={style.pre_footer_content}>
                            <div className={style.copy_right}>© {t("Copyright")} {year} by Hay Conexion. {t("All Rights Reserved")}.
                            </div>
                            <nav className={style.footer_menu}>
                                <a href="/contact-us">{t("Contact Us")}</a>
                                <a href="/privacy-policy">{t("Privacy Policy")}</a>
                                <a href="/terms-and-conditions">{t("Terms and Conditions")}</a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

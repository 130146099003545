import axios from "axios";
import config from "../config";
const $api = axios.create({
    baseURL: config.BACKEND_URL,
    // timeout: 5000,
    withCredentials: true,
    sameSite: 'None'
})

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
})

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if(error.config && error.response.status === 401 && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get(`${config.BACKEND_URL}refresh`, {withCredentials: true});
            const token = response.data.accessToken;
            localStorage.setItem("token", token);
            return $api.request(originalRequest);
        }catch(error) {
            console.log('Unauthorized');
        }
    }
    throw error
})

export default $api;
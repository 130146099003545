import React from 'react';
import style from './HowToThePay.module.scss'
import image from '../../assets/images/how-to-pay.png'
import {useSelector} from "react-redux";
import SectionSmallTitle from "../SectionSmallTitle";
import SectionDescription from "../SectionDescription";
function HowToThePay({bgc, contentBG, itemsBGC}) {
    const darkMode = useSelector((state) => state.settings.darkMode);

    return (
        <section className={darkMode ? style.how_to_the_pay + " dark-section-bg light-text" : style.how_to_the_pay} style={{ backgroundColor: bgc }}>
            <div className="container">
                <div className={darkMode ? "row d-flex justify-content-center dark-block-bg light-text " + style.how_to_the_pay_items : "row d-flex justify-content-center " + style.how_to_the_pay_items} style={{backgroundColor: itemsBGC}}>
                    <div className={style.how_to_pay_image + " col-md-6 col-12 d-flex justify-content-center align-items-center"} data-aos="fade-right" data-aos-duration="1500"><img src={image} alt="Payment"/></div>
                    <div className={darkMode ? style.how_to_pay_content + " col-md-6 col-12 dark-block-bg" : style.how_to_pay_content + " col-md-6 col-12"} data-aos="zoom-in" data-aos-duration="1500" style={{backgroundColor: contentBG}}>
                        <SectionSmallTitle title="How do they pay?" marginBottom={32}/>
                        <SectionDescription text="Guests and clients pay electronically by card or via payment systems by QR code. For payment a smartphone is enough, there is no need to install applications."/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowToThePay;
import React from 'react';
import style from "./HowItsWork.module.scss";
import registrationImage from "../../assets/images/how-it-works-registration.png";
import arrow from "../../assets/images/arrow.png";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function HowItsWorkItem({image, number, title, description}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <div className={style.how_its_work_items_body} data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
            <div className={darkMode ? style.how_its_work_item + " d-flex flex-column align-lg-start align-center col-sm-4 col-lg-4 col-12 dark-block-bg light-text" : style.how_its_work_item + " d-flex flex-column align-lg-start align-center col-sm-4 col-lg-4 col-12"}>
                <div className={style.image}><img src={image} alt={title + " image"}/></div>
                <div className={style.counter}><span className={style.counter_number}>{number}</span><img src={arrow} alt="arrow-img" className={style.counter_img}/></div>
                <div className={"small_title " + style.title}>{t(title)}</div>
                <div className={style.description}>{t(description)}</div>
                <img src={arrow} alt="arrow-img" className={style.counter_img_bottom + " d-none"}/>
            </div>
        </div>
    );
}

export default HowItsWorkItem;
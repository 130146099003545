import $api from "../http";

export default class AuthService {
    static async login(phone, password, rememberMe) {
        return $api.post('login', {phone, password, rememberMe})
    }

    static async registration(phone, email ,password) {
        return $api.post('registration', {phone, email ,password})
    }

    static async logout(data) {
        return $api.post('logout', {data})
    }

    static async sendSMS(userID) {
        return $api.post('sendSMS', {userID})
    }

    static async getForgotPasswordUser(phone) {
        return $api.post('get-forgot-password-user', {phone})
    }
}
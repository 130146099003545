import React, {useEffect} from 'react';
import TransactionsContent from "../components/profile-components/transactions-content/TransactionsContent";
import ProfileHeader from "../components/profile-components/profile-header/ProfileHeader";
import {useLocation} from "react-router-dom";

function Transactions() {
    const location = useLocation();

    useEffect(() => {
        document.title = "Transactions - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);

    return (
        <section className="transactions pb-3">
            <div className="container">
                <ProfileHeader/>
                <TransactionsContent/>
            </div>
        </section>
    );
}

export default Transactions;
import React, {useEffect, useState} from 'react';
import style from './ProfileEdit.module.scss';
import {useDispatch, useSelector} from "react-redux";
import avatar from '../../../assets/images/avatars/default-avatar.png';
import companyAvatar from "../../../assets/images/company-logo.png"
import Button from '@mui/material/Button';
import {AiOutlineCamera} from "react-icons/ai";
import {FaRegTrashAlt} from "react-icons/fa";
import UserService from "../../../services/UserService";
import {
    addUser, BirthDate, City,
    CompanyName, Country, Email,
    EmailNotification, FirstName, ImagePath,
    IsRating, IsReview, IsLoading,
    LastName, Profession, Region,
    StreetLine1, StreetLine2, ThankYouPhrase,
    UserFacebook, UserInstagram, UserLinkedin,
    UserName, UserWhatsapp, WelcomePhrase,
    UserTikTok, UserYoutube, AvatarModal,
    CompanyLogoModal, UserPhone, UserTotalAmount,
    UserCollected, IsGoodDrinksPaymentPage, IsCleanPremisesPaymentPage,
    IsGoodFoodPaymentPage, IsGoodMusicPaymentPage, IsGoodServicePaymentPage,
    IsLikeEuroPaymentPage, UserMinimumAmount
} from "../../../redux/slices/userSlice";
import {
    Avatar, Box, FormControlLabel,
    FormGroup, FormHelperText, Switch,
    TextField, Tooltip, useTheme,
    ClickAwayListener, IconButton, Skeleton
} from "@mui/material";
import {FixedMenuTabValue} from "../../../redux/slices/accountSettingsSlice";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import userService from "../../../services/UserService";
import SuccessModal from "../../SuccessModal";
import {PaymentStatusModalOpen} from "../../../redux/slices/paymentSlice";
import {PhoneInput} from "react-international-phone";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Alert from '@mui/material/Alert';
import {useTranslation} from "react-i18next";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import axios from "axios";
import config from "../../../config";
import ReplayIcon from '@mui/icons-material/Replay';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IoSettingsOutline } from "react-icons/io5";
import { LuKeyRound } from "react-icons/lu";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {useNavigate} from "react-router-dom";
import {isMobile, isDesktop} from "react-device-detect";
import { MdLocalBar } from "react-icons/md";
import { FaHandSparkles } from "react-icons/fa";
import { FaMusic } from "react-icons/fa6";
import CleanIcon from "./survey-icon/CleanIcon";
import GoodDrink from "./survey-icon/GoodDrink";
import GoodFood from "./survey-icon/GoodFood";
import GoodMusic from "./survey-icon/GoodMusic";
import GoodService from "./survey-icon/GoodService";
import LikeEuro from "./survey-icon/LikeEuro";

function ProfileEditContent() {
    const {user,
        isEmailNotification,
        isReviewInPaymentPage,
        isRatingInPaymentPage,
        isGoodDrinksPaymentPage,
        isLoading, firstName,
        lastName, userName,
        userEmail, birthDate,
        profession, companyName,
        userCountry, userCity,
        region, streetLine1,
        streetLine2, thankYouPhrase,
        welcomePhrase, imagePath,
        companyImagePath, userFacebook,
        userInstagram, userWhatsapp,
        userLinkedIn, userTikTok,
        userYoutube, userPhone,
        userCollected, userTotalAmount, userMinimumAmount,
        isCleanPremisesPaymentPage,
        isGoodFoodPaymentPage,
        isGoodMusicPaymentPage,
        isGoodServicePaymentPage,
        isLikeEuroPaymentPage,
    } = useSelector(state => state.user);
    const [contentTitle, setContentTitle] = useState("Info");
    const {id, userEmailConfirm} = user && user;
    const {fixedMenuTabValue} = useSelector(state => state.settings);
    const dispatch = useDispatch();
    const [isErrorText, setIsErrorText] = useState("");
    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isWelcomePhraseValid, setIsWelcomePhraseValid] = useState(false);
    const [isThankYouPhraseValid, setIsThankYouPhraseValid] = useState(false);
    const [countries, setCountries] = useState([])
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const darkMode = useSelector((state) => state.settings.darkMode);
    const [open, setOpen] = useState(false);
    const [emailVerifiedAlert, setemailVerifiedAlert] = useState(true);
    const {t} = useTranslation();
    const [error, setError] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    const [notification, setNotification] = useState('');

    const handleEmailNotificationChecked = () => {
        dispatch(EmailNotification(!isEmailNotification));
    }
    const handleIsReviewChecked = () => {
        dispatch(IsReview(!isReviewInPaymentPage));
    }
    const handleIsRatingChecked = () => {
        dispatch(IsRating(!isRatingInPaymentPage));
    }
    const handleIsGoodDrinksChecked = () => {
        dispatch(IsGoodDrinksPaymentPage(!isGoodDrinksPaymentPage));
    }
    const handleIsCleanPremisesChecked = () => {
        dispatch(IsCleanPremisesPaymentPage(!isCleanPremisesPaymentPage));
    }
    const handleIsGoodFoodChecked = () => {
        dispatch(IsGoodFoodPaymentPage(!isGoodFoodPaymentPage));
    }
    const handleIsGoodMusicChecked = () => {
        dispatch(IsGoodMusicPaymentPage(!isGoodMusicPaymentPage));
    }
    const handleIsGoodServiceChecked = () => {
        dispatch(IsGoodServicePaymentPage(!isGoodServicePaymentPage));
    }
    const handleIsLikeEuroChecked = () => {
        dispatch(IsLikeEuroPaymentPage(!isLikeEuroPaymentPage));
    }
    const sendSettingData = async () => {
        dispatch(IsLoading(true));
        try {
            const data = {
                userID: id,
                isEmail: isEmailNotification ? 1 : 0,
                isRating: isRatingInPaymentPage ? 1 : 0,
                isReview: isReviewInPaymentPage ? 1 : 0,
                welcomePhrase,
                thankYouPhrase,
                userCollected,
                userTotalAmount,
                userMinimumAmount,
                goodDrink: isGoodDrinksPaymentPage ? 1 : 0,
                cleanPremises: isCleanPremisesPaymentPage ? 1 : 0,
                goodFood: isGoodFoodPaymentPage ? 1 : 0,
                goodMusic: isGoodMusicPaymentPage ? 1 : 0,
                goodService: isGoodServicePaymentPage ? 1 : 0,
                likeEuro: isLikeEuroPaymentPage ? 1 : 0,
            };
            const result = await userService.settingsChange(data);
            dispatch(PaymentStatusModalOpen(true))
        } catch (error) {
            console.error('Error updating email notification:', error);
        }finally {
            dispatch(IsLoading(false));
        }
    };
    const handleDelete = async () => {
        await UserService.deleteAvatar(id);
        dispatch(ImagePath(''))
    };
    // const fetchCountries = async () => {
    //     dispatch(IsLoading(true))
    //     try {
    //         const result = await axios.get("https://restcountries.com/v3.1/all");
    //         if (result) {
    //             const sortedCountries = result.data.sort((a, b) => {
    //                 const nameA = a.name.common.toUpperCase();
    //                 const nameB = b.name.common.toUpperCase();
    //                 if (nameA < nameB) {
    //                     return -1;
    //                 }
    //                 if (nameA > nameB) {
    //                     return 1;
    //                 }
    //                 return 0;
    //             });
    //             setCountries(sortedCountries);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     } finally {
    //         dispatch(IsLoading(false))
    //     }
    // };
    // useEffect(() => {
    //     fetchCountries();
    // }, []);
    const handleChangeSelectCountry = (event) => {
        const {
            target: { value },
        } = event;
        dispatch(Country(value))
    };
    const handleContentTitleChange = (e) => setContentTitle(e.currentTarget.innerText);
    const handleChange = (event, newValue) => {
        dispatch(FixedMenuTabValue(newValue))
    };
    const handleFirstNameChange = (event) => {
        dispatch(FirstName(event.target.value))
        // if (firstName.length > 2) {
        //     setIsFirstNameValid(false);
        // }
    };
    const handleLastNameChange = (event) => {
        dispatch(LastName(event.target.value))
        // if (lastName.length > 2) {
        //     setIsLastNameValid(false);
        // }
    }
    const handleEmailChange = (event) => {
        dispatch(Email(event.target.value));
        // if (userEmail.length > 2) {
        //     setIsEmailValid(false);
        // }
    }
    const handleCityChange = (event) => dispatch(City(event.target.value));
    const handleCountryChange = (event) => dispatch(Country(event.target.value));
    const handleBirthDateChange = (event) => {
        const unformatedDate = event.$d;
        const date = new Date(unformatedDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${month}/${day}/${year}`;
        dispatch(BirthDate(formattedDate))
    };
    const handleUserNameChange = (event) => dispatch(UserName(event.target.value));
    const handleProfessionChange = (event) => dispatch(Profession(event.target.value));
    const handleCompanyNameChange = (event) => dispatch(CompanyName(event.target.value));
    const handleRegionChange = (event) => dispatch(Region(event.target.value));
    const handleStreetLine1Change = (event) => dispatch(StreetLine1(event.target.value));
    const handleStreetLine2Change = (event) => dispatch(StreetLine2(event.target.value));
    const handleWelcomePhraseChange = (event) => {
        dispatch(WelcomePhrase(event.target.value));
        if (welcomePhrase.length > 2) {
            setIsWelcomePhraseValid(false);
        }
    }
    const handleThankYouPhraseChange = (event) => {
        dispatch(ThankYouPhrase(event.target.value));
        if (thankYouPhrase.length > 2) {
            setIsThankYouPhraseValid(false);
        }
    }
    const handleFacebookChange = (event) => {
        dispatch(UserFacebook(event.target.value));
    }
    const handleInstagramChange = (event) => {
        dispatch(UserInstagram(event.target.value));
    }
    const handleWhatsappChange = (event) => {
        dispatch(UserWhatsapp(event.target.value));
    }
    const handleLinkedInChange = (event) => {
        dispatch(UserLinkedin(event.target.value));
    }
    const handleTikTokChange = (event) => {
        dispatch(UserTikTok(event.target.value));
    }
    const handleYoutubeChange = (event) => {
        dispatch(UserYoutube(event.target.value));
    }
    const handlePhoneChange = (event) => {
        dispatch(UserPhone(event));
    }
    const handleTotalAmountChange = (event) => {
        dispatch(UserTotalAmount(event));
    }
    const handleMinimumAmountChange = (event) => {
        dispatch(UserMinimumAmount(event));
    }
    const handleCollectedChange = (event) => {
        dispatch(UserCollected(event));
    }
    const avatarPath = `/media/${imagePath}`
    const companyAvatarPath = `/media/company/${companyImagePath}`
    useEffect(() => {
        switch (isErrorText) {
            case "First Name is required":
                setIsFirstNameValid(true);
                setIsLastNameValid(false);
                setIsEmailValid(false);
                break
            case "Last Name is required":
                setIsFirstNameValid(false);
                setIsLastNameValid(true);
                setIsEmailValid(false);
                break
            case "Email must be valid":
                setIsFirstNameValid(false);
                setIsLastNameValid(false);
                setIsEmailValid(true);
                break
            case "First Name is required,Last Name is required":
                setIsFirstNameValid(true);
                setIsLastNameValid(true);
                setIsEmailValid(false);
                break
            case "First Name is required,Last Name is required,Email must be valid":
                setIsFirstNameValid(true);
                setIsLastNameValid(true);
                setIsEmailValid(true);
                break
            case "Last Name is required,Email must be valid":
                setIsFirstNameValid(false);
                setIsLastNameValid(true);
                setIsEmailValid(true);
                break
            case "First Name is required,Email must be valid":
                setIsFirstNameValid(true);
                setIsLastNameValid(false);
                setIsEmailValid(true);
                break
            case "The thank you phrase field cannot contain less than 3 characters":
                setIsWelcomePhraseValid(false)
                setIsThankYouPhraseValid(true)
                break
            case "The welcome phrase field cannot contain less than 3 characters":
                setIsWelcomePhraseValid(true)
                setIsThankYouPhraseValid(false)
                break
            case "The thank you phrase field cannot contain less than 3 characters,The welcome phrase field cannot contain less than 3 characters":
                setIsWelcomePhraseValid(true)
                setIsThankYouPhraseValid(true)
                break
            default:
                setIsFirstNameValid(false);
                setIsLastNameValid(false);
                setIsEmailValid(false);
                setIsWelcomePhraseValid(false)
                setIsThankYouPhraseValid(false)
                break
        }
    }, [isErrorText])
    const handleSubmitImportantData = async () => {
        dispatch(IsLoading(true))
        try {
            const result = await UserService.updateUserImportantData(
                {
                    id,
                    firstName,
                    lastName,
                    userName,
                    userEmail,
                    birthDate,
                    profession,
                    companyName,
                    userCountry,
                    userCity,
                    region,
                    streetLine1,
                    streetLine2,
                    thankYouPhrase,
                    welcomePhrase,
                    facebook: userFacebook,
                    instagram: userInstagram,
                    whatsapp: `https://wa.me/${userWhatsapp}`,
                    linkedIn: userLinkedIn,
                    tiktok: userTikTok,
                    youtube: userYoutube,
                }
            )
            dispatch(addUser(result.data[0]))
            dispatch(PaymentStatusModalOpen(true))
        } catch (e) {
            setIsErrorText(e.response?.data?.message);
        }finally {
            dispatch(IsLoading(false))
        }
    }
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#0666EB' : '#0666EB',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#0666EB',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    const handleModalOpen = () => {
        dispatch(AvatarModal(true))
    }
    const handleCompanyLogoModalOpen = () => {
        dispatch(CompanyLogoModal(true))
    }
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const alertClose = () => {
        setemailVerifiedAlert(false)
    }
    const changePass = async () => {
        dispatch(IsLoading(true))
        try {
            const data = {
                password: newPassword,
                id,
                userPhone,
            }
            if(newPassword === newPasswordRepeat && newPassword !== "" && newPasswordRepeat !== "") {
                await axios.post(`${config.BACKEND_URL}forgot-password`, data);
                dispatch(PaymentStatusModalOpen(true))
                setNewPassword("");
                setNewPasswordRepeat("");
            }else {
                setError(true);
                setNotification("Password mismatch");
            }
        }catch (e){
            setError(true);
            setNotification(e.response?.data?.message);
        }finally {
            dispatch(IsLoading(false))
        }
    }
    const passShow = () => {
        setIsShow(!isShow);
    }
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter' && fixedMenuTabValue === "1") {
                handleSubmitImportantData();
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [handleSubmitImportantData]);
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter' && fixedMenuTabValue === "2") {
                changePass();
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [changePass]);
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter' && fixedMenuTabValue === "3") {
                sendSettingData();
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [sendSettingData]);

    return (
        <div className={style.profile_edit + " flex-lg-row flex-md-column flex-column"}>
            <SuccessModal/>
            {(fixedMenuTabValue === "1" && isMobile || (isDesktop)) &&
                <div className={darkMode ? style.profile_avatar + " col-lg-4 col-12 dark-block-bg" : style.profile_avatar + " col-lg-4 col-12"}>
                    <div className={style.profile_image}>
                        <div className={style.avatar}>
                            {isLoading
                                ?
                                <Skeleton variant="circular" width={366} height={366}/>
                                :
                                <img src={avatarPath ? avatarPath : avatar} alt="avatar"/>
                            }
                        </div>
                        <div className={style.edit_btns}>
                            <div className={style.edit_btn}><Button onClick={handleDelete}><DeleteIcon/></Button></div>
                            <div className={style.edit_btn}><Button onClick={handleModalOpen}><EditIcon/></Button></div>
                        </div>
                    </div>
                </div>
            }
            <div className={style.profile_content_edit + " col-lg-8 col-12"} style={{padding: isMobile ? "0" : "0 12px 0 24px"}}>
                <TabContext value={fixedMenuTabValue}>
                    {isDesktop &&
                        <Box sx={{borderBottom: 1, borderColor: darkMode ? "#fff" : 'divider'}}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                                scrollButtons={true}
                                allowScrollButtonsMobile
                                variant="scrollable"
                                centered={true}
                            >
                                <Tab label={t("Personal Information")} value="1"/>
                                <Tab label={t("Accesses")} value="2"/>
                                <Tab label={t("Ajustes")} value="3"/>
                            </TabList>
                        </Box>
                    }
                    <TabPanel value="1" className="p-2">
                        <div className={darkMode ? style.profile_content_edit_body + " row bg-white dark-block-bg" : style.profile_content_edit_body + " row bg-white"} style={{marginTop: `${isMobile ? "0px" : "24px"}`}}>
                            <div className={style.profile_edit_content_title + " col-md-4 col-12"}>{t("Personal Information")}</div>
                            <div className={darkMode ? style.profile_content_edit_items + " col-md-8 col-12 dark-input" : style.profile_content_edit_items + " col-md-8 col-12"}>
                                <Box noValidate autoComplete="on" sx={{width: "100%", maxWidth: '100%'}}>
                                    <TextField error={isFirstNameValid && "error"}
                                               helperText={isFirstNameValid && t("First Name is required")}
                                               className={style.profile_content_edit_item} value={firstName}
                                               id="outlined-basic" label={t("First name")} variant="outlined" fullWidth
                                               required onChange={(e) => handleFirstNameChange(e)}
                                               autoComplete="first-name"
                                    />
                                    <TextField error={isLastNameValid && "error"}
                                               helperText={isLastNameValid && t("Last Name is required")}
                                               className={style.profile_content_edit_item} value={lastName}
                                               id="outlined-basic" label={t("Last name")} variant="outlined" fullWidth
                                               required onChange={(e) => handleLastNameChange(e)}
                                    />
                                    <FormControl sx={{width: '100%'}} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">{t("E-mail")}</InputLabel>
                                        <OutlinedInput
                                            error={isEmailValid && "error"}
                                            helperText={isEmailValid && t("Email must be valid")}
                                            className={style.profile_content_edit_item}
                                            value={userEmail}
                                            id="outlined-basic"
                                            label={t("E-mail")}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            onChange={(e) => handleEmailChange(e)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {userEmailConfirm === 1
                                                        ?
                                                        <CheckCircleIcon sx={{color: "#519D62", fontSize: 25}}/>
                                                        :
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            // onClick={passShow}
                                                            edge="end"
                                                        >
                                                            <ErrorIcon sx={{color: "#ff9100", fontSize: 25}}/>
                                                        </IconButton>
                                                    }
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DemoContainer
                                            components={['DatePicker']}
                                        >
                                            <DatePicker
                                                label={t("Birth Date")}
                                                fullWidth
                                                className={style.profile_content_edit_item}
                                                onChange={(e) => handleBirthDateChange(e)}
                                                value={dayjs(birthDate)}
                                                views={['day', 'month', 'year']}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <TextField helperText="" className={style.profile_content_edit_item}
                                               value={profession} id="outlined-basic"
                                               label={t("Your Profession")}
                                               ariant="outlined"
                                               fullWidth onChange={(e) => handleProfessionChange(e)}
                                    />
                                    <div className={style.profile_content_edit_item + " d-flex align-items-center justify-content-center gap-2"}>
                                        <TextField helperText=""
                                                   value={companyName} id="outlined-basic" label={t("Your Company")}
                                                   ariant="outlined" fullWidth
                                                   onChange={(e) => handleCompanyNameChange(e)}
                                        />
                                        {isDesktop && <span style={{textAlign: "center"}}>{t("Logo of company")}</span>}
                                        <div className={style.profile_image}>
                                            <div className={style.avatar}>
                                                <Avatar
                                                    alt="Company logo"
                                                    src={companyAvatarPath}
                                                    sx={{
                                                        width: 56,
                                                        height: 56,
                                                        border: 1,
                                                        borderRadius: 100,
                                                        borderColor: "#C4C4C4"
                                                    }}
                                                />
                                            </div>
                                            <div className={style.edit_btns + " align-items-end"}
                                                 style={{width: 0, bottom: 0}}>
                                                <div className={style.edit_btn}><Button
                                                    onClick={handleCompanyLogoModalOpen}><EditIcon sx={{fontSize: 14}}/></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Tooltip
                                        title="The service is currently only available to residents of Spain."
                                        arrow
                                    >
                                        <TextField helperText="" className={style.profile_content_edit_item}
                                                   value={userCountry} id="outlined-basic" label={t("Country")}
                                                   ariant="outlined"
                                                   fullWidth
                                                   disabled="disabled"
                                                   onChange={(e) => handleCountryChange(e)}
                                                   onClick={handleTooltipOpen}
                                        />
                                    </Tooltip>

                                    {/*<FormControl fullWidth>*/}
                                    {/*    <InputLabel id="country-select-label">{t(Country)}</InputLabel>*/}
                                    {/*    <Select*/}
                                    {/*        labelId="country-select-label"*/}
                                    {/*        id="country-select"*/}
                                    {/*        value={userCountry}*/}
                                    {/*        onChange={handleChangeSelectCountry}*/}
                                    {/*        input={<OutlinedInput label="Name" />}*/}
                                    {/*        MenuProps={MenuProps}*/}
                                    {/*        className={style.profile_content_edit_item}*/}
                                    {/*    >*/}
                                    {/*        {countries.map((el) => (*/}
                                    {/*            <MenuItem*/}
                                    {/*                key={el.name.common}*/}
                                    {/*                value={el.name.common}*/}
                                    {/*            >*/}
                                    {/*                {el.name.common}*/}
                                    {/*            </MenuItem>*/}
                                    {/*        ))}*/}
                                    {/*    </Select>*/}
                                    {/*</FormControl>*/}

                                    <TextField helperText="" className={style.profile_content_edit_item}
                                               value={userCity} id="outlined-basic" label={t("City")} ariant="outlined"
                                               fullWidth onChange={(e) => handleCityChange(e)}/>
                                    <TextField helperText="" className={style.profile_content_edit_item} value={region}
                                               id="outlined-basic" label={t("Region")} ariant="outlined" fullWidth
                                               onChange={(e) => handleRegionChange(e)}/>
                                    <TextField helperText="" className={style.profile_content_edit_item}
                                               value={streetLine1} id="outlined-basic" label={t("Street line 1")}
                                               variant="outlined" fullWidth
                                               onChange={(e) => handleStreetLine1Change(e)}/>
                                    <TextField helperText="" className={style.profile_content_edit_item}
                                               value={streetLine2} id="outlined-basic" label={t("Street line 2")}
                                               variant="outlined" fullWidth
                                               onChange={(e) => handleStreetLine2Change(e)}/>
                                </Box>
                            </div>
                            <div className={style.profile_edit_content_title + " mt-4 col-md-4 col-12"}>{t("Social Media")}</div>
                            <div className={darkMode ? style.profile_content_edit_items + " mt-4 col-md-8 col-12 dark-input" : style.profile_content_edit_items + " col-md-8 col-12 mt-5"}>
                                <Box noValidate autoComplete="on" sx={{width: "100%", maxWidth: '100%',}}>
                                        <TextField error={isFirstNameValid && "error"}
                                                   helperText={t("Link to your profile in Facebook")}
                                                   className={style.profile_content_edit_item}
                                                   value={userFacebook}
                                                   id="outlined-basic"
                                                   label="Facebook" variant="outlined"
                                                   fullWidth
                                                   onChange={(e) => handleFacebookChange(e)}
                                        />
                                        <TextField error={isLastNameValid && "error"}
                                                   helperText={t("Link to your profile in Instagram")}
                                                   className={style.profile_content_edit_item}
                                                   value={userInstagram}
                                                   id="outlined-basic" label="Instagram" variant="outlined" fullWidth
                                                   onChange={(e) => handleInstagramChange(e)}
                                        />
                                        <TextField className={style.profile_content_edit_item}
                                                   helperText={t("Your Whatsapp number: 34123456789")}
                                                   value={userWhatsapp}
                                                   id="outlined-basic"
                                                   label="Whatsapp"
                                                   variant="outlined"
                                                   fullWidth
                                                   onChange={(e) => handleWhatsappChange(e)}
                                        />
                                        <TextField className={style.profile_content_edit_item}
                                                   helperText={t("Link to your profile in Linkedin")}
                                                   value={userLinkedIn}
                                                   id="outlined-basic"
                                                   label="Linkedin"
                                                   variant="outlined"
                                                   fullWidth
                                                   onChange={(e) => handleLinkedInChange(e)}
                                        />
                                        <TextField
                                            helperText={t("Link to your profile in TokTok")}
                                            className={style.profile_content_edit_item}
                                            value={userTikTok}
                                            id="outlined-basic"
                                            label="TikTok" variant="outlined"
                                            fullWidth
                                            onChange={(e) => handleTikTokChange(e)}
                                        />
                                        <TextField
                                            helperText={t("Link to your profile in Youtube")}
                                            className={style.profile_content_edit_item}
                                            value={userYoutube}
                                            id="outlined-basic"
                                            label="Youtube" variant="outlined"
                                            fullWidth
                                            onChange={(e) => handleYoutubeChange(e)}
                                        />
                                    <Button variant="contained" fullWidth onClick={handleSubmitImportantData} className="btn">{t("Save")}</Button>
                                </Box>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="2" className="p-2">
                        <div className={darkMode ? style.profile_content_edit_body + " row bg-white dark-block-bg" : style.profile_content_edit_body + " row bg-white"} style={{marginTop: `${isMobile ? "0px" : "24px"}`}}>
                            <div className={style.profile_edit_content_title + " col-md-4 col-12"}>{t("Accesses")}</div>
                            <div className={darkMode ? style.profile_content_edit_items + " col-md-8 col-12 dark-input" : style.profile_content_edit_items + " col-md-8 col-12"}>
                                <Box noValidate autoComplete="on" sx={{
                                    width: "100%",
                                    maxWidth: '100%',
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "15px"
                                }}>
                                    <PhoneInput
                                        defaultCountry="es"
                                        value={userPhone}
                                        onChange={(e) => handlePhoneChange(e)}
                                        required
                                        hideDropdown
                                        forceDialCode
                                        disableFocusAfterCountrySelect
                                        disableCountryGuess
                                        className={style.profile_content_edit_item + " mb-0 " + style.profile_content_edit_phone}
                                        disabled="disabled"
                                    />
                                    <FormControl sx={{width: '100%'}} variant="outlined">
                                        <InputLabel
                                            htmlFor="outlined-adornment-password">{t("New password")}</InputLabel>
                                        <OutlinedInput
                                            error={error && "error"}
                                            id="outlined-adornment-password"
                                            type={isShow ? 'text' : 'password'}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            value={newPassword}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={passShow}
                                                        edge="end"
                                                    >
                                                        {isShow ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={t("New password")}
                                        />
                                    </FormControl>
                                    <div>
                                        <FormControl sx={{width: '100%'}} variant="outlined">
                                            <InputLabel
                                                htmlFor="outlined-adornment-password">{t("Repeat new password")}</InputLabel>
                                            <OutlinedInput
                                                error={notification === "Password mismatch" && "error"}
                                                id="outlined-adornment-password"
                                                type={isShow ? 'text' : 'password'}
                                                onChange={(e) => setNewPasswordRepeat(e.target.value)}
                                                value={newPasswordRepeat}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={passShow}
                                                            // onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {isShow ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label={t("Repeat new password")}
                                            />
                                        </FormControl>
                                        {error && <FormHelperText error={notification === "Password mismatch" && "error"} id="outlined-weight-helper-text">{notification}</FormHelperText>}
                                    </div>
                                    <Button variant="contained" fullWidth onClick={changePass} className="btn">{t("Save")}</Button>
                                </Box>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="3" className="p-2">
                        <div className={darkMode ? style.profile_content_edit_body + " row bg-white dark-block-bg" : style.profile_content_edit_body + " row bg-white"} style={{marginTop: `${isMobile ? "0px" : "24px"}`}}>
                            <div className={darkMode ? style.profile_content_edit_items + " col-12 dark-input" : style.profile_content_edit_items + " col-12"}>
                                <FormGroup>
                                    <div className={darkMode ? style.profile_content_edit_body + " row bg-white dark-block-bg p-0" : style.profile_content_edit_body + " row bg-white p-0"}>
                                        <div className={style.profile_edit_content_title + " col-md-4 col-12"}>{t("The payment page")}</div>
                                        <div className={darkMode ? style.profile_content_edit_items + " col-md-8 col-12 dark-input" : style.profile_content_edit_items + " col-md-8 col-12"}>
                                            <Box noValidate autoComplete="on" sx={{width: "100%", maxWidth: '100%'}}>
                                                <TextField error={isWelcomePhraseValid && "error"}
                                                           helperText={isWelcomePhraseValid ? t("The welcome phrase field cannot contain less than 3 characters") : t("This text will be shown on the payment page")}
                                                           className={style.profile_content_edit_item}
                                                           value={welcomePhrase}
                                                           id="outlined-basic" label={t("Welcome phrase")}
                                                           variant="outlined" fullWidth
                                                           required onChange={(e) => handleWelcomePhraseChange(e)}
                                                />

                                                <div className="d-flex" style={{marginBottom: "30px"}}>
                                                    <FormControl fullWidth sx={{marginRight: 1}}>
                                                        <InputLabel htmlFor="outlined-adornment-amount">{t("Total amount")}</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-amount"
                                                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                                            label="Total amount"
                                                            value={userTotalAmount}
                                                            type="number"
                                                            inputMode="numeric"
                                                            onChange={(e) => handleTotalAmountChange(e.target.value)}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="outlined-adornment-amount">{t("Collected")}</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-amount"
                                                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                                            label="How much is left"
                                                            value={userCollected}
                                                            type="number"
                                                            inputMode="numeric"
                                                            onChange={(e) => handleCollectedChange(e.target.value)}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <TextField error={isThankYouPhraseValid && "error"}
                                                           helperText={isThankYouPhraseValid ? t("The thank you phrase field cannot contain less than 3 characters") : t("This text will be shown after successful payment")}
                                                           className={style.profile_content_edit_item}
                                                           value={thankYouPhrase}
                                                           id="outlined-basic" label={t("Thank you phrase")}
                                                           variant="outlined" fullWidth
                                                           required onChange={(e) => handleThankYouPhraseChange(e)}/>
                                                {/*<FormControl fullWidth sx={{marginRight: 1}}>*/}
                                                {/*    <InputLabel htmlFor="outlined-adornment-amount">{t("Minimum amount")}</InputLabel>*/}
                                                {/*    <OutlinedInput*/}
                                                {/*        id="outlined-adornment-amount"*/}
                                                {/*        startAdornment={<InputAdornment position="start">€</InputAdornment>}*/}
                                                {/*        label="minimum amount"*/}
                                                {/*        value={userMinimumAmount}*/}
                                                {/*        onChange={(e) => handleMinimumAmountChange(e.target.value)}*/}
                                                {/*    />*/}
                                                {/*</FormControl>*/}
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="w-100 flex-md-row flex-sm-row flex-column d-flex align-items-center mt-4 mb-4">
                                        <span className={style.profile_content_edit_item_title + " col-xl-4 col-md-4 col-sm-5 col-12"}>{t("Allow ranking")}</span>
                                        <FormControlLabel
                                            className="col-xl-5 col-md-5 col-sm-7 col-12 ps-3"
                                            control={<IOSSwitch sx={{m: 1}}/>}
                                            label={t("allows ranking on the payment page")}
                                            checked={isRatingInPaymentPage}
                                            onChange={handleIsRatingChecked}
                                        />
                                    </div>
                                    <div className="w-100 flex-md-row flex-sm-row flex-column d-flex align-items-center mb-4">
                                        <span className={style.profile_content_edit_item_title + " col-xl-4 col-md-4 col-sm-5 col-12"}>{t("Allow reviews")}</span>
                                        <FormControlLabel
                                            className="col-xl-5 col-md-5 col-sm-7 col-12 ps-3"
                                            control={<IOSSwitch sx={{m: 1}}/>}
                                            label={t("allow reviews on the payment page")}
                                            checked={isReviewInPaymentPage}
                                            onChange={handleIsReviewChecked}
                                        />
                                    </div>
                                    <div className="w-100 flex-md-row flex-sm-row flex-column d-flex align-items-center mb-4">
                                        <span className={style.profile_content_edit_item_title + " col-xl-4 col-md-4 col-sm-5 col-12"}>{t("Notify by email")}</span>
                                        <FormControlLabel
                                            className="col-xl-5 col-md-5 col-sm-7 col-12 ps-3"
                                            control={<IOSSwitch sx={{m: 1}}/>}
                                            label={t("notify the income and refunds via email")}
                                            checked={isEmailNotification}
                                            onChange={handleEmailNotificationChecked}
                                        />
                                    </div>
                                    <div className="w-100 flex-md-row flex-sm-row flex-column d-flex align-items-start mb-4">
                                        <span className={style.profile_content_edit_item_title + " col-xl-4 col-md-4 col-sm-5 col-12"}>{t("Notify by WhatsApp")}</span>
                                        <div className="col-xl-5 col-md-5 col-sm-7 col-12 d-flex flex-column">
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{m: 1}}/>}
                                                label={t("notify the income and withdrawals via WhatsApp")}
                                                disabled="disabled"
                                                className="ps-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-100 flex-md-row flex-sm-row flex-column d-flex align-items-start mb-4">
                                        <span className={style.profile_content_edit_item_title + " col-xl-4 col-md-4 col-sm-5 col-12"}>{t("Notify by Telegram")}</span>
                                        <div className="col-xl-5 col-md-5 col-sm-7 col-12 d-flex flex-column">
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{m: 1}}/>}
                                                label={t("notify the income and withdrawals via Telegram")}
                                                disabled="disabled"
                                                className="ps-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-100 flex-md-row flex-sm-row flex-column d-flex align-items-start mb-4">
                                        <span className={style.profile_content_edit_item_title + " col-xl-4 col-md-4 col-sm-5 col-12"}>{t("Notify by SMS")}</span>
                                        <div className="col-xl-5 col-md-5 col-sm-7 col-12 d-flex flex-column">
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{m: 1}}/>}
                                                label={t("* it has a cost of 0.05 € per sms")}
                                                disabled="disabled"
                                                className="ps-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-100 pt-4 mb-4 border-top">
                                        <div className={style.profile_edit_content_title + " col-md-4 col-12"}>{t("Survey")}</div>
                                        <div className={style.profile_edit_content_subtitle + " col-md-4 col-12"}>{t("Why do they pay tips?")}</div>
                                        <div className="flex-wrap justify-content-between flex-md-row flex-sm-row flex-column d-flex align-items-start">
                                            <div className="col-xxl-2 col-xl-3 col-md-4 col-sm-6 col-12 d-flex flex-xl-column flex-lg-column flex-md-column flex-sm-row align-items-center mb-3 gap-xl-0 gap-lg-0 gap-md-0 gap-sm-5">
                                                <GoodDrink active={isGoodDrinksPaymentPage} width={70}/>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{m: 1}}/>}
                                                    label={t("Good drinks")}
                                                    labelPlacement="top"
                                                    className="m-0 col-xl-11 col-lg-7 col-md-6 col-sm-6 col-8"
                                                    checked={isGoodDrinksPaymentPage}
                                                    onChange={handleIsGoodDrinksChecked}
                                                />
                                            </div>
                                            <div className="col-xxl-2 col-xl-3 col-md-4 col-sm-6 col-12 d-flex flex-xl-column flex-lg-column flex-md-column flex-sm-row align-items-center mb-3 gap-xl-0 gap-lg-0 gap-md-0 gap-sm-5">
                                                <CleanIcon active={isCleanPremisesPaymentPage} width={70}/>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{m: 1}}/>}
                                                    label={t("Clean premises")}
                                                    labelPlacement="top"
                                                    className="m-0 col-xl-11 col-lg-7 col-md-6 col-sm-6 col-8"
                                                    checked={isCleanPremisesPaymentPage}
                                                    onChange={handleIsCleanPremisesChecked}
                                                />
                                            </div>
                                            <div className="col-xxl-2 col-xl-3 col-md-4 col-sm-6 col-12 d-flex flex-xl-column flex-lg-column flex-md-column flex-sm-row align-items-center mb-3 gap-xl-0 gap-lg-0 gap-md-0 gap-sm-5">
                                                <GoodFood active={isGoodFoodPaymentPage} width={70}/>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{m: 1}}/>}
                                                    label={t("Good food")}
                                                    labelPlacement="top"
                                                    className="m-0 col-xl-11 col-lg-7 col-md-6 col-sm-6 col-8"
                                                    checked={isGoodFoodPaymentPage}
                                                    onChange={handleIsGoodFoodChecked}
                                                />
                                            </div>
                                            <div className="col-xxl-2 col-xl-3 col-md-4 col-sm-6 col-12 d-flex flex-xl-column flex-lg-column flex-md-column flex-sm-row align-items-center mb-3 gap-xl-0 gap-lg-0 gap-md-0 gap-sm-5">
                                                <GoodMusic active={isGoodMusicPaymentPage} width={70}/>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{m: 1}}/>}
                                                    label={t("Good music")}
                                                    labelPlacement="top"
                                                    className="m-0 col-xl-11 col-lg-7 col-md-6 col-sm-6 col-8"
                                                    checked={isGoodMusicPaymentPage}
                                                    onChange={handleIsGoodMusicChecked}
                                                />
                                            </div>
                                            <div className="col-xxl-2 col-xl-3 col-md-4 col-sm-6 col-12 d-flex flex-xl-column flex-lg-column flex-md-column flex-sm-row align-items-center mb-3 gap-xl-0 gap-lg-0 gap-md-0 gap-sm-5">
                                                <GoodService active={isGoodServicePaymentPage} width={70}/>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{m: 1}}/>}
                                                    label={t("Good service")}
                                                    labelPlacement="top"
                                                    className="m-0 col-xl-11 col-lg-7 col-md-6 col-sm-6 col-8"
                                                    checked={isGoodServicePaymentPage}
                                                    onChange={handleIsGoodServiceChecked}
                                                />
                                            </div>
                                            <div className="col-xxl-2 col-xl-3 col-md-4 col-sm-6 col-12 d-flex flex-xl-column flex-lg-column flex-md-column flex-sm-row align-items-center mb-3 gap-xl-0 gap-lg-0 gap-md-0 gap-sm-5">
                                                <LikeEuro active={isLikeEuroPaymentPage} width={70}/>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{m: 1}}/>}
                                                    label={t("Like euro")}
                                                    labelPlacement="top"
                                                    className="m-0 col-xl-11 col-lg-7 col-md-6 col-sm-6 col-8"
                                                    checked={isLikeEuroPaymentPage}
                                                    onChange={handleIsLikeEuroChecked}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Button variant="contained" fullWidth onClick={sendSettingData} className="col-md-8 col-12 btn">{t("Save")}</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    )
}

export default ProfileEditContent;
import React from 'react';
import {useSelector} from "react-redux";

export default function GoodDrink({active, scale, width}) {
    const darkMode = useSelector((state) => state.settings.darkMode);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} style={{transform: `scale(${scale})`, transition: ".2s"}}>
            <g>
                <path className="st0" style={{ fill: darkMode ? '#FFFFFF' : '#1F3150' }} d="M382.2,285.8c-71.1,0-128.9-57.8-128.9-128.9S311.1,28,382.2,28s128.9,57.8,128.9,128.9   S453.3,285.8,382.2,285.8z M382.2,73.3c-46.1,0-83.6,37.5-83.6,83.6s37.5,83.6,83.6,83.6s83.6-37.5,83.6-83.6   S428.3,73.3,382.2,73.3z"/>
                <path className="st1" fill={active ? "#0666EB" : "#A3ABB7"} d="M341.6,141.6H182.8H23.9c-20.3,0-30.7,24.5-16.4,39L76.1,251l86.5,88.7v103.5c0,0.3-0.2,0.5-0.5,0.5h-58.4   c-11.1,0-20.2,9.1-20.2,20.2v0c0,11.1,9.1,20.2,20.2,20.2h158.3c11.1,0,20.2-9.1,20.2-20.2v0c0-11.1-9.1-20.2-20.2-20.2h-58.4   c-0.3,0-0.5-0.2-0.5-0.5V339.7l86.5-88.7l68.6-70.3C372.3,166.1,362,141.6,341.6,141.6z"/>
            </g>
        </svg>
    );
}

import React from 'react';
import NumberFormat from "react-number-format";
import style from "./payment-components/PaymentComponent.module.scss";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {Amount} from "../redux/slices/paymentSlice";
import {HiOutlineMinus, HiOutlinePlus} from "react-icons/hi2";

function limit(val, max) {
    if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            val = '01';
        } else if (val > max) {
            val = max;
        }
    }

    return val;
}

function cardExpiry(val) {
    let month = limit(val.substring(0, 2), '12');
    let date = limit(val.substring(2, 4), '31');

    return month + (date.length ? '/' + date : '');
}

function DecimalInput({minAmount}) {
    const amount = useSelector(state => state.currency.amount);
    const dispatch = useDispatch();

    const handleIncrement = () => {
        dispatch(Amount(amount + 1));
    };

    const handleDecrement = () => {
        dispatch(Amount(Math.max(amount - 1, 1.00)));
    };

    return (
        <div className="d-flex flex-row align-items-start gap-2">
            <Button onClick={handleDecrement} style={{backgroundColor:"#F0F2F9"}}><HiOutlineMinus color="#000"/></Button>
            <div className={style.price_input}>
                <NumberFormat
                    value={amount}
                    displayType={'input'}
                    inputMode="numeric"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    onValueChange={(values) => {
                        const { floatValue } = values;
                        if(floatValue < 1.00) {
                            dispatch(Amount(1.00));
                        }else {
                            dispatch(Amount(floatValue));
                        }
                    }}
                />
                <div className={style.minimum_amount}>Importe mínimo 1 <span className="no-translate">€</span></div>
            </div>
            <Button onClick={handleIncrement} style={{backgroundColor:"#F0F2F9"}}><HiOutlinePlus color="#000"/></Button>
        </div>
    );
}

export default DecimalInput;

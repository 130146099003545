import React from 'react';
import style from './WhyYouShouldUseOurService.module.scss';
import image1 from '../../../assets/images/why-you-should-use-our-service-1.png'
import image2 from '../../../assets/images/why-you-should-use-our-service-2.png'
import image3 from '../../../assets/images/why-you-should-use-our-service-3.png'
import image4 from '../../../assets/images/why-you-should-use-our-service-4.png'
import {useSelector} from "react-redux";
import SectionSmallTitle from "../../SectionSmallTitle";
import WhyYouShouldUseOurServiceItem from "./WhyYouShouldUseOurServiceItem";

function WhyYouShouldUseOurService(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    return (
        <section className={darkMode ? style.why_you_should_use_our_service + " dark-section-bg light-text" : style.why_you_should_use_our_service}>
            <div className="container">
                <SectionSmallTitle title="Why you should use our service?" textAlign="center"/>
                <div className={"row " + style.why_you_should_use_our_service_items}>
                    <WhyYouShouldUseOurServiceItem text="Instant withdrawal to a bank card" image={image1}/>
                    <WhyYouShouldUseOurServiceItem text="The payer will not see your personal data when transferring" image={image2}/>
                    <WhyYouShouldUseOurServiceItem text="Reports on received funds are displayed immediately" image={image3}/>
                    <WhyYouShouldUseOurServiceItem text="QR-code in a friendly format for printing on business cards, receipts, badges, etc." image={image4}/>
                </div>
            </div>
        </section>
    );
}

export default WhyYouShouldUseOurService;
import React, {useEffect} from 'react';
import AboutBanner from "../components/about-us-page-components/about-banner/AboutBanner";
import ForWhomThisService from "../components/about-us-page-components/for-whom-this-service/ForWhomThisService";
import OurVisionAndMission from "../components/about-us-page-components/our-vision-and-mission/OurVisionAndMission";
import JoinOurService from "../components/about-us-page-components/join-our-service/JoinOurService";
import WhyYouShouldUseOurService from "../components/about-us-page-components/why-you-should-use-our-service/WhyYouShouldUseOurService";
import {useLocation} from "react-router-dom";
function About(props) {
    const location = useLocation();

    useEffect(() => {
        document.title = "About Us - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);

    return (
        <div className="about">
            <AboutBanner/>
            <ForWhomThisService/>
            <OurVisionAndMission/>
            <WhyYouShouldUseOurService/>
            <JoinOurService/>
        </div>
    );
}

export default About;
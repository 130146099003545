import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {useDispatch, useSelector} from "react-redux";
import {PaymentStatusModalOpen} from "../redux/slices/paymentSlice";
import {useEffect} from "react";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 268,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export default function SuccessModal() {
    const {payStatusModalOpen} = useSelector(state => state.currency);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(PaymentStatusModalOpen(false));
    };

    useEffect(() => {
        setTimeout(()=> {
            handleClose()
        }, 1400)
    }, [payStatusModalOpen]);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={payStatusModalOpen}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={payStatusModalOpen}>
                    <Box sx={style}>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

import React, {useEffect, useState} from 'react';
import '../style/GetStarted.scss';
import 'react-international-phone/style.css';
import {useDispatch, useSelector} from "react-redux";
import {IsConfirmCode, IsForgetPassInputs, IsLogin} from "../../../redux/slices/getStartedSlice";
import Timer from "../../timer/Timer";
import userService from "../../../services/UserService";
import Button from "@mui/material/Button";
import {Box, FormHelperText, TextField} from "@mui/material";
import style from "../../profile-components/profile-edit/ProfileEdit.module.scss";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {IsLoading} from "../../../redux/slices/userSlice";
function SendSMSComponent() {
    const [code, setCode] = useState('');
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);
    const [notification, setNotification] = useState('');
    const {smsSendUrl, user} = useSelector(state => state.user);
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation()
    const navigate = useNavigate();
    const confirm = async () => {
        dispatch(IsLoading(true))
        try {
            const data = {code}
            const url = smsSendUrl ? smsSendUrl : 'confirm'
            await userService.confirmUser(url, data)
            // smsSendUrl ? dispatch(IsForgetPassInputs(true)) : dispatch(IsLogin(true));
            smsSendUrl ? navigate("/forgot/change") : navigate("/sign-in");
        }catch (e){
            setIsError(true);
            setNotification(e.response?.data?.message);
        }finally {
            dispatch(IsLoading(false))
        }
    }

    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                confirm();
            }
        };

        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [confirm]);

    return (
        <div className="get-started">
            <div className="get-started_title text-center col-md-5 col-12">{t("A confirmation code has been sent to phone")}</div>
            <div className="get-started_form d-flex flex-column col-lg-3 col-md-6 col-sm-8 col-12">
                <div className={darkMode ? "dark-input" : ""}>
                    {/*<Form.Control*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Code"*/}
                    {/*    onChange={(e) => setCode(parseInt(e.target.value))}*/}
                    {/*    value={code}*/}
                    {/*    className="get-started_form_input"*/}
                    {/*    maxLength={4}*/}
                    {/*/>*/}
                    <Box noValidate autoComplete="on" sx={{width: "100%", maxWidth: '100%'}}>
                        <TextField
                            error={isError && "error"}
                            type="number"
                            className={style.profile_content_edit_item}
                            value={code}
                            id="outlined-basic" label="Code" variant="outlined" fullWidth
                            onChange={(e) => setCode(parseInt(e.target.value))}
                        />
                        {isError && <FormHelperText error={isError && "error"} id="outlined-weight-helper-text">{notification}</FormHelperText>}
                    </Box>
                </div>
                <Button variant="contained" className="send-button mb-0" onClick={confirm}>{t("Confirm")}</Button>
                <Timer initialSeconds={20}/>
            </div>
        </div>
    );
}
export default SendSMSComponent;
import $api from "../http";

export default class PaymentService {

    static async transaction(data) {
        return $api.post("send-transaction", data);
    }

    static async getTransaction(id) {
        return $api.post("get-transaction", {id});
    }

    static async rating(data) {
        return $api.post("update-rating", data);
    }

    static async reaction(data) {
        return $api.post("update-reaction", data);
    }

    static async accountPayment(data) {
        return $api.post("account-payment", data);
    }

    static async getPaymentUser(data) {
        return $api.post("get-payment-user", data);
    }
}
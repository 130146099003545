import React from 'react';
import {PDFViewer} from "@react-pdf/renderer";
import {useLocation} from "react-router-dom";
import BusinessCard from "../components/pdf-components/BusinessCard";
import BusinessCard2 from "../components/pdf-components/BusinessCard2";
import CircleCard from "../components/pdf-components/CircleCard";
import TableTent from "../components/pdf-components/TableTent";
import SquareSticker from "../components/pdf-components/SquareSticker";
import SquareSticker2 from "../components/pdf-components/SquareSticker2";
import QRCode from "../components/pdf-components/QRCode";

function PDFViewerPage() {
    const location = useLocation();
    const id = location.pathname.split("/")[3];
    const phone = location.pathname.split("/")[4];
    const pathname = location.pathname.split("/")[5];

    const formatPhone = phone => {
        if (!phone) return ''; // Проверка на undefined
        const cleanedPhone = phone.startsWith('+34') ? phone.slice(3) : phone;
        return cleanedPhone.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3-$4');
    };

    return (
        <PDFViewer
            children={
                pathname === "Business%20card"
                    ?
                    <BusinessCard id={id} phone={formatPhone(phone)}/>
                    :
                    pathname === "Business%20card%20%E2%84%962"
                        ?
                        <BusinessCard2 id={id} phone={formatPhone(phone)}/>
                        :
                        pathname === "Table%20tent%20with%20company%20code"
                            ?
                            <TableTent id={id} phone={formatPhone(phone)}/>
                            :
                            pathname === "Round%20sticker"
                                ?
                                <CircleCard id={id} phone={formatPhone(phone)}/>
                                :
                                pathname === "Square%20sticker"
                                    ?
                                    <SquareSticker id={id} phone={formatPhone(phone)}/>
                                    :
                                    pathname === "Square%20sticker%20%E2%84%962"
                                    ?
                                    <SquareSticker2 id={id} phone={formatPhone(phone)}/>
                                        :
                                        pathname === "QR%20code"
                                        ?
                                            <QRCode id={id} phone={formatPhone(phone)}/>
                                            :
                                            <BusinessCard id={id} phone={formatPhone(phone)}/>
            }
            width="100%"
            style={{height: "100vh"}}
        />
    );
}

export default PDFViewerPage;
import React from 'react';
import style from './DoYouWantToAcceptTips.module.scss'
import image from '../../assets/images/offer-to-use-app.png'
import CustomLink from "../CustomLink";
import {useSelector} from "react-redux";
import SectionSmallTitle from "../SectionSmallTitle";
import SectionDescription from "../SectionDescription";
function DoYouWantToAcceptTips({bgc}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {auth} = useSelector(state => state.user);

    return (
        <section className={darkMode ? style.do_you_want_to_accept_tips + " dark-section-bg light-text" : style.do_you_want_to_accept_tips} style={{backgroundColor: bgc}}>
            <div className="container">
                <div className={"row d-flex justify-content-between align-items-center " + style.do_you_want_to_accept_tips_items}>
                    <div className={"col-md-7 col-12 " + style.do_you_want_to_accept_tips_content} data-aos="zoom-in" data-aos-duration="1500">
                        <SectionSmallTitle title="Do you want to accept tips?" />
                        <SectionDescription text="Sign up with TipsSi to receive tips" col="font-bold text-white"/>
                        <CustomLink className={style.btn} link={auth ? "/profile" : "/sign-in"} text="Get Started"/>
                    </div>
                    <div className={"flex-column justify-center d-sm-flex col-sm-5 col-12 p-0 " + style.do_you_want_to_accept_tips_image} data-aos="fade-left" data-aos-duration="1500"><img src={image} alt="Payment"/></div>
                </div>
            </div>
        </section>
    );
}

export default DoYouWantToAcceptTips;
import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import style from "./Modal.module.scss";
import {useDispatch, useSelector} from "react-redux";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Stack from "@mui/material/Stack";
import {CompanyImagePath, IsLoading} from "../../redux/slices/userSlice";
import UserService from "../../services/UserService";
import {useTranslation} from "react-i18next";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function SelectCompanyLogoModal(props) {
    const [albumId, setAlbumId] = useState('M8tqdQO');
    const [selectedFile, setSelectedFile] = useState(null);
    const cropperRef = useRef(null);
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.user);
    const userID = user.id
    const [disableButton, setDisableButton] = useState(false);
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    const handleUpload = async (image) => {
        dispatch(IsLoading(true));
        setDisableButton(true)
        try {
            const file = new FormData();
            file.append('file', image)
            const result = await UserService.saveCompanyLogo(file);
            const link = result.data.link;
            dispatch(CompanyImagePath(link))
            props.onHide();
        } catch (e) {
            console.error('Error uploading image:', e);
        } finally {
            dispatch(IsLoading(false));
            setDisableButton(false)
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(URL.createObjectURL(file));
        }
    };

    const cropImage = () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const croppedCanvas = cropper.getCroppedCanvas();
            if (croppedCanvas) {
                croppedCanvas.toBlob((blob) => {
                    const croppedFile = new File([blob], `${userID}.png`, { type: "image/png" });
                    handleUpload(croppedFile);
                });
            }
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className={darkMode && "dark-block-bg light-text"}>
                <Modal.Title id="contained-modal-title-vcenter" className={style.modal_title}>
                    {t("Upload a new avatar")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={style.modal_body}>
                <Cropper
                    style={{ height: 400, width: "100%" }}
                    zoomTo={0.1}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={selectedFile}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    ref={cropperRef}
                    aspectRatio={1}
                />
            </Modal.Body>
            <Modal.Footer className={darkMode ? style.modal_footer + " dark-block-bg" : style.modal_footer}>
                <Stack spacing={1} direction="row" className="d-flex flex-md-row flex-column justify-content-md-end justify-content-center col-md-10 col-12">
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        size="large"
                        disabled={disableButton && "disabled"}
                    >
                        {t("Select image")}
                        <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange}
                            accept="image/*"
                        />
                    </Button>
                    <Button variant="contained" size="large" onClick={cropImage} className="ms-md-2 mt-md-0 mt-3 ms-0" disabled={disableButton && "disabled"}>{t("Save")}</Button>
                    <Button variant="contained" size="large" onClick={() => props.onHide()} className="ms-md-2 mt-md-0 mt-3 ms-0" disabled={disableButton && "disabled"}>{t("Cancel")}</Button>
                </Stack>
            </Modal.Footer>
        </Modal>
    );
}

export default SelectCompanyLogoModal;

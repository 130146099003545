import React, {useEffect, useState} from 'react';
import "./BizumStyles.scss";
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup, FormHelperText,
    TextField, Tooltip
} from "@mui/material";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import style from "./TransferContent.module.scss";
import TabContext from "@mui/lab/TabContext";
import {useDispatch, useSelector} from "react-redux";
import {
    WithdrawalAmount,
    BankCard,
    RecipientPhoneNumber,
    BizumPhone,
    PaymentComment, PaymentStatusModalOpen, CheckModalOpen, Amount
} from "../../../redux/slices/paymentSlice";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from "@mui/material/Button";
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BizumIcon from "../../BizumIcon";
import {IsLoading} from "../../../redux/slices/userSlice";
import axios from "axios";
import config from "../../../config";
import {PhoneInput} from "react-international-phone";
import paymentService from "../../../services/PaymentService";
import PaymentService from "../../../services/PaymentService";
import { v4 as uuidv4 } from "uuid";
import SuccessModal from "../../SuccessModal";
import {useTranslation} from "react-i18next";
import Check from "./Check";
import CheckTemplate from "./CheckTemplate";
import TransferIcons from "./icons/TransferIcons";
import tipssiToBankCard from "../../../assets/images/tipssi-to-card.png"
import tipssiToBankCardActive from "../../../assets/images/tipssi-to-card-active.png"
import tipssiTotipssi from "../../../assets/images/tipssi-to-tipssi.png"
import tipssiTotipssiActive from "../../../assets/images/tipssi-to-tipssi-active.png"
import tipssiToBizum from "../../../assets/images/tipssi-tu-bizum.png"
import tipssiToBizumActive from "../../../assets/images/tipssi-tu-bizum-active.png"
import NumberFormat from "react-number-format";
import BankCardComponent from "./BankCardComponent";
import TipsSiToBizumIcon from "./icons/TipsSiToBizumIcon";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {AccountCircle} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
function limit(val, max) {
    if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            val = '01';

            //this can happen when user paste number
        } else if (val > max) {
            val = max;
        }
    }

    return val;
}

function cardExpiry(val) {
    let month = limit(val.substring(0, 2), '12');
    let date = limit(val.substring(2, 4), '31');

    return month + (date.length ? '/' + date : '');
}
function TransferContent(props) {
    const [value, setValue] = useState("1");
    const {bankCard, withdrawalAmount, bizumPhone, recipientPhoneNumber, paymentComment} = useSelector(state => state.currency)
    const {user} = useSelector(state => state.user)
    const handleChange = (event, newValue) => setValue(newValue);
    const dispatch = useDispatch();
    const [numberError, setNumberError] = useState("");
    const [balanceError, setBalanceError] = useState("");
    const [status, setStatus] = useState("");
    const {id, userAmount, userPhone, userFirstName, userLastName} = user || {};
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isBalance, setIsBalance] = useState(false);
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    const senderName = `${userFirstName} ${userLastName}`;
    const [checkAmount, setCheckAmount] = useState("");
    const [checkRecipientPhone, setCheckRecipientPhone] = useState("");
    const [checkRecipientName, setCheckRecipientName] = useState("");
    const [checkComment, setCheckComment] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [isFindUser, setIsFindUser] = useState(false);
    const [isFindBizumUser, setIsFindBizumUser] = useState(false);
    const [userName, setUserName] = useState("");

    useEffect(() => {
        if (recipientPhoneNumber.length >= 11) {
            setIsNumberValid(false);
        } else {
            setIsNumberValid(true);
        }

        if (parseFloat(withdrawalAmount) <= parseFloat(userAmount)) {
            setIsBalance(false);
        } else {
            setIsBalance(true);
        }
    }, [recipientPhoneNumber, withdrawalAmount, userAmount]);
    const handleAmountChange = (e) => {
        dispatch(WithdrawalAmount(e.target.value));
    }
    const handleBankCardChange = (e) => {
        dispatch(BankCard(e.target.value));
    }
    const handleBizumPhoneChange = (e) => {
        dispatch(BizumPhone(e));
    }
    const handleRecipientPhoneNumberChange = (e) => {
        dispatch(RecipientPhoneNumber(e));
        setIsFindUser(false)
    }
    const handlePaymentCommentChange = (e) => {
        dispatch(PaymentComment(e.target.value));
    }
    const bizumPayment = async () => {
        dispatch(IsLoading(true));
        const data = {
            amount: withdrawalAmount,
            bizumPhone,
            userId: id,
        };
        try {
            await axios.post(`${config.BACKEND_URL}bizum-payment`, { data });
        } catch (e) {
            setIsNumberValid(true);
            setNumberError(e.response?.data?.message);
        } finally {
            dispatch(IsLoading(false));
        }
    };
    const accountPayment = async () => {
        dispatch(IsLoading(true));
        const data = {
            userID: id,
            amount: withdrawalAmount,
            recipientPhoneNumber,
        };
        try {
            if(withdrawalAmount >= 1){
                const result = await paymentService.accountPayment(data);
                if (result.status === 200) {
                    setStatus("SUCCEEDED");
                    setCheckAmount(result.data.amount);
                    setCheckRecipientPhone(result.data.recipientPhoneNumber);
                    setCheckRecipientName(result.data.recipientName);
                    setDocumentNumber(result.data.checkNumber);
                    setIsFindUser(false)
                    // dispatch(BankCard(""));
                    setTimeout(() => {
                        dispatch(CheckModalOpen(true));
                    }, 1500)
                }
            }else {
                setIsBalance(true)
            }
        } catch (e) {
            if (e.response?.data?.message === "Not enough money on balance!") {
                setIsBalance(true);
                setBalanceError(e.response?.data?.message);
            } else if (e.response?.data?.message === "User with phone not found") {
                setIsNumberValid(true);
                setNumberError(e.response?.data?.message);
            }
        } finally {
            dispatch(IsLoading(false));
        }
    };
    const getUserData = async () => {
        dispatch(IsLoading(true));
        const data = {
            id,
            recipientPhoneNumber
        };
        try {
            const result = await paymentService.getPaymentUser(data);
            if(result.status === 200){
                setIsFindUser(true)
                const name = `${result.data.recipient[0].userFirstName} ${result.data.recipient[0].userLastName}`;
                setUserName(name)
            }
        } catch (e) {
            if (e.response?.data?.message === "Not enough money on balance!") {
                setIsBalance(true);
                setBalanceError(e.response?.data?.message);
            } else if (e.response?.data?.message === "User with phone not found") {
                setIsNumberValid(true);
                setNumberError(e.response?.data?.message);
            } else if (e.response?.data?.message === "This is your phone number!") {
                setIsNumberValid(true);
                setNumberError(e.response?.data?.message);
            }
        } finally {
            dispatch(IsLoading(false));
        }
    };
    const sendTransaction = async () => {
        const data = {
            payID: uuidv4(),
            userId: id,
            amount: withdrawalAmount,
            status,
            comment: paymentComment,
            type: "transfer",
            method: "To account",
            goodDrink: 0,
            cleanPremises: 0,
            goodFood: 0,
            goodMusic: 0,
            goodService: 0,
            likeEuro: 0,
        }
        const tipData = {
            payID: uuidv4(),
            phone: recipientPhoneNumber,
            amount: withdrawalAmount,
            status,
            comment: paymentComment,
            type: "tip",
            method: "To account",
            goodDrink: 0,
            cleanPremises: 0,
            goodFood: 0,
            goodMusic: 0,
            goodService: 0,
            likeEuro: 0,
        }
        try {
            await PaymentService.transaction(data)
            const result = await PaymentService.transaction(tipData)
            setCheckComment(result.data.comment)
            dispatch(PaymentStatusModalOpen(true))
            dispatch(RecipientPhoneNumber(""));
            dispatch(PaymentComment(""))
        }catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        if(status !== ""){
            sendTransaction()
        }
    }, [status]);
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter' && value === "1") {
                getUserData();
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [getUserData]);
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter'  && value === "1") {
                accountPayment();
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [accountPayment]);
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter'  && value === "3") {
                bizumPayment();
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [bizumPayment]);
    return (
        <>
            <SuccessModal/>
            <Check
                senderPhone={userPhone}
                senderName={senderName}
                amount={checkAmount}
                recipientPhone={checkRecipientPhone}
                recipientName={checkRecipientName}
                comment={checkComment}
                comicion="0.00"
                documentNumber={documentNumber}
                template={
                    <CheckTemplate
                        senderPhone={userPhone}
                        senderName={senderName}
                        amount={checkAmount}
                        recipientPhone={checkRecipientPhone}
                        recipientName={checkRecipientName}
                        comment={checkComment}
                        comicion="0.00"
                        documentNumber={documentNumber}
                    />
                }
            />
            <TabContext value={value}>
                <div className={style.transfer_items + " d-flex align-items-start gap-3 flex-lg-row flex-column"}>
                    <div className={darkMode ? style.transfer_item + " bg-white col-xl-4 col-lg-6 col-md-8 col-sm-10 offset-xl-2 offset-lg-1 offset-md-2 offset-sm-1 col-12 p-4 dark-block-bg light-text" : style.transfer_item + " bg-white col-xl-4 col-lg-6 col-md-8 col-sm-10 offset-xl-2 offset-lg-1 offset-md-2 col-12 p-4"}>
                        <div className={style.transfer_title}>{t("Transfer funds")}</div>
                        <div className={style.transfer_subtitle}>
                            {value === "1" && t("from TipsSi to TipsSi")}
                            {value === "2" && t("from TipsSi to Bank card")}
                            {value === "3" && t("from TipsSi to Bizum")}
                        </div>
                        <Box>
                            <TabList
                                onChange={handleChange}
                                // allowScrollButtonsMobile
                                centered
                                variant="scrollable"
                                scrollButtons="false"
                                aria-label="scrollable auto tabs example"
                                style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            >
                                <Tab value="1" icon={<TransferIcons image={value === "1" ? tipssiTotipssiActive : tipssiTotipssi}/>}/>
                                <Tab value="2" icon={<TransferIcons image={value === "2" ? tipssiToBankCardActive : tipssiToBankCard}/>}/>
                                {isDesktop && <Tab value="3" icon={<TransferIcons image={value === "3" ? tipssiToBizumActive : tipssiToBizum}/>}/>}
                                {isTablet && <Tab value="3" icon={<TransferIcons image={value === "3" ? tipssiToBizumActive : tipssiToBizum}/>}/>}
                                {isMobile && <Tab value="3" icon={<TipsSiToBizumIcon color={value === "3" ? "#0666EB" : "#B2B2B2"} iconColor={value === "3" ? "#30B8C1" : "#B2B2B2"}/>}/>}

                                {/*<Tooltip title={t("Service is temporarily unavailable")}>*/}
                                {/*    <span>*/}
                                {/*        <Tab value="1" icon={<TransferIcons image={value === "1" ? tipssiToBankCardActive : tipssiToBankCard}/>}/>*/}
                                {/*    </span>*/}
                                {/*</Tooltip>*/}
                                {/*<Tooltip title={t("Service is temporarily unavailable")}>*/}
                                {/*    <span>*/}
                                {/*        <Tab value="3" icon={<TransferIcons image={value === "3" ? tipssiToBizumActive : tipssiToBizum}/>}/>*/}
                                {/*    </span>*/}
                                {/*</Tooltip>*/}
                            </TabList>
                        </Box>
                        <TabPanel value="1" className="p-0 mt-4">
                            <Box
                                noValidate
                                autoComplete="on"
                                sx={{width: "100%", maxWidth: '100%', backgroundColor: "#fff", marginBottom: "24px"}}
                                className={darkMode && "dark-block-bg"}
                            >
                                <PhoneInput
                                    defaultCountry="es"
                                    value={recipientPhoneNumber}
                                    onChange={(phone) => handleRecipientPhoneNumberChange(phone)}
                                    required
                                    hideDropdown
                                    forceDialCode
                                    disableFocusAfterCountrySelect
                                    disableCountryGuess
                                    className={darkMode && " dark-input"}
                                />
                                {isNumberValid && <FormHelperText error={isNumberValid} id="outlined-weight-helper-text">{t(numberError)}</FormHelperText>}
                                {!isFindUser && <Box sx={{marginTop: "24px"}}><Button variant="contained" fullWidth onClick={getUserData} className="btn">{t("Search user")}</Button></Box>}
                                {isFindUser &&
                                    <>
                                        <div className="user-name mt-4">
                                            <TextField
                                                id="outlined-start-adornment"
                                                value={userName}
                                                label={t("User name")}
                                                sx={{width: "80%"}}
                                                className={darkMode ? " dark-input": ""}
                                                disabled="disabled"
                                            />
                                            <span style={{borderColor: darkMode ? "#C4C4C4" : " ", backgroundColor: darkMode ? "transparent" : "#F5F5F5"}}><PersonOutlineIcon /></span>
                                        </div>
                                        <TextField
                                            error={isBalance && "error"}
                                            value={withdrawalAmount}
                                            inputMode="numeric"
                                            id="outlined-basic" label={t("Transfer Amount")}
                                            variant="outlined" fullWidth
                                            onChange={(e) => handleAmountChange(e)}
                                            className={darkMode ? " mt-4 dark-input": "mt-4"}
                                        />
                                        {isBalance && <FormHelperText error={isBalance} id="outlined-weight-helper-text" className="mb-1">{balanceError}</FormHelperText>}
                                        <FormHelperText id="outlined-weight-helper-text" style={{color: darkMode && "#FF9100"}} error={isBalance && "error"} className="mb-4">{t("Minimum amount")} 1€</FormHelperText>
                                        <TextField
                                            value={paymentComment}
                                            id="outlined-basic" label={t("Comments (optional)")}
                                            variant="outlined" fullWidth
                                            onChange={(e) => handlePaymentCommentChange(e)}
                                            className={darkMode && "dark-input"}
                                        />
                                    </>
                                }
                            </Box>
                            {isFindUser && <Box sx={{marginTop: "24px"}}><Button variant="contained" fullWidth onClick={accountPayment} className="btn">{t("Transfer")}</Button></Box>}
                        </TabPanel>
                        <TabPanel value="2" className="p-0 mt-4">
                            <Box
                                noValidate
                                autoComplete="on"
                                sx={{width: "100%", maxWidth: '100%', backgroundColor: "#fff", marginBottom: "24px"}}
                                className={darkMode && "dark-block-bg"}
                            >
                                {/*<TextField*/}
                                {/*    value={bankCard}*/}
                                {/*    id="outlined-basic"*/}
                                {/*    label={t("Bank card")}*/}
                                {/*    variant="outlined"*/}
                                {/*    fullWidth*/}
                                {/*    onChange={(e) => handleBankCardChange(e)}*/}
                                {/*    // disabled="disabled"*/}
                                {/*    className={darkMode ? " dark-input": ""}*/}
                                {/*/>*/}
                                {/*<BankCardComponent/>*/}
                                <NumberFormat
                                    format="#### #### #### ####"
                                    label={t("Bank card")}
                                    id="outlined-basic"
                                    name="cc-number"
                                    className={darkMode ? style.card_input + " dark-input text-light" : style.card_input}
                                    placeholder="0000 0000 0000 0000"
                                />
                                <TextField
                                    value={withdrawalAmount}
                                    id="outlined-basic" label={t("Transfer Amount")}
                                    inputMode="numeric"
                                    variant="outlined" fullWidth
                                    onChange={(e) => handleAmountChange(e)}
                                    className={darkMode ? " mt-4 mb-4 dark-input" : "mt-4 mb-4"}
                                />
                                {/*<NumberFormat*/}
                                {/*    value={withdrawalAmount}*/}
                                {/*    displayType={'input'}*/}
                                {/*    thousandSeparator={'.'}*/}
                                {/*    decimalSeparator={','}*/}
                                {/*    decimalScale={2}*/}
                                {/*    fixedDecimalScale={true}*/}
                                {/*    onValueChange={(values) => {*/}
                                {/*        const { floatValue } = values;*/}
                                {/*        handleAmountChange(floatValue);*/}
                                {/*    }}*/}
                                {/*    label={t("Transfer Amount")}*/}
                                {/*/>*/}
                                <TextField
                                    value={paymentComment}
                                    id="outlined-basic" label={t("Comments (optional)")}
                                    variant="outlined" fullWidth
                                    onChange={(e) => handlePaymentCommentChange(e)}
                                    className={darkMode && "dark-input"}
                                />
                            </Box>
                            <Box sx={{marginTop: "24px"}}><Button variant="contained" fullWidth onClick={bizumPayment} disabled="disabled" className="btn">{t("Transfer")}</Button></Box>
                        </TabPanel>
                        <TabPanel value="3" className="p-0 mt-4">
                            <Box
                                noValidate
                                autoComplete="on"
                                sx={{width: "100%", maxWidth: '100%', backgroundColor: "#fff", marginBottom: "24px"}}
                                className={darkMode && "dark-block-bg"}
                            >
                                <PhoneInput
                                    defaultCountry="es"
                                    value={bizumPhone}
                                    onChange={(phone) => handleBizumPhoneChange(phone)}
                                    required
                                    hideDropdown
                                    forceDialCode
                                    disableFocusAfterCountrySelect
                                    // disabled="disabled"
                                    disableCountryGuess
                                    className={darkMode && " dark-input"}
                                />
                                {isNumberValid && <FormHelperText error={isNumberValid} id="outlined-weight-helper-text">{numberError}</FormHelperText>}
                                {!isFindBizumUser && <Box sx={{marginTop: "24px"}}><Button variant="contained" fullWidth onClick={getUserData} className="btn">{t("Search user")}</Button></Box>}
                                {isFindBizumUser &&
                                    <>
                                        <div className="user-name mt-4">
                                            <TextField
                                                id="outlined-start-adornment"
                                                value={userName}
                                                label="User name"
                                                sx={{width: "80%"}}
                                                className={darkMode ? " dark-input": ""}
                                                disabled="disabled"
                                            />
                                            <span style={{borderColor: darkMode ? "#C4C4C4" : " ", backgroundColor: darkMode ? "transparent" : "#F5F5F5"}}><PersonOutlineIcon /></span>
                                        </div>
                                        <TextField
                                            error={isBalance && "error"}
                                            value={withdrawalAmount}
                                            inputMode="numeric"
                                            id="outlined-basic" label={t("Transfer Amount")}
                                            variant="outlined" fullWidth
                                            onChange={(e) => handleAmountChange(e)}
                                            className={darkMode ? " mt-4 dark-input": "mt-4"}
                                        />
                                        {isBalance && <FormHelperText error={isBalance} id="outlined-weight-helper-text" className="mb-1">{balanceError}</FormHelperText>}
                                        <FormHelperText id="outlined-weight-helper-text" style={{color: darkMode && "#FF9100"}} error={isBalance && "error"} className="mb-4">{t("Minimum amount")} 1€</FormHelperText>
                                        <TextField
                                            value={paymentComment}
                                            id="outlined-basic" label={t("Comments (optional)")}
                                            variant="outlined" fullWidth
                                            onChange={(e) => handlePaymentCommentChange(e)}
                                            className={darkMode && "dark-input"}
                                        />
                                    </>
                                }
                            </Box>
                            {isFindBizumUser && <Box sx={{marginTop: "24px"}}><Button variant="contained" fullWidth onClick={bizumPayment} disabled="disabled" className="btn">{t("Transfer")}</Button></Box>}
                        </TabPanel>
                    </div>
                    <TabPanel value="1" className="p-0 col-lg-4 col-md-8 offset-lg-0 offset-md-2 offset-sm-1 col-sm-10 col-12">
                        <div className={style.transfer_conditions_danger_text + " mb-2"}><ErrorOutlineIcon /> {t("Transfer conditions")}</div>
                        <div className={darkMode ? style.transfer_conditions + " bg-white p-4 dark-block-bg light-text" : style.transfer_conditions + " bg-white p-4"}>
                            <ul className={style.transfer_conditions_list}>
                                <li className={style.transfer_conditions_list_item}> {t("You can transfer your funds in any amount to any tipssi.net user without a fee. Enter a personal code of the recipient, then enter the amount.")}</li>
                                <li className={style.transfer_conditions_list_item}> {t("Be careful when sending, transfers between users in the system are irreversible.")}</li>
                            </ul>
                        </div>
                    </TabPanel>
                    <TabPanel value="2" className="p-0 col-lg-4 col-md-8 offset-lg-0 offset-md-2 offset-sm-1 col-sm-10 col-12">
                        <div className={style.transfer_conditions_danger_text + " mb-2"}><ErrorOutlineIcon /> {t("Transfer conditions")}</div>
                        <div className={darkMode ? style.transfer_conditions + " bg-white p-4 dark-block-bg light-text" : style.transfer_conditions + " bg-white p-4"}>
                            <div className={style.transfer_conditions_title}>{t("Withdrawal conditions:")}</div>
                            <ul className={style.transfer_conditions_list}>
                                <li className={style.transfer_conditions_list_item}> {t("Withdrawal to any bank card. Commission for the transfer is not charged.")}</li>
                                <li className={style.transfer_conditions_list_item}> {t("For one card number available to 15 transfers per day and 20 transfers per month.")}</li>
                                <li className={style.transfer_conditions_list_item}> {t("After requesting withdrawal, follow the status in the list of transactions. Usually withdrawal does not take more than 1 minute.")}</li>
                            </ul>
                        </div>
                    </TabPanel>
                </div>
            </TabContext>
        </>
    );
}

export default TransferContent;

import React from 'react';
import style from './Banner.module.scss';
import CustomLink from "../CustomLink";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function Banner({image, title, subtitle, bgc}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {auth} = useSelector(state => state.user);
    const {t} = useTranslation();

    return (
        <section className={darkMode ? style.bannerContainer + " dark-section-bg" : style.bannerContainer} style={{backgroundColor: bgc}}>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className='col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-md-10 col-12' data-aos="fade-right" data-aos-duration="1500">
                        <div className={style.main_title}>{t(title)}</div>
                        <div className={darkMode ? style.main_subtitle + " mt-6 light-text" : style.main_subtitle + " mt-6"}>{t(subtitle)}</div>
                        <CustomLink className='custom_btn' link={auth ? "/profile" : "/sign-in"} text="Get Started"/>
                    </div>
                    <div className={'col-sm-6 col-12 ' + style.bannerImage} data-aos="zoom-in" data-aos-duration="1500"><img src={image} alt="Application"/></div>
                </div>
            </div>
        </section>
    );
}

export default Banner;
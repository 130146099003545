import React, { useState, useEffect } from 'react';
import {Button} from "react-bootstrap";
import AuthService from "../../services/AuthService";
import {useSelector} from "react-redux";

const Timer = ({ initialSeconds }) => {
    const [seconds, setSeconds] = useState(initialSeconds);
    const [isActive, setIsActive] = useState(true);
    useEffect(() => {
        let timerId;
        if (isActive && seconds > 0) {
            timerId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        }
        return () => clearInterval(timerId);
    }, [isActive, seconds]);

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };
    const sendSMS = async () => {
        try {
            setSeconds(initialSeconds);
            setIsActive(true);
            await AuthService.sendSMS()
        }catch (e){
            console.log(e)
        }
    }
    return (
        <div>
            <Button variant="primary" onClick={sendSMS} disabled={seconds !== 0 && "disabled"}>{seconds !== 0 ? "Send the code again via " + formatTime(seconds) : "Send the code again via" }</Button>
        </div>
    );
};

export default Timer;

import React, {useEffect, useRef} from 'react';
import ProfileHeader from "../components/profile-components/profile-header/ProfileHeader";
import PrintItem from "../components/profile-components/print-component/PrintItem";
import horizontal from "../assets/images/horizontal.png";
import tent from "../assets/images/tent.png";
import roundSticker from "../assets/images/round-sticker.png";
import qr from "../assets/images/qr-download.png";
import qrRemoveBG from "../assets/images/qr-download-removebg.png";
import qrRemoveBGDarkMode from "../assets/images/darkThemeQR.png";
import BusinessCard from "../components/pdf-components/BusinessCard";
import { useSelector } from "react-redux";
import BusinessCardPNG from "../components/png-components/BusinessCardPNG";
import { useLocation } from "react-router-dom";
import TableTent from "../components/pdf-components/TableTent";
import BusinessCard2 from "../components/pdf-components/BusinessCard2";
import CircleCard from "../components/pdf-components/CircleCard";
import SquareSticker from "../components/pdf-components/SquareSticker";
import SquareSticker2 from "../components/pdf-components/SquareSticker2";
import QRCode from "../components/pdf-components/QRCode";


function Print() {
    const { user, userName, firstName, lastName, welcomePhrase, userPhone } = useSelector(state => state.user);
    const name = firstName ? `${firstName} ${lastName}` : userName;
    const id = user && user.id;
    const location = useLocation();
    const businessCardRef = useRef(null);
    const darkMode = useSelector((state) => state.settings.darkMode);

    useEffect(() => {
        document.title = "Print - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);

    return (
        <section className="print pt-0">
            <div className="container">
                <ProfileHeader />
                <div className="row flex-wrap">
                    <PrintItem
                        title="Business card"
                        size="5cm x 9cm"
                        image={horizontal}
                        name={name}
                        welcomePhrase={welcomePhrase}
                        pdfFile={<BusinessCard id={id} phone={userPhone} />}
                        pngFile={<BusinessCardPNG id={id} phone={userPhone} ref={businessCardRef} />}
                    />
                    <PrintItem
                        title="Business card №2"
                        size="5cm x 9cm"
                        image={horizontal}
                        pdfFile={<BusinessCard2 id={id} phone={userPhone} />}
                    />
                    <PrintItem
                        title="Table tent with company code"
                        size="15cm x 15cm"
                        image={tent}
                        pdfFile={<TableTent id={id} phone={userPhone} />}
                    />
                    <PrintItem
                        title="Round sticker"
                        size="14cm in diameter"
                        image={roundSticker}
                        pdfFile={<CircleCard id={id} phone={userPhone} />}
                    />
                    <PrintItem
                        title="Square sticker"
                        size="15cm x 15cm"
                        image={qr}
                        pdfFile={<SquareSticker id={id} phone={userPhone} />}
                    />
                    <PrintItem
                        title="Square sticker №2"
                        size="15cm x 15cm"
                        image={qr}
                        pdfFile={<SquareSticker2 id={id} phone={userPhone} />}
                    />
                    <PrintItem
                        title="QR code"
                        size="1000x1000px"
                        image={darkMode ? qrRemoveBGDarkMode : qrRemoveBG}
                        pdfFile={<QRCode id={id} phone={userPhone} />}
                    />
                </div>
            </div>
        </section>
    );
}

export default Print;

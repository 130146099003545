import React, { useEffect, useState, useCallback } from 'react';
import '../style/GetStarted.scss';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { IsConfirmCode, IsForgetPassGetUser, IsLogin, IsRegistration } from '../../../redux/slices/getStartedSlice';
import { addUser, Auth, IsLoading } from '../../../redux/slices/userSlice';
import AuthService from '../../../services/AuthService';
import InputLabel from '@mui/material/InputLabel';
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    IconButton,
    InputAdornment,
    OutlinedInput,
    FormControl,
    Button
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";

function SignInComponent(props) {
    const [isShow, setIsShow] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [isNotification, setIsNotification] = useState(false);
    const [error, setError] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const darkMode = useSelector((state) => state.settings.darkMode);
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const [rememberMe, setRememberMe] = useState(false)
    const navigate = useNavigate()
    // useEffect(() => {
    //     if (password.length > 3) {
    //         setIsPasswordValid(false);
    //     }
    // }, [password]);

    const noAccount = useCallback(() => {
        dispatch(IsRegistration(true));
        dispatch(IsLogin(false));
    }, [dispatch]);
    const passShow = useCallback(() => {
        setIsShow((prev) => !prev);
    }, []);
    const login = useCallback(async () => {
        dispatch(IsLoading(true));
        try {
            const response = await AuthService.login(phone, password, rememberMe);
            const session = response.data.session;
            const { userData } = response.data;
            const id = response.data.userData.user[0].id;
            if(rememberMe){
                localStorage.setItem('session', session);
            }
            localStorage.setItem('user', id);
            dispatch(addUser(userData.user[0]));
            navigate('/profile');
        } catch (error) {
            setIsNotification(true);
            setError(error.response?.data?.message);
            console.log(error.response?.data?.message)
        } finally {
            dispatch(IsLoading(false));
        }
    }, [phone, password, rememberMe, dispatch, navigate]);

    useEffect(() => {
        document.querySelector('.react-international-phone-country-selector-button').setAttribute('disabled', 'disabled');
    }, []);
    useEffect(() => {
        const borderColor = (selector, color) => {
            document.querySelector(selector).style.borderColor = color;
        };
        switch (error) {
            case'User with phone not found':
                borderColor('.react-international-phone-input', '#eb0606');
                borderColor('.react-international-phone-country-selector button', '#eb0606');
                setIsNumberValid(true);
                setIsPasswordValid(false);
                break
            case'Incorrect password':
                borderColor('.react-international-phone-input', '#DCDCDC');
                borderColor('.react-international-phone-country-selector button', '#DCDCDC');
                setIsNumberValid(false);
                setIsPasswordValid(true);
                break
            case'User with phone not found,Incorrect password':
                borderColor('.react-international-phone-input', '#eb0606');
                borderColor('.react-international-phone-country-selector button', '#eb0606');
                setIsNumberValid(true);
                setIsPasswordValid(true);
                break
            default:
                setIsNumberValid(false);
                setIsPasswordValid(false);
                break
        };
    }, [error]);
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                login();
            }
        };

        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [login]);

    return (
        <div className="get-started">
            <div className="get-started_title text-center">{t("Log in to")} <span style={{ color: '#0666EB' }}>Tips</span>Si</div>
            <div className="get-started_form d-flex flex-column col-lg-3 col-md-6 col-sm-8 col-12">
                <div className={darkMode ? "dark-input" : ""}>
                    <PhoneInput
                        defaultCountry="es"
                        value={phone}
                        onChange={setPhone}
                        required={true}
                        hideDropdown
                        forceDialCode
                        disableFocusAfterCountrySelect={false}
                        disableCountryGuess
                    />
                    {/*{isNumberValid && <FormHelperText error id="outlined-weight-helper-text">{t("User with phone not found")}</FormHelperText>}*/}
                </div>
                <div className={darkMode ? "get-started_form-password dark-input" : "get-started_form-password"}>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel error={isPasswordValid && "error"} htmlFor="outlined-adornment-password">{t("Password")}</InputLabel>
                        <OutlinedInput
                            error={isPasswordValid && "error"}
                            id="outlined-adornment-password"
                            type={isShow ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={passShow}
                                        edge="end"
                                    >
                                        {isShow ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t("Password")}
                        />
                        {/*{isPasswordValid && <FormHelperText error={isPasswordValid && "error"} id="outlined-weight-helper-text">{t("Incorrect password")}</FormHelperText>}*/}
                        {error && <FormHelperText sx={{fontWeight: 700}} error className="error-text" id="outlined-weight-helper-text">{t(error)}</FormHelperText>}
                    </FormControl>
                </div>
                <div className="get-started_form_forgot-password_block">
                    <FormGroup >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked
                                    checked={rememberMe}
                                    onChange={() => setRememberMe((prev) => !prev)}
                                />
                            }
                            label={t("Remember me")}
                            inputProps={{ 'aria-label': 'controlled' }}
                            labelPlacement="end"
                            className={darkMode ? "dark-input" : ""}
                        />
                    </FormGroup>
                    <Button variant="text" className="forgot-password"><Link to="/forgot">{t("Forgot your password?")}</Link></Button>
                </div>
                <Button variant="contained" className="send-button" onClick={login}>{t("Log in")}</Button>
                <div className="dont-have-account">{t("Don't have an account?")}&nbsp;&nbsp;<Link to="/sign-up" variant="text" style={{fontWeight: 700, color:"#0666EB", textTransform: "uppercase"}}>{t("SIGN UP")}</Link></div>
            </div>
        </div>
    );
}

export default SignInComponent;

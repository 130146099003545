import React from 'react';
import {useSelector} from "react-redux";

export default function LikeEuro({active, scale, width}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const fillColor1 = darkMode ? '#fff' : '#1F3150';
    const fillColor2 = active ? "#0666EB" : '#A3ABB7';
    const fillColor3 = '#FFFFFF'; // Статический цвет для белого

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} style={{transform: `scale(${scale})`, transition: ".2s"}}>
            <g>
                <path style={{ fill: fillColor2 }}
                      d="M313.4,32.9c26,5.2,42.9,30.5,37.7,56.5l-2.3,11.4c-5.3,26.7-15.1,52.1-28.8,75.2h144c26.5,0,48,21.5,48,48
                         c0,18.5-10.5,34.6-25.9,42.6C497,275.4,504,288.9,504,304c0,23.4-16.8,42.9-38.9,47.1c4.4,7.3,6.9,15.8,6.9,24.9
                         c0,21.3-13.9,39.4-33.1,45.6c0.7,3.3,1.1,6.8,1.1,10.4c0,26.5-21.5,48-48,48h-97.5c-19,0-37.5-5.6-53.3-16.1l-38.5-25.7
                         C176,420.4,160,390.4,160,358.3V320v-48v-24.9c0-29.2,13.3-56.7,36-75l7.4-5.9c26.5-21.2,44.6-51,51.2-84.2l2.3-11.4
                         C262.1,44.6,287.4,27.7,313.4,32.9L313.4,32.9z"/>
                <path style={{ fill: fillColor1 }}
                      d="M32,192h64c17.7,0,32,14.3,32,32v224c0,17.7-14.3,32-32,32H32c-17.7,0-32-14.3-32-32V224
                         C0,206.3,14.3,192,32,192z"/>
                <g>
                    <g>
                        <path style={{ fill: fillColor3 }}
                              d="M379.3,228.3l-7.3,34.1c-7-7-17.2-10.5-30.5-10.5c-13.4,0-24.2,4.8-32.5,14.4c-4.7,5.4-8,12.2-10,20.3h67.7
                                 l-3.9,19.1h-66.2c-0.1,1.9-0.1,4.3-0.1,7.3c0,2.9,0.1,5.9,0.2,9h62.6l-3.9,19.1h-56.1c2,8.8,5.2,15.7,9.5,20.7
                                 c8.2,9.7,18.9,14.5,32,14.5c15.7,0,28.3-4.8,37.6-14.5v37.7c-10.7,5.3-23.1,8-37.3,8c-23.9,0-43.6-8.2-59.1-24.6
                                 c-10.5-11.1-17.4-25.1-20.8-41.7h-18.2l3.9-19.1h12.1c-0.1-2-0.1-4.1-0.1-6.3c0-3.8,0.1-7.1,0.2-10h-16.1l3.9-19.1h14.6
                                 c3.4-16.3,10.3-30,20.5-41c15.5-16.6,35.9-24.9,61.2-24.9C357.2,220.8,369.2,223.3,379.3,228.3z"/>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import config from "../../config";
import logo from "../../assets/images/tipssi-print-logo.jpg";
import QRCode from 'qrcode';
import {Svg, Path} from '@react-pdf/primitives';
import fontPath from '../../assets/fonts/Roboto-Bold.ttf'

Font.register({
    family: 'Roboto',
    fontWeight: 700,
    fonts: [{src: fontPath}]
});

const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        flexDirection: 'row',
        alignItems: 'center',
        position: "relative",
        backgroundColor: "#0666EB",
    },
    bigTitle: {
        fontSize: 16,
        letterSpacing: 1,
        fontWeight: 700,
        marginBottom: 10,
        color: '#fff',
        padding: "10px 10px 0 10px",
    },
    smallTitle: {
        fontSize: 9,
        maxWidth: "65%",
        fontWeight: 700,
        textAlign: "center",
        textTransform: "uppercase",
        padding: "5px 25px 5px 25px",
        letterSpacing: 0.3,
        color: "#000",
    },
    section: {
        width: '100%',
    },
    qrCodeBox:{
        position: "absolute",
        top: 10,
        right: 10,
        width: "35%",
        borderRadius: "8px",
        padding: "5px 11px",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: "3px solid #0666EB",
        boxShadow: "none",
    },
    qrCodeText: {
        fontSize: 11,
        textAlign: "center"
    },
    qrCode: {
        width: 50,
        height: 50,
    },
    whiteBG:{
        width: '100%',
        backgroundColor: "#fff"
    },
    paymentMethods:{
        justifyContent: "space-between",
        flexDirection: "row",
        width: "60%",
        gap: "6px",
        padding: "15px 10px",
    },
    paymentMethod:{
        backgroundColor: "#fff",
        borderRadius: "6px",
        padding: "10px 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    footer:{
        flexDirection: "column",
        padding: "10px 10px 8px 10px",
        width: "100%",
    },
    footerText:{
        fontSize: 10,
        letterSpacing: 0.2,
        marginRight: 10
    },
    footerSmallText:{
        fontSize: 9,
        color: "#0666EB",
        textAlign: "right",
        marginRight: "10.5px",
        marginTop: "-5px"
    }
});

function BusinessCard({ id, phone }) {
    const url = `${config.FRONTEND_URL}/payment/${id}`;
    const [qrCodeData, setQrCodeData] = useState('');

    const formatPhone = phone => {
        if (!phone) return ''; // Проверка на undefined
        const cleanedPhone = phone.startsWith('+34') ? phone.slice(3) : phone;
        return cleanedPhone.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3-$4');
    };

    useEffect(() => {
        QRCode.toDataURL(url, {
            errorCorrectionLevel: 'M',
            margin: 1,
            scale: 10
        }).then(data => {
            setQrCodeData(data);
        });
    }, [url]);

    return (
        <Document>
            <Page style={styles.page} size={[266]} wrap={false}>
                <View style={styles.section}>
                    <View style={styles.bigTitle}><Text>TIPEA MUY FÁCIL</Text></View>
                    <View style={[styles.whiteBG, {marginTop: "-15px"}]}><Text style={styles.smallTitle}>PUEDE DEJARME PROPINAS YOU CAN LEAVE ME A TIPS APPLE PAY / GOOGLE PAY</Text></View>
                    <View style={styles.paymentMethods}>
                        <View style={styles.paymentMethod}>
                            <Svg width={35} viewBox="0 0 85 36.9">
                                <Path fill="#020203" d="M16.8,4.9c1-1.3,1.8-3.1,1.6-4.9c-1.5,0.1-3.4,1-4.5,2.3c-1,1.1-1.8,3-1.6,4.7C14,7.1,15.7,6.1,16.8,4.9"/>
                                <Path fill="#020203" d="M18.4,7.3c-2.5-0.1-4.6,1.4-5.8,1.4c-1.2,0-3-1.3-5-1.3c-2.6,0-5,1.5-6.3,3.8c-2.7,4.6-0.7,11.5,1.9,15.3 C4.5,28.4,6,30.4,8,30.4c1.9-0.1,2.6-1.2,5-1.2c2.3,0,3,1.2,5,1.2c2.1,0,3.4-1.9,4.7-3.7c1.5-2.1,2.1-4.2,2.1-4.3 c0,0-4-1.6-4.1-6.2c0-3.8,3.1-5.7,3.3-5.8C22.1,7.7,19.3,7.4,18.4,7.3"/>
                                <Path fill="#020203" d="M41.6,5.3c4.8,0,8.2,3.3,8.2,8.2c0,4.9-3.4,8.2-8.3,8.2h-5.3v8.5h-3.9V5.3L41.6,5.3L41.6,5.3z M36.1,18.4h4.4 c3.4,0,5.3-1.8,5.3-5c0-3.1-1.9-4.9-5.3-4.9h-4.5V18.4z"/>
                                <Path fill="#020203" d="M50.8,25c0-3.2,2.4-5.1,6.7-5.4l5-0.3v-1.4c0-2-1.4-3.2-3.6-3.2c-2.2,0-3.5,1-3.8,2.7h-3.5 c0.2-3.3,3-5.7,7.5-5.7c4.4,0,7.2,2.3,7.2,6v12.5h-3.6v-3h-0.1c-1.1,2-3.3,3.3-5.7,3.3C53.3,30.5,50.8,28.3,50.8,25z M62.5,23.4 v-1.4l-4.5,0.3c-2.2,0.2-3.5,1.1-3.5,2.7c0,1.6,1.3,2.6,3.3,2.6C60.5,27.5,62.5,25.7,62.5,23.4z"/>
                                <Path fill="#020203" d="M69.6,36.8v-3c0.3,0.1,0.9,0.1,1.2,0.1c1.7,0,2.7-0.7,3.2-2.6c0,0,0.3-1.1,0.3-1.1L67.8,12h4l4.6,14.8h0.1 L81.1,12H85l-6.8,19.1c-1.6,4.4-3.3,5.8-7.1,5.8C70.8,36.9,69.9,36.9,69.6,36.8z"/>
                            </Svg>
                        </View>
                        <View style={styles.paymentMethod}>
                            <Svg width={35} viewBox="0 0 85 33.8">
                                <Path fill="#60646A" d="M40.2,16.5v9.9h-3.1V2h8.3c2,0,3.9,0.7,5.4,2.1c1.5,1.3,2.3,3.2,2.2,5.1c0,2-0.8,3.9-2.2,5.2 c-1.5,1.4-3.3,2.1-5.4,2.1H40.2z M40.2,5v8.5h5.3c1.2,0,2.3-0.4,3.1-1.3c1.7-1.6,1.7-4.2,0.1-5.9l-0.1-0.1 c-0.8-0.9-1.9-1.3-3.1-1.3L40.2,5z M60.3,9.1c2.3,0,4.2,0.6,5.5,1.9c1.3,1.2,2,2.9,2,5.1v10.3h-3v-2.3h-0.1c-1.3,1.9-3,2.9-5.2,2.9 c-1.8,0-3.4-0.5-4.6-1.6c-1.2-1-1.9-2.5-1.9-4.1c0-1.7,0.7-3.1,2-4.1c1.3-1,3.1-1.5,5.2-1.5c1.9,0,3.4,0.4,4.6,1v-0.7 c0-1.1-0.5-2.1-1.3-2.8c-0.8-0.7-1.9-1.2-3-1.2c-1.8,0-3.1,0.7-4.2,2.2l-2.8-1.7C55.1,10.2,57.3,9.1,60.3,9.1L60.3,9.1z M56.2,21.3 c0,0.8,0.4,1.6,1,2c0.7,0.5,1.6,0.8,2.4,0.8c1.3,0,2.6-0.5,3.5-1.5c1-1,1.6-2.1,1.6-3.5c-1-0.8-2.3-1.2-4.1-1.2 c-1.3,0-2.3,0.3-3.2,0.9C56.7,19.6,56.2,20.4,56.2,21.3z M85,9.7L74.5,33.8h-3.2l3.9-8.4L68.3,9.7h3.4l5,12h0.1l4.9-12L85,9.7z"/>
                                <Path fill="#517CBE" d="M27.5,14.4c0-1-0.1-1.9-0.2-2.8H14v5.4h7.6c-0.3,1.7-1.3,3.3-2.8,4.3v3.5h4.5C26,22.3,27.5,18.6,27.5,14.4 L27.5,14.4z"/>
                                <Path fill="#32A853" d="M14,28.1c3.8,0,7-1.2,9.3-3.4l-4.5-3.5c-1.3,0.9-2.9,1.3-4.8,1.3c-3.7,0-6.8-2.5-7.9-5.8H1.5v3.6 C3.9,25.1,8.7,28.1,14,28.1z"/>
                                <Path fill="#FBBC0C" d="M6.2,16.7c-0.6-1.7-0.6-3.6,0-5.4V7.7H1.5c-2,4-2,8.6,0,12.6L6.2,16.7z"/>
                                <Path fill="#E84436" d="M14,5.6c2,0,3.9,0.7,5.4,2.1l4-4C20.9,1.3,17.5,0,14,0C8.7,0,3.9,3,1.5,7.7l4.7,3.6C7.3,8,10.4,5.6,14,5.6z"/>
                            </Svg>
                        </View>
                        <View style={styles.paymentMethod}>
                            <Svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 62.6 34.9" style={{width: 35}}>
                                <Path d="M39,0H23.5C21.6,0,20,1.5,20,3.5V14c0,1.9,1.5,3.5,3.5,3.5H39c1.9,0,3.5-1.5,3.5-3.5V3.5C42.5,1.5,40.9,0,39,0 z M23.5,1.6H39c1,0,1.9,0.8,1.9,1.9v1H21.6v-1C21.6,2.4,22.4,1.6,23.5,1.6z M39,15.8H23.5c-1,0-1.9-0.8-1.9-1.9V8.5h19.3V14 C40.9,15,40,15.8,39,15.8z" fill="#000"/>
                                <Path fill="st0" d="M1.3,27.3v-3.7H0v-0.8h3.6v0.8H2.2v3.7H1.3z"/>
                                <Path fill="st0" d="M7.7,27.3h-1l-0.4-1H4.5l-0.4,1h-1L5,22.8h1L7.7,27.3z M6,25.5l-0.6-1.7l-0.6,1.7H6z"/>
                                <Path fill="st0" d="M8.2,27.3v-4.5h1.9c0.5,0,0.8,0,1,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.7 c0,0.3-0.1,0.6-0.3,0.8s-0.5,0.4-0.9,0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.3,0.4,0.5,0.7l0.5,0.9h-1.1l-0.7-1 c-0.2-0.3-0.4-0.6-0.5-0.7s-0.2-0.2-0.3-0.2c-0.1,0-0.2,0-0.5,0H9.1v1.9H8.2z M9.1,24.7h0.7c0.4,0,0.7,0,0.8-0.1 c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.7,0H9.1V24.7z"/>
                                <Path fill="st0" d="M14.3,22.8h0.9v2.8c0,0.4,0,0.7-0.1,0.9c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.2-0.5,0.2-0.9,0.2 c-0.4,0-0.8-0.1-1-0.4c-0.2-0.2-0.4-0.6-0.4-1.1l0.9-0.1c0,0.3,0,0.4,0.1,0.5c0.1,0.2,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2 c0.1-0.1,0.1-0.4,0.1-0.7V22.8z"/>
                                <Path fill="st0" d="M16.2,27.3v-4.5h3.3v0.8h-2.4v1h2.2v0.8h-2.2v1.2h2.5v0.8H16.2z"/>
                                <Path fill="st0" d="M21.4,27.3v-3.7H20v-0.8h3.6v0.8h-1.3v3.7H21.4z"/>
                                <Path fill="st0" d="M27.7,27.3h-1l-0.4-1h-1.8l-0.4,1h-1l1.7-4.5h1L27.7,27.3z M26.1,25.5l-0.6-1.7l-0.6,1.7H26.1z"/>
                                <Path fill="st0" d="M29.7,22.8h1.8c0.4,0,0.6,0,0.8,0s0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0.1,0.3,0.1,0.5 c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4c0.3,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2,0,0.4-0.1,0.6 s-0.2,0.3-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5,0-1.1,0h-1.5V22.8z M30.6,23.6v1h0.6c0.4,0,0.6,0,0.7,0 c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3,0-0.8,0H30.6z M30.6,25.4v1.2h0.8c0.3,0,0.5,0,0.6,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3 c-0.1-0.1-0.2-0.2-0.3-0.2s-0.4-0.1-0.8-0.1H30.6z"/>
                                <Path fill="st0" d="M38.3,27.3h-1l-0.4-1h-1.8l-0.4,1h-1l1.7-4.5h1L38.3,27.3z M36.6,25.5L36,23.9l-0.6,1.7H36.6z"/>
                                <Path fill="st0" d="M38.8,27.3v-4.5h0.9l1.8,3v-3h0.8v4.5h-0.9l-1.8-2.9v2.9H38.8z"/>
                                <Path fill="st0" d="M46.1,25.7l0.9,0.3c-0.1,0.5-0.4,0.9-0.7,1.1s-0.7,0.4-1.2,0.4c-0.6,0-1.1-0.2-1.5-0.6 c-0.4-0.4-0.6-1-0.6-1.7c0-0.7,0.2-1.3,0.6-1.7s0.9-0.6,1.5-0.6c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.4,0.5,0.5,0.8l-0.9,0.2 c-0.1-0.2-0.2-0.4-0.3-0.5s-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.6-0.3,1.2c0,0.6,0.1,1,0.3,1.2 c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2C45.9,26.2,46.1,26,46.1,25.7z"/>
                                <Path fill="st0" d="M51.8,27.3h-1l-0.4-1h-1.8l-0.4,1h-1l1.7-4.5h1L51.8,27.3z M50.2,25.5l-0.6-1.7l-0.6,1.7H50.2z"/>
                                <Path fill="st0" d="M52.3,27.3v-4.5h1.9c0.5,0,0.8,0,1,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.7 c0,0.3-0.1,0.6-0.3,0.8s-0.5,0.4-0.9,0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.3,0.4,0.5,0.7l0.5,0.9h-1.1l-0.7-1 c-0.2-0.3-0.4-0.6-0.5-0.7s-0.2-0.2-0.3-0.2c-0.1,0-0.2,0-0.5,0h-0.2v1.9H52.3z M53.2,24.7h0.7c0.4,0,0.7,0,0.8-0.1 c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.7,0h-0.7V24.7z"/>
                                <Path fill="st0" d="M56.8,27.3v-4.5h0.9v4.5H56.8z"/>
                                <Path fill="st0" d="M62.6,27.3h-1l-0.4-1h-1.8l-0.4,1h-1l1.7-4.5h1L62.6,27.3z M60.9,25.5l-0.6-1.7l-0.6,1.7H60.9z"/>
                                <Path fill="st0" d="M12.7,30.3h1.8c0.4,0,0.6,0,0.8,0s0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0.1,0.3,0.1,0.5 c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4c0.3,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2,0,0.4-0.1,0.6 s-0.2,0.3-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5,0-1.1,0h-1.5V30.3z M13.6,31.1v1h0.6c0.4,0,0.6,0,0.7,0 c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3,0-0.8,0H13.6z M13.6,32.9v1.2h0.8c0.3,0,0.5,0,0.6,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3 c-0.1-0.1-0.2-0.2-0.3-0.2s-0.4-0.1-0.8-0.1H13.6z"/>
                                <Path fill="st0" d="M21.2,34.8h-1l-0.4-1H18l-0.4,1h-1l1.7-4.5h1L21.2,34.8z M19.5,33l-0.6-1.7L18.3,33H19.5z"/>
                                <Path fill="st0" d="M21.7,34.8v-4.5h0.9l1.8,3v-3h0.8v4.5h-0.9l-1.8-2.9v2.9H21.7z"/>
                                <Path fill="st0" d="M26.2,34.8v-4.5h0.9v2l1.8-2h1.2l-1.7,1.7l1.8,2.7h-1.2l-1.2-2.1l-0.7,0.7v1.4H26.2z"/>
                                <Path fill="st0" d="M35.3,33.2l0.9,0.3c-0.1,0.5-0.4,0.9-0.7,1.1s-0.7,0.4-1.2,0.4c-0.6,0-1.1-0.2-1.5-0.6 c-0.4-0.4-0.6-1-0.6-1.7c0-0.7,0.2-1.3,0.6-1.7s0.9-0.6,1.5-0.6c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.4,0.5,0.5,0.8l-0.9,0.2 c-0.1-0.2-0.2-0.4-0.3-0.5S34.6,31,34.3,31c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.6-0.3,1.2c0,0.6,0.1,1,0.3,1.2 c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2C35.1,33.7,35.2,33.5,35.3,33.2z"/>
                                <Path fill="st0" d="M41,34.8h-1l-0.4-1h-1.8l-0.4,1h-1l1.7-4.5h1L41,34.8z M39.3,33l-0.6-1.7L38.1,33H39.3z"/>
                                <Path fill="st0" d="M41.5,34.8v-4.5h1.9c0.5,0,0.8,0,1,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.7 c0,0.3-0.1,0.6-0.3,0.8s-0.5,0.4-0.9,0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.3,0.4,0.5,0.7l0.5,0.9h-1.1l-0.7-1 c-0.2-0.3-0.4-0.6-0.5-0.7S43.1,33,43,33c-0.1,0-0.2,0-0.5,0h-0.2v1.9H41.5z M42.4,32.2h0.7c0.4,0,0.7,0,0.8-0.1 c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.7,0h-0.7V32.2z"/>
                                <Path fill="st0" d="M46,30.3h1.7c0.4,0,0.7,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.1,1.1 c0,0.4,0,0.7-0.1,1c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.8,0.1H46V30.3z M46.9,31.1v3h0.7 c0.3,0,0.4,0,0.5,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.2,0.1-0.5,0.1-0.8c0-0.3,0-0.6-0.1-0.8 c-0.1-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.4,0-0.7,0H46.9z"/>                            </Svg>
                        </View>
                    </View>
                    <View style={[styles.whiteBG, styles.footer]}>
                        <View style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row"}}>
                            <View style={{width: 75}}><Image src={logo} style={{width:"100%", objectFit: "cover"}}/></View>
                            <View style={[styles.whiteBG, {textAlign: "right"}]}>
                                <Text style={styles.footerText}>SISTEMA DE PROPINAS DIGITALES</Text>
                            </View>
                        </View>
                        <View><Text style={styles.footerSmallText}>www.tipssi.net</Text></View>
                    </View>
                </View>
                <View style={styles.qrCodeBox}>
                    <Text style={styles.qrCodeText}>Escanea el código QR</Text>
                    {qrCodeData && <Image src={qrCodeData} style={styles.qrCode} />}
                    <Text style={{fontSize: 8, textAlign: "center"}}>o entra a la web</Text>
                    <Text style={{fontSize: 8, color: "#0666EB"}}>tipssi.net/qr</Text>
                    <Text style={{fontSize: 8, marginBottom: 5}}>y teclea numero</Text>
                    <Text style={[styles.qrCodeText, {textAlign: "left"}]}>{formatPhone(phone)}</Text>
                </View>
            </Page>
        </Document>
    );
}

export default BusinessCard;
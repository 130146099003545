import React from 'react';
import style from './ThankYouComponent.module.scss';
import logo from "../../assets/images/tipssi-print-logo.jpg"
import Button from "@mui/material/Button";
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { AiFillTikTok } from "react-icons/ai";
import { LuCheckCircle2 } from "react-icons/lu";

function ThankYouComponent({url, thankyou, whatsapp, facebook, instagram, linkedin, tiktok, youtube}) {
    return (
        <div className={style.thankyou_component + " text-center col-sm-9 col-md-5 col-lg-4 col-12"}>
            <div className={style.thank_you_icon}><LuCheckCircle2 fontSize={80} color="#0666EB"/></div>
            <div className={style.thank_you_subtitle}>
                Gracias <br/> por dejar propinas!
            </div>
            <div className={style.thank_you_title}>{thankyou}</div>
            <div className={style.thank_you_title}>Sigueme en las redes sociales</div>
            <div className={style.thank_you_social}>
                {facebook !== "" && <a href={facebook} className={style.thank_you_social_icon}><FacebookIcon
                    className={style.facebook}/></a>}
                {instagram !== "" && <a href={instagram} className={style.thank_you_social_icon}><InstagramIcon
                    className={style.instagram}/></a>}
                {whatsapp !== "" && <a href={whatsapp} className={style.thank_you_social_icon}><WhatsAppIcon
                    className={style.whatsapp}/></a>}
                {linkedin !== "" && <a href={linkedin} className={style.thank_you_social_icon}><LinkedInIcon
                    className={style.linkedin}/></a>}
                {tiktok !== "" && <a href={tiktok} className={style.thank_you_social_icon}><AiFillTikTok
                    className={style.tiktok}/></a>}
                {youtube !== "" && <a href={youtube} className={style.thank_you_social_icon}><YouTubeIcon
                    className={style.youtube}/></a>}
            </div>
            <Button href={url} variant="contained" fullWidth
                    sx={{marginBottom: "32px", fontSize: "19px", textTransform: "capitalize"}} className="btn">Repitir
                el pago</Button>
            <div className={style.thank_you_image}><img src={logo} alt=""/></div>
        </div>
    );
}

export default ThankYouComponent;
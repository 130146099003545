import React from 'react';
import TipssiQRComponent from "../components/tipssi-qr-components/TipssiQRComponent";

function TipssiQR(props) {
    return (
        <section className="tipssi-qr">
            <div className="container">
                <div className="d-flex justify-content-center align-items-center">
                    <TipssiQRComponent/>
                </div>
            </div>
        </section>
    )
}

export default TipssiQR;
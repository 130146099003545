import React from 'react';
import {useSelector} from "react-redux";

export default function GoodService({active, scale, width}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const fillColor1 = darkMode ? '#fff' : '#1F3150';
    const fillColor2 = active ? "#0666EB" : '#A3ABB7';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} style={{transform: `scale(${scale})`, transition: ".2s"}}>
            <g>
                <g>
                    <path style={{ fill: fillColor1 }} d="M479.6,219.1H451c-11.7-53-55.7-132.4-158.5-147.8c1.8-4.5,2.9-9.4,2.9-14.5c0-21.7-17.7-39.4-39.4-39.4
                             c-21.7,0-39.4,17.7-39.4,39.4c0,5.3,1.1,10.2,2.9,14.8c-96.4,16-137.3,94.6-148,147.5H33.3c-17.9,0-32.4,14.5-32.4,32.4
                             s14.5,32.4,32.4,32.4h446.3c17.9,0,32.4-14.5,32.4-32.4S497.5,219.1,479.6,219.1z"/>
                </g>
                <path style={{ fill: fillColor2 }}
                      d="M504.7,341.9l-3-4.1c-10-13.7-29.3-16.6-42.9-6.6l-109.4,80.4H241.2c-8.4,0-15.1-6.8-15.1-15.1
                         c0-8.4,6.8-15.1,15.1-15.1H314c15.5,0,28.1-12.6,28.1-28.1s-12.6-28.1-28.1-28.1H167.6c-12.5,0-37.1,3.3-54.2,16.2
                         c-15.1,11.4-51.6,39.9-51.6,39.9H28.3C12.7,381.4,0,394.1,0,409.7v56.6c0,15.6,12.7,28.3,28.3,28.3h291.3
                         c11.9,0,32.3-2.4,45.1-11.7l133.4-98.1C511.9,374.8,514.8,355.6,504.7,341.9z"/>
            </g>
        </svg>
    );
}

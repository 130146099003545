import { configureStore } from '@reduxjs/toolkit'
import getStartedSlice from "./slices/getStartedSlice";
import {userApiAuth} from "./rtkQuery/userApiAuth";
import userSlice from "./slices/userSlice";
import paymentSlice from "./slices/paymentSlice";
import accountSettings from "./slices/accountSettingsSlice";
import contactSlice from "./slices/contactSlice";
import refSlice from "./slices/pngDownloadRefSlice";

export const store = configureStore({
    reducer: {
        getStarted: getStartedSlice,
        user: userSlice,
        currency: paymentSlice,
        settings: accountSettings,
        contact: contactSlice,
        ref: refSlice,
        [userApiAuth.reducerPath]: userApiAuth.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApiAuth.middleware),
})
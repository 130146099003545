import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    darkMode: false,
    fixedMenuTabValue: "1"
}

export const accountSettings = createSlice({
    name: 'accountSetting',
    initialState,
    reducers: {
        DarkMode: (state, action) => {
            state.darkMode = action.payload
        },
        FixedMenuTabValue: (state, action) => {
            state.fixedMenuTabValue = action.payload
        },
    }
})

export const {
    DarkMode,
    FixedMenuTabValue,
} = accountSettings.actions;

export default accountSettings.reducer
import React, {useEffect} from 'react';
import PrivacyPolicyComponent from "../components/policy/PrivacyPolicyComponent";
import {useLocation} from "react-router-dom";

function PrivacyPolicy(props) {
    const location = useLocation();

    useEffect(() => {
        document.title = "Privacy Policy - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);

    return (
        <section className="policy pt-4 pb-4">
            <div className="container">
                <PrivacyPolicyComponent/>
            </div>
        </section>
    );
}

export default PrivacyPolicy;
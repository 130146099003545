import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    amount: 1,
    withdrawalAmount: 1,
    bankCard: "",
    email: "",
    rating: 0,
    review: 0,
    bizumPhone: "",
    recipientPhoneNumber: "",
    paymentComment: "",
    payStatusModalOpen: false,
    checkModalOpen: false
}
export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        Amount: (state, action) => {
            state.amount = action.payload;
        },
        WithdrawalAmount: (state, action) => {
            state.withdrawalAmount = action.payload;
        },
        BankCard: (state, action) => {
            state.bankCard = action.payload;
        },
        PaymentEmail: (state, action) => {
            state.email = action.payload;
        },
        UserRating: (state, action) => {
            state.rating = action.payload;
        },
        UserReview: (state, action) => {
            state.review = action.payoad;
        },
        BizumPhone: (state, action) => {
            state.bizumPhone = action.payload;
        },
        RecipientPhoneNumber: (state, action) => {
            state.recipientPhoneNumber = action.payload;
        },
        PaymentComment: (state, action) => {
            state.paymentComment = action.payload;
        },
        PaymentStatusModalOpen: (state, action) => {
            state.payStatusModalOpen = action.payload;
        },
        CheckModalOpen:(state, action) => {
            state.checkModalOpen = action.payload;
        }
    }
})

export const {
    Amount,
    PaymentEmail,
    BankCard,
    UserRating,
    WithdrawalAmount,
    BizumPhone,
    RecipientPhoneNumber,
    PaymentComment,
    PaymentStatusModalOpen,
    CheckModalOpen,
    UserReview
} = paymentSlice.actions;

export default paymentSlice.reducer
import React from 'react';
import style from './HowMuchDoesItCost.module.scss';
import image from '../../assets/images/how-much-it-costs-1.png'
import {useSelector} from "react-redux";
import SectionBigTitle from "../SectionBigTitle";
import {useTranslation} from "react-i18next";
function HowMuchDoesItCost({bgc}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <section className={darkMode ? style.how_much_does_it_cost + " dark-section-bg" : style.how_much_does_it_cost} style={{backgroundColor: bgc}}>
            <div className="container">
                <div className="row d-flex justify-content-between">
                    <div className={style.text_block + " col-sm-6 col-md-5 col-12"} data-aos="zoom-in" data-aos-duration="1500">
                        <div className="mb-4 section_title"></div>
                        <SectionBigTitle title="How much does it cost?" marginBottom="24"/>
                        <div className={darkMode ? " section_description light-text" : "section_description"}>
                            <div>{t("Tipssi.net service commission")}</div>
                            <div>{t("Always 2.5% for employees and for the institution!")}</div>
                            <div>{t("No additional fees or subscription fees.")}</div>
                        </div>
                    </div>
                    <div className={style.how_much_does_it_cost_img + " d-flex flex-column justify-content-center col-sm-6 col-md-6 col-12"} data-aos="fade-left" data-aos-duration="1500"><img src={image} alt="How much it costs icon"/></div>
                </div>
            </div>
        </section>
    );
}

export default HowMuchDoesItCost;
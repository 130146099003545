import React from 'react';
import style from './RegistrationQRPayment.module.scss';
import arrowImg from '../../assets/images/arrow.png';
import block1BG from '../../assets/images/qr.png';
import block1Phone from '../../assets/images/how-it-works-registration.png';
import block2Phone from '../../assets/images/how-it-works-qr-code.png';
import block2QR from '../../assets/images/how-to-start-tablet.png';
import block3Payment from '../../assets/images/how-it-works-payment.png';
import block3PaymentImage from '../../assets/images/how-to-start-payments.png';
import block4BigImage from '../../assets/images/how-to-start-withdraw-money.png';
import block4Icon1 from '../../assets/images/withdraw-immediately-icon.png';
import block4Icon2 from '../../assets/images/system-automated-icon.png';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function RegistrationQrPayment({bgc}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <section className={darkMode ? style.registration_qr_payment + " dark-section-bg light-text" : style.registration_qr_payment} style={{backgroundColor: bgc}}>
            <div className="container">
                <div className={darkMode ? style.registration_qr_payment_item + " col col-12 bg-white dark-block-bg" : style.registration_qr_payment_item + " col col-12 bg-white"}>
                    <img src={block1BG} alt="" className={style.bg}/>
                    <div className={style.registration_qr_payment_item_title + " section_small_title"} data-aos="fade-right" data-aos-duration="1500">1. {t("Registration")}</div>
                    <div className={style.registration_qr_payment_item_content}>
                        <div className={style.registration_qr_payment_item_content_img + " col-sm-4 col-12"} data-aos="zoom-in" data-aos-duration="1500"><img src={block1Phone} alt="Registration"/></div>
                        <div className={darkMode ? style.registration_qr_payment_item_content_text + " col-sm-10 col-md-8 col-lg-6 col-12 light-text" : style.registration_qr_payment_item_content_text} data-aos="fade-down" data-aos-duration="1500">
                            <div>{t("You receive a personal QR code when you register.")}</div>
                            <div>{t("If you register as an administrator, you can connect several people to the system at once.")}</div>
                            <div className={style.arrow}><img src={arrowImg} alt="Arrow"/></div>
                        </div>
                    </div>
                </div>
                <div className={"text-white p-0 " + style.qr_code_block}>
                    <div className={style.qr_code_block_left}>
                        <div className={"section_small_title " + style.registration_qr_payment_item_title} data-aos="fade-right" data-aos-duration="1500">2. {t("QR-code")}</div>
                        <div className={style.registration_qr_payment_item_content}>
                            <div className={"col-sm-4 col-12 " + style.registration_qr_payment_item_content_img} data-aos="zoom-in" data-aos-duration="1500"><img src={block2Phone} alt="Qr Code"/></div>
                            <div className={style.registration_qr_payment_item_content_text} data-aos="fade-down" data-aos-duration="1500">
                                <div className="col-md-12 col-lg-8 col-12">{t("Show the client/guest the QR-code in printed form or on the phone screen. You download the print layouts or order delivery in your personal account.")}</div>
                                <div className={style.arrow}><img src={arrowImg} alt="Arrow"/></div>
                            </div>
                        </div>
                    </div>
                    <div className={darkMode ? style.qr_code_block_right + " col-sm-5 col-12 dark-block-bg light-text" : style.qr_code_block_right + " col-sm-5 col-12"}>
                        <div className="text-center small_title" data-aos="fade-left" data-aos-duration="1500">{t("Printed materials")}</div>
                        <div className={style.qr_code_block_right_subtitle} data-aos="fade-right" data-aos-duration="1500">
                            {t("In your personal account you can download or order the delivery of ready-made personalized layouts (Business cards, stickers, tabloid labels).")}
                            <div>{t("Just download or order delivery.")}</div>
                        </div>
                        <div className={style.qr_code_block_right_img} data-aos="zoom-in" data-aos-duration="1500"><img src={block2QR} alt="Tablet"/></div>
                    </div>
                </div>
                <div className={"col col-12 text-white d-flex justify-content-between mt-5 " + style.payment_block}>
                    <div className={darkMode ? style.payment_block_left + " col-sm-6 col-12 dark-block-bg" : style.payment_block_left + " col-sm-6 col-12"}>
                        <div className={darkMode ? style.registration_qr_payment_item_title + " section_small_title light-text" :  style.registration_qr_payment_item_title + " section_small_title"} data-aos="fade-right" data-aos-duration="1500">3. {t("Payment")}</div>
                        <div className={style.registration_qr_payment_item_content}>
                            <div className={"col-sm-4 col-12 " + style.registration_qr_payment_item_content_img} data-aos="zoom-in" data-aos-duration="1500"><img src={block3Payment} alt="Payment"/></div>
                            <div className={darkMode ? style.registration_qr_payment_item_content_text + " light-text" : style.registration_qr_payment_item_content_text} data-aos="fade-down" data-aos-duration="1500">
                                <div className="col-md-12 col-lg-8 col-12 mb-4">{t("The guest or customer scans the QR code, goes to your personal page, where he leaves a tip.")}</div>
                                <div className="col-md-12 col-lg-8 col-12">{t("The payment procedure takes a minute.")}</div>
                                <div className={style.arrow}><img src={arrowImg} alt="Arrow"/></div>
                            </div>
                        </div>
                    </div>
                    <div className={style.payment_block_right + " d-flex col-sm-6 col-12"}>
                        <div className="col-md-6 col-12 text-white mt-sm-5">
                            <div className="small_title mb-3" data-aos="fade-left" data-aos-duration="1500">{t("How do they pay?")}</div>
                            <div className={style.payment_block_right_subtitle} data-aos="fade-right" data-aos-duration="1500">
                                {t("In your personal account you can download or order the delivery of ready-made personalized layouts (Business cards, stickers, tabloid labels).")}
                                <div>{t("Just download or order delivery.")}</div>
                            </div>
                        </div>
                        <div className={style.payment_block_right_img} data-aos="zoom-in" data-aos-duration="1500"><img src={block3PaymentImage} alt="Tablet"/></div>
                    </div>
                </div>
                <div className={"col col-12 text-white mt-5 " + style.withdrawing_block}>
                    <div className={"section_small_title " + style.registration_qr_payment_item_title} data-aos="fade-right" data-aos-duration="1500">4. {t("Withdrawing money from your card")}</div>
                    <div className={style.withdrawing_block_content + " d-flex"}>
                        <div className={style.withdrawing_block_content_items}>
                            <div className={style.withdrawing_block_content_item + " d-flex align-items-center col-md-10 col-lg-7 col-12"} data-aos="fade-left" data-aos-duration="1500" >
                                <div className={style.withdrawing_block_content_item_img + " col-sm-3 col-lg-2 col-12"}><img src={block4Icon1} alt="block4BigImage"/></div>
                                <div className={style.withdrawing_block_content_item_text + " section_description"}>{t("Money is withdrawn immediately, you only need to specify the number of your card.")}</div>
                            </div>
                            <div className={style.withdrawing_block_content_item + " d-flex align-items-center col-md-10 col-lg-7 col-12"} style={{marginLeft: "calc(0px + 100 * ((100vw - 320px) / (1860 - 320)))"}} data-aos="fade-right" data-aos-duration="1500">
                                <div className={style.withdrawing_block_content_item_img + " col-sm-3 col-lg-2 col-12"}><img src={block4Icon2} alt="block4BigImage"/></div>
                                <div className={style.withdrawing_block_content_item_text + " section_description"}>The system is fully automated, no need to wait.</div>
                            </div>
                        </div>
                        <div className={style.withdrawing_block_content_img} data-aos="zoom-in" data-aos-duration="1500"><img src={block4BigImage} alt=""/></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RegistrationQrPayment;
import React from 'react';
import style from './OurVisionAndMission.module.scss';
import image from "../../../assets/images/check.png";
import {useSelector} from "react-redux";
import SectionSmallTitle from "../../SectionSmallTitle";
import {useTranslation} from "react-i18next";
import OurVisionAndMissionItem from "./OurVisionAndMissionItem";

function OurVisionAndMission(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <section className={darkMode ? style.our_vision_and_mission + " dark-section-bg light-text" : style.our_vision_and_mission}>
            <div className="container">
                <div className="row" data-aos="fade-up" data-aos-duration="1500">
                    <SectionSmallTitle title="Our Vision and Mission" textAlign="center"/>
                    <div className={darkMode ? style.our_vision_and_mission_desc + " section_description text-center col-12 light-text" : style.our_vision_and_mission_desc + " section_description text-center col-12"}>{t("The money tipped by customers to the personnel is often the main income source for the latter. Since cash turnover is gradually decreasing, clients of cafes, restaurants, hairdressers and other establishments are tipping less and less frequently. This directly affects the wages of employees. This is the problem we are trying to solve by designing, implementing and developing Tipssi.")}</div>
                    <div className="small_title text-center">{t("We overcame other difficulties facing visitors and the staff of establishments:")}</div>
                </div>
                <div className="row">
                    <div className={style.our_vision_and_mission_items}>
                        <OurVisionAndMissionItem text="Excluded the possibility of withdrawal by the administrator or owner of the cafe, hotel, etc. Tip will be given to the one who earned it."/>
                        <OurVisionAndMissionItem text="Simplified all actions for customers of restaurants, cabs, etc. - It is enough to have a smartphone with you."/>
                        <OurVisionAndMissionItem text="Organized a open and transparent process of all transactions."/>
                        <OurVisionAndMissionItem text="Cut the time for paying tips down to 2-3 seconds for both parties."/>
                        {/*<div className={darkMode ? style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12 dark-block-bg light-text" : style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12"} data-aos="zoom-in" data-aos-duration="1500">*/}
                        {/*    <div className={style.our_vision_and_mission_item_img + " col-lg-1 col-2 d-flex justify-center"}><img src={image} alt="Check Circle"/></div>*/}
                        {/*    <div className="col-lg-11 col-10">{t("Excluded the possibility of withdrawal by the administrator or owner of the cafe, hotel, etc. Tip will be given to the one who earned it.")}</div>*/}
                        {/*</div>*/}
                        {/*<div className={darkMode ? style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12 dark-block-bg light-text" : style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12"} data-aos="zoom-in" data-aos-duration="1500">*/}
                        {/*    <div className={style.our_vision_and_mission_item_img + " col-lg-1 col-2 d-flex justify-center"}><img src={image} alt="Check Circle"/></div>*/}
                        {/*    <div className="col-lg-11 col-10">{t("Simplified all actions for customers of restaurants, cabs, etc. - It is enough to have a smartphone with you.")}</div>*/}
                        {/*</div>*/}
                        {/*<div className={darkMode ? style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12 dark-block-bg light-text" : style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12"} data-aos="zoom-in" data-aos-duration="1500">*/}
                        {/*    <div className={style.our_vision_and_mission_item_img + " col-lg-1 col-2 d-flex justify-center"}><img src={image} alt="Check Circle"/></div>*/}
                        {/*    <div className="col-lg-11 col-10">{t("Organized a open and transparent process of all transactions.")}</div>*/}
                        {/*</div>*/}
                        {/*<div className={darkMode ? style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12 dark-block-bg light-text" : style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12"} data-aos="zoom-in" data-aos-duration="1500">*/}
                        {/*    <div className={style.our_vision_and_mission_item_img + " col-lg-1 col-2 d-flex justify-center"}><img src={image} alt="Check Circle"/></div>*/}
                        {/*    <div className="col-lg-11 col-10">{t("Cut the time for paying tips down to 2-3 seconds for both parties.")}</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurVisionAndMission;
import React from 'react';
import FrequentlyAskedQuestions from "../components/home-page-components/frequently-asked-questions/FrequentlyAskedQuestions";
import homePageImage from '../assets/images/start-iamge.png'
import Banner from "../components/banner/Banner";
import HowItsWork from "../components/how-its-work/HowItsWork";
import HowToThePay from "../components/how-to-the-pay/HowToThePay";
import WithdrawalOfFunds from "../components/withdrawal-of-funds/WithdrawalOfFunds";
import WhoNeedsOurService from "../components/home-page-components/who-needs-our-service/WhoNeedsOurService";
import BusinessOwners from "../components/business-owners/BusinessOwners";
import WhereToSetupAQRCode from "../components/where-to-set-up-qr-code/WhereToSetupAQRCode";
import ItIsConvenient from "../components/it-is-convenient/ItIsConvenient";
import HowMuchDoesItCost from "../components/how-much-it-cost/HowMuchDoesItCost";
import DoYouWantToAcceptTips from "../components/do-you-want-to-accept-tips/DoYouWantToAcceptTips";

function Home(props) {
    return (
        <div className="home">
            <Banner image={homePageImage} title="Receive digital tips by QR" subtitle="Accept tips from your card easily, quickly and securely. 1 minute registration, instant withdrawal to the bank card or via Bizum"/>
            <HowItsWork bgc="#F0F2F9"/>
            <HowToThePay bgc="#fff"/>
            <WithdrawalOfFunds/>
            <WhoNeedsOurService/>
            {/*<BusinessOwners/>*/}
            <WhereToSetupAQRCode/>
            <ItIsConvenient/>
            <HowMuchDoesItCost/>
            <DoYouWantToAcceptTips/>
            <FrequentlyAskedQuestions/>
        </div>
    );
}

export default Home;
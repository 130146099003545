import React from 'react';
import style from './WhereToSetupAQRCode.module.scss';
import image from '../../assets/images/set-up-qr-code.png'
import image2 from '../../assets/images/set-up-qr-code-2.png'
import image3 from '../../assets/images/set-up-qr-code-3.png'
import image4 from '../../assets/images/set-up-qr-code-4.png'
import image5 from '../../assets/images/set-up-qr-code-5.png'
import image6 from '../../assets/images/set-up-qr-code-6.png'
import WhereToSetupAqrCodeItem from "./WhereToSetupAQRCodeItem";
import {useTranslation} from "react-i18next";
import SectionBigTitle from "../SectionBigTitle";
function WhereToSetupAqrCode(props) {
    const {t} = useTranslation();
    return (
        <section className={style.where_to_setup_qr_code}>
            <div className="container">
                <div className="row ">
                    <SectionBigTitle title="Where to set up a QR code?" textAlign="center"/>
                    <div className={"col-12 d-flex flex-wrap " + style.where_to_setup_qr_code_items}>
                        <WhereToSetupAqrCodeItem text="Business cards" image={image}/>
                        <WhereToSetupAqrCodeItem text="Stickers" image={image2}/>
                        <WhereToSetupAqrCodeItem text="Receipts" image={image3}/>
                        <WhereToSetupAqrCodeItem text="Mobile phone" image={image4}/>
                        <WhereToSetupAqrCodeItem text="Badge" image={image5}/>
                        <WhereToSetupAqrCodeItem text="Table tents" image={image6}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhereToSetupAqrCode;
import React from 'react';
import style from './AboutBanner.module.scss';
import CustomLink from "../../CustomLink";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
function AboutBanner(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <section className={darkMode ? style.bannerContainer + " dark-section-bg" : style.bannerContainer}>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className='col-12' data-aos="fade-right" data-aos-duration="1500">
                        <div className={style.main_title}>{t("About us")}</div>
                        <div className={darkMode ? style.main_subtitle + " mt-6 light-text" : style.main_subtitle + " mt-6"}>{t("Accept tips from your card simply, quickly and securely. Registration in a minute, instant withdrawal to the card")}</div>
                        <CustomLink className='custom_btn' link="/sign-in" text="Get Started"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutBanner;
import "../../../assets/styles/globalStyles.scss";
import React, {useCallback, useRef} from 'react';
import style from "./PrintItem.module.scss";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from "@mui/material/Button";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypePng } from "react-icons/bs";
import {Box} from "@mui/material";
import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import {useSelector} from "react-redux";
import {toPng} from "html-to-image";
import download from 'downloadjs';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
function PrintItem({title, size, pdfFile, pngFile, image}) {
    const {user} = useSelector(state => state.user);
    const {id, userPhone} = user && user
    const navigate = useNavigate();
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    const pdfViewer = () => {
        navigate(`/profile/pdf/${id}/${userPhone}/${title}`);
    }
    const pngDownload = useCallback(() => {
        if (pngFile.ref.current === null) {
            return
        }
        toPng(pngFile.ref.current)
            .then(function (dataUrl) {
                download(dataUrl, `${id}.png`);
            });
    }, [pngFile])

    return (
        <div className={style.print_item_body + " col-lg-4 col-md-6 col-sm-10 col-12"}>
            <div className={darkMode ? style.print_item + " dark-block-bg light-text" : style.print_item}>
                <div className={style.print_item_header}>
                    <div className={style.print_item_image}><img src={image} alt="Icon"/></div>
                    <div className={style.print_item_content}>
                        <div className={style.print_item_title}>{title}</div>
                        <div className={style.print_item_size}>{size}</div>
                    </div>
                </div>
                <Box className={style.print_item_footer + " flex-xl-row flex-lg-column flex-md-column flex-sm-column flex-column align-items-xl-center align-items-lg-start align-items-md-start align-items-sm-start align-items-start gap-3 mt-xl-0 mt-lg-2 mt-2"} sx={{color: "#60758D"}}>
                    <Button variant="contained" className={darkMode ? style.print_item_show + " " + style.darkEye: style.print_item_show} onClick={pdfViewer}><RemoveRedEyeIcon/></Button>
                    <div className={style.print_item_buttons + " flex-xl-row flex-lg-column flex-md-column flex-sm-column flex-column col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12"}>
                        <PDFDownloadLink document={pdfFile} fileName={id + ".pdf"} className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12"><Button variant="contained" startIcon={<FaRegFilePdf/>} style={{width: "100%"}}>{t("Download")}</Button></PDFDownloadLink>
                        <Button variant="contained" onClick={pngDownload} disabled="disabled" className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 disabled" startIcon={<BsFiletypePng/>}><span style={{color:" #E1E1E1B2"}}>{t("Download")}</span></Button>
                    </div>
                </Box>
            </div>
        </div>
    );
}

export default PrintItem;
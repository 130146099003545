import React from 'react';
import PaymentComponent from "../components/payment-components/PaymentComponent";
import style from "../components/payment-components/PaymentComponent.module.scss"
import { useSelector} from "react-redux";
import TranslatePage from "../components/TranslatePage";
function Payment(props) {
    const {user, refreshUser} = useSelector(state => state.user);
    //
    // const dispatch = useDispatch();
    // const profileImage = user.length > 0 && user[0].imageName;
    // const importAvatar = (imageName) => {
    //     try {
    //         return require(`../assets/images/avatars/${imageName}`);
    //     } catch (err) {
    //         return defaultAvatar;
    //     }
    // };
    //
    // useEffect(() => {
    //     const avatarPath = profileImage ? importAvatar(profileImage) : defaultAvatar;
    //     dispatch(ImagePath(avatarPath));
    // }, [user])

    return (
        <section className={style.payment + " p-2 d-flex align-items-start"}>
            <PaymentComponent/>
            {/*<TranslatePage/>*/}
        </section>
    );
}

export default Payment;
import React from 'react';
import ContactUsComponent from "../components/contact-us/ContactUsComponent";

function ContactUS(props) {
    return (
        <section className="contact-us pt-4 pb-4">
            <div className="container">
                <div className="row">
                    <ContactUsComponent/>
                </div>
            </div>
        </section>
    );
}

export default ContactUS;
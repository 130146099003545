import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {useDispatch, useSelector} from "react-redux";
import {PaymentStatusModalOpen} from "../redux/slices/paymentSlice";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
};

export default function PaymentErrorModal() {
    const {payStatusModalOpen} = useSelector(state => state.currency);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(PaymentStatusModalOpen(false));
    };
    // useEffect(() => {
    //     setTimeout(()=> {
    //         handleClose()
    //     }, 1400)
    // }, [payStatusModalOpen]);
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={payStatusModalOpen}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={payStatusModalOpen}>
                    <Box sx={style}>
                        {/*<svg className="error-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" width="300"*/}
                        {/*     height="300">*/}
                        {/*    <line className="error-mark__line1" x1="16" y1="16" x2="36" y2="36" stroke="red"*/}
                        {/*          stroke-width="4" stroke-linecap="round" stroke-dasharray="29.29"*/}
                        {/*          stroke-dashoffset="29.29">*/}
                        {/*        <animate attributeName="stroke-dashoffset" from="29.29" to="0" dur="0.2s"*/}
                        {/*                 fill="freeze"/>*/}
                        {/*    </line>*/}
                        {/*    <line className="error-mark__line2" x1="36" y1="16" x2="16" y2="36" stroke="red"*/}
                        {/*          stroke-width="4" stroke-linecap="round" stroke-dasharray="29.29"*/}
                        {/*          stroke-dashoffset="29.29">*/}
                        {/*        <animate attributeName="stroke-dashoffset" from="29.29" to="0" dur="0.2s" fill="freeze"*/}
                        {/*                 begin="0.2s"/>*/}
                        {/*    </line>*/}
                        {/*</svg>*/}
                        <SentimentVeryDissatisfiedIcon style={{color:"#FF0000", fontSize: 150, marginBottom: 20}}/>
                        <div className="payment_error_modal_title">Your payment failed</div>
                        <div className="payment_error_modal_subtitle">Please try again</div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

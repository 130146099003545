import React from 'react';

function PaymentFailed(props) {
    return (
        <svg className="error-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" width="300" height="300">
            <circle className="error-mark__circle" cx="26" cy="26" r="25" fill="none" stroke="red" stroke-width="2"/>
            <line className="error-mark__line1" x1="16" y1="16" x2="36" y2="36" stroke="red" stroke-width="4"
                  stroke-linecap="round" stroke-dasharray="28.28" stroke-dashoffset="28.28">
                <animate attributeName="stroke-dashoffset" from="28.28" to="0" dur="0.5s" fill="freeze"/>
            </line>
            <line className="error-mark__line2" x1="36" y1="16" x2="16" y2="36" stroke="red" stroke-width="4"
                  stroke-linecap="round" stroke-dasharray="28.28" stroke-dashoffset="28.28">
                <animate attributeName="stroke-dashoffset" from="28.28" to="0" dur="0.5s" fill="freeze" begin="0.5s"/>
            </line>
        </svg>
    );
}
export default PaymentFailed;
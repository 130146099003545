import React, {useEffect, useState} from 'react';
import style from './ProfileTransactions.module.scss';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ProfileTransactionItem from "./profile-transaction-item/ProfileTransactionItem";
import PaymentService from "../../../services/PaymentService";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function ProfileTransactions(props) {
    const {user} = useSelector(state => state.user);
    const {id, userPhone} = user && user
    const [transactions, setTransactions] = useState([]);
    const darkMode = useSelector((state) => state.settings.darkMode);
    const { t } = useTranslation();

    const getTransactions = async () => {
        try {
            if (id) {
                const response = await PaymentService.getTransaction(id);
                const transactions = response.data;
                const senderTransactions = transactions.filter(transaction => transaction.transactionUser === id);
                const recipientTransactions = transactions.filter(transaction => transaction.transactionUser === userPhone);

                if (senderTransactions.length > 0) {
                    setTransactions(senderTransactions);
                }else if (recipientTransactions.length > 0) {
                    setTransactions(recipientTransactions);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTransactions()
    }, [id]);

    return (
        <div className={style.profile_transactions + " col-lg-8 col-md-12 col-sm-12 col-12"}>
            <div className={darkMode ? style.profile_transactions_content + " dark-block-bg light-text" : style.profile_transactions_content}>
                <div className={style.profile_transactions_content_title}>{t("Transactions")}</div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{backgroundColor: darkMode && "#373737"}}>
                                <TableCell className={darkMode && "light-text"}>{t("Type")}</TableCell>
                                <TableCell className={darkMode && "light-text"}>{t("Date")}</TableCell>
                                {/*<TableCell className={darkMode && "light-text"}>{t("Time")}</TableCell>*/}
                                <TableCell className={darkMode && "light-text"}>{t("Amount")}</TableCell>
                                <TableCell className={darkMode && "light-text"}>{t("Rating")}</TableCell>
                                {/*<TableCell className={darkMode && "light-text"}>{t("Comment")}</TableCell>*/}
                                <TableCell className={darkMode && "light-text"}>{t("Survey")}</TableCell>
                                {/*<TableCell className={darkMode && "light-text"}>{t("Status")}</TableCell>*/}
                                {/*<TableCell className={darkMode && "light-text"}>{t("Action")}</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.length > 0 ?
                                transactions.map((transaction) => {
                                    return (
                                        <ProfileTransactionItem
                                            key={transaction.id}
                                            amount={transaction.transactionAmount}
                                            date={transaction.transactionDate}
                                            time={transaction.transactionTime}
                                            status={transaction.transactionStatus}
                                            type={transaction.transactionType}
                                            rating={transaction.transactionRating}
                                            comment={transaction.transactionComment}
                                            goodDrink={transaction.goodDrink}
                                            cleanPremises={transaction.cleanPremises}
                                            goodFood={transaction.goodFood}
                                            goodMusic={transaction.goodMusic}
                                            goodService={transaction.goodService}
                                            likeEuro={transaction.likeEuro}
                                        />
                                    )
                                })
                                : <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                    className={style.transaction_item}
                                ><TableCell align="center" className={darkMode && "dark-block-bg light-text"} colSpan={8}>{t("No data available!")}</TableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default ProfileTransactions;
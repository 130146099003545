import React, {useEffect} from 'react';
import Banner from "../components/banner/Banner";
import bannerImage from "../assets/images/get-started-for-staff.png";
import HowItsWork from "../components/how-its-work/HowItsWork";
import HowToThePay from "../components/how-to-the-pay/HowToThePay";
import WithdrawalOfFunds from "../components/withdrawal-of-funds/WithdrawalOfFunds";
import ItIsConvenient from "../components/it-is-convenient/ItIsConvenient";
import DoYouWantToAcceptTips from "../components/do-you-want-to-accept-tips/DoYouWantToAcceptTips";
import {useLocation} from "react-router-dom";
function Staff(props) {
    const location = useLocation();

    useEffect(() => {
        document.title = "For Staff - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);
    return (
        <div className="staff">
            <Banner image={bannerImage} bgc="#fff" title="Accept tips on the card" subtitle="Accept tips from guests with your card. Registration in a minute, instant withdrawal to the card. If a customer wants to leave a tip, he doesn't need to install an app."/>
            <HowItsWork/>
            <HowToThePay/>
            <WithdrawalOfFunds/>
            <ItIsConvenient bgc="#fff" itemsBG="#F0F2F9"/>
            <DoYouWantToAcceptTips bgc="#F0F2F9"/>
        </div>
    );
}

export default Staff;
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import {useDispatch, useSelector} from "react-redux";
import {UserRating} from "../../redux/slices/paymentSlice";

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" />,
        label: 'Very Dissatisfied',
        number: 1
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" />,
        label: 'Dissatisfied',
        number: 2
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" />,
        label: 'Neutral',
        number: 3
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" />,
        label: 'Satisfied',
        number: 4
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" />,
        label: 'Very Satisfied',
        number: 5
    },
};

function IconContainer(props) {
    const dispatch = useDispatch()
    const { value, ...other } = props;
    return <div {...other}><span>{customIcons[value].icon}</span></div>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function PaymentRating() {
    const { rating } = useSelector(state => state.currency);
    const dispatch = useDispatch()

    const addRating = (e) => {
        dispatch(UserRating(e.target.value))
    }

    return (
        <StyledRating
            name="highlight-selected-only"
            value={rating}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
            onChange={(e) => addRating(e)}
        />
    );
}

import React from 'react';
import style from './WhoNeedsOurService.module.scss'
import waitersImage from '../../../assets/images/waiters.png'
import couriersImage from '../../../assets/images/couriers.png'
import musiciansImage from '../../../assets/images/musicians.png'
import taxiImage from '../../../assets/images/Taxi-drivers.png'
import doormenImage from '../../../assets/images/doormen.png'
import bartendersImage from '../../../assets/images/bartenders.png'
import maidsImage from '../../../assets/images/maids.png'
import hairdressersImage from '../../../assets/images/hairdressers.png'
import {useSelector} from "react-redux";
import SectionSmallTitle from "../../SectionSmallTitle";
import SectionDescription from "../../SectionDescription";
import {useTranslation} from "react-i18next";
function WhoNeedsOurService(props) {
    const {t} = useTranslation()
    return (
        <section className={style.who_needs_our_service}>
            <div className="container">
                <div className="row" data-aos="fade-down" data-aos-duration="1500">
                    <SectionSmallTitle title="Who needs our service?" marginBottom={24} textAlign="center"/>
                    <SectionDescription col="col-md-6 col-12 m-auto" text="Everyone who receives a tip. Often clients or guests either don't have cash or don't have small denomination bills."/>
                </div>
                <div className="row row-gap-3" style={{marginTop: 60}}>
                    <div className="col-md-3 col-6 d-flex flex-column gap-3" style={{marginTop: "calc(20px + 120 * ((100vw - 320px) / (1820 - 320)))"}}>
                        <div className={style.item} style={{backgroundImage: `url(${waitersImage})`, height: "calc(150px + 210 * ((100vw - 320px) / (1820 - 320)))"}} data-aos="zoom-in" data-aos-duration="1500">
                            <div className={style.item_content}>{t("Waiters")}</div>
                        </div>
                        <div className={style.item} style={{backgroundImage: `url(${couriersImage})`, height: "calc(171px + 117 * ((100vw - 320px) / (1820 - 320)))"}} data-aos="zoom-in" data-aos-duration="1500">
                            <div className={style.item_content}>{t("Couriers")}</div>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 d-flex flex-column gap-3" style={{marginTop: 22}}>
                        <div className={style.item}
                             style={{backgroundImage: `url(${musiciansImage})`, height: "calc(117px + 171 * ((100vw - 320px) / (1820 - 320)))"}} data-aos="zoom-in" data-aos-duration="1500">
                            <div className={style.item_content}>{t("Musicians")}</div>
                        </div>
                        <div className={style.item} style={{backgroundImage: `url(${taxiImage})`, height: "calc(217px + 304 * ((100vw - 320px) / (1820 - 320)))"}} data-aos="zoom-in" data-aos-duration="1500">
                            <div className={style.item_content}>{t("Taxi drivers")}</div>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 d-flex flex-column gap-3">
                        <div className={style.item} style={{backgroundImage: `url(${doormenImage})`, height: "calc(217px + 304 * ((100vw - 320px) / (1820 - 320)))"}} data-aos="zoom-in" data-aos-duration="1500"><div className={style.item_content}>{t("Doormen")}</div></div>
                        <div className={style.item} style={{backgroundImage: `url(${bartendersImage})`, height: "calc(141px + 136 * ((100vw - 320px) / (1820 - 320)))"}} data-aos="zoom-in" data-aos-duration="1500"><div className={style.item_content}>{t("Bartenders")}</div></div>
                    </div>
                    <div className="col-md-3 col-6 d-flex flex-column gap-3">
                        <div className={style.item} style={{backgroundImage: `url(${maidsImage})`, height: "calc(141px + 136 * ((100vw - 320px) / (1820 - 320)))"}} data-aos="zoom-in" data-aos-duration="1500"><div className={style.item_content}>{t("Maids")}</div></div>
                        <div className={style.item} style={{backgroundImage: `url(${hairdressersImage})`, height: "calc(211px + 150 * ((100vw - 320px) / (1820 - 320)))"}} data-aos="zoom-in" data-aos-duration="1500"><div className={style.item_content}>{t("Hairdressers")}</div></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhoNeedsOurService;
import React, {useEffect, useState} from 'react';
import style from "./ContactUsComponent.module.scss";
import {Box, FormHelperText, TextField} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config"
import Button from "@mui/material/Button";
import companyIcon from '../../assets/images/company.png';
import telIcon from '../../assets/images/tel.svg';
import envelopIcon from '../../assets/images/envelop.svg';
import {useDispatch, useSelector} from "react-redux";
import {SendEmail, SendFullName, SendMessage, SendSubject} from "../../redux/slices/contactSlice";
import contactService from "../../services/ContactService"
import {IsLoading} from "../../redux/slices/userSlice";
import {Alert} from "@mui/lab";
import {useTranslation} from "react-i18next";
function ContactUsComponent(props) {
    const [captchaValue, setCaptchaValue] = useState(false);
    const {fullName, email, subject, message} = useSelector(state => state.contact);
    const [error, setError] = useState("");
    const validationMap = {
        "Full name is required": {
            fullName: true,
            message: false,
            email: false,
            subject: false
        },
        "E-mail is required": {
            fullName: false,
            message: false,
            email: true,
            subject: false
        },
        "Subject is required": {
            fullName: false,
            message: false,
            email: false,
            subject: true
        },
        "Message is required": {
            fullName: false,
            message: true,
            email: false,
            subject: false
        },
        "Full name is required,E-mail is required,Subject is required,Message is required": {
            fullName: true,
            message: true,
            email: true,
            subject: true
        },
        "Full name is required,E-mail is required": {
            fullName: true,
            message: false,
            email: true,
            subject: false
        },
        "E-mail is required,Full name is required": {
            fullName: true,
            message: false,
            email: true,
            subject: false
        },
        "Full name is required,Subject is required": {
            fullName: true,
            message: false,
            email: false,
            subject: true
        },
        "Subject is required,Full name is required": {
            fullName: true,
            message: false,
            email: false,
            subject: true
        },
        "Full name is required,Message is required": {
            fullName: true,
            message: true,
            email: false,
            subject: false
        },
        "Message is required,Full name is required": {
            fullName: true,
            message: true,
            email: false,
            subject: false
        },
        "E-mail is required,Message is required": {
            fullName: false,
            message: true,
            email: true,
            subject: false
        },
        "Message is required,E-mail is required": {
            fullName: false,
            message: true,
            email: true,
            subject: false
        },
        "E-mail is required,Subject is required": {
            fullName: false,
            message: false,
            email: true,
            subject: true
        },
        "Subject is required,E-mail is required": {
            fullName: false,
            message: false,
            email: true,
            subject: true
        },
        "Message is required,Subject is required": {
            fullName: false,
            message: true,
            email: false,
            subject: true
        },
        "Subject is required,Message is required": {
            fullName: false,
            message: true,
            email: false,
            subject: true
        },
        "E-mail is required,Subject is required,Message is required": {
            fullName: false,
            message: true,
            email: true,
            subject: true
        },
        "Message is required,Subject is required,E-mail is required": {
            fullName: false,
            message: true,
            email: true,
            subject: true
        }
    };
    const [fullNameValidation, setFullNameValidation] = useState(false);
    const [emailValidation, setEmailValidation] = useState(false);
    const [subjectValidation, setSubjectValidation] = useState(false);
    const [messageValidation, setMessageValidation] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
    const [success, setSendSuccess] = useState(false)
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();

    const handleCaptchaChange = async (value) => {
        const response = await fetch(`${config.BACKEND_URL}verify-captcha`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({token: value}),
        });
        if (response.status === 200) {
            setCaptchaValue(true);
            setCaptchaError(false);
        } else {
            setCaptchaValue(false);
        }
    };
    const handleSubmit = async () => {
        if (!captchaValue && fullName !== "" && email !== "" && subject !== "" && message !== "") {
            setCaptchaError(true);
            return;
        }
        dispatch(IsLoading(true));
        try {
            const data = {
                fullName,
                email,
                subject,
                message
            };
            const result = await contactService.sendContactMail(data);
            if (result.status === 200) {
                dispatch(SendFullName(""))
                dispatch(SendMessage(""))
                dispatch(SendEmail(""))
                dispatch(SendSubject(""))
                setSendSuccess(true)
            }
        } catch (error) {
            setError(error.response?.data?.message);
        } finally {
            dispatch(IsLoading(false));
        }
    };
    const dispatch = useDispatch()
    const handleFullNameChange = (e) => {
        dispatch(SendFullName(e.target.value))
    }
    const handleEmailChange = (e) => {
        dispatch(SendEmail(e.target.value))
    }
    const handleSubjectChange = (e) => {
        dispatch(SendSubject(e.target.value))
    }
    const handleMessageChange = (e) => {
        dispatch(SendMessage(e.target.value))
    }
    useEffect(() => {
        const validation = validationMap[error];
        if (validation) {
            setFullNameValidation(validation.fullName);
            setMessageValidation(validation.message);
            setEmailValidation(validation.email);
            setSubjectValidation(validation.subject);
        }
    }, [error]);
    useEffect(() => {
        if (fullName.length > 2) {
            setFullNameValidation(false);
        }
        if (email.length > 2) {
            setEmailValidation(false);
        }
        if (subject.length > 2) {
            setSubjectValidation(false);
        }
        if (message.length > 2) {
            setMessageValidation(false);
        }
    }, [fullName, email, subject, message]);

    return (
        <div className={style.contact + " col-12"}>
            <div
                className={darkMode ? style.contact_form + " dark-block-bg light-text dark-input d-flex flex-column align-start col-md-7 col-12" : style.contact_form + " d-flex flex-column align-start col-md-7 col-12 bg-white"}>
                {success &&
                    <Alert variant="filled" severity="success" className="mb-2">Thank you for contacting us, we will
                        contact you soon.</Alert>}
                <div className={style.contact_title}>Contact Us</div>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': {m: 1, width: '100%'},
                    }}
                    noValidate
                    autoComplete="on"
                >
                    <div className="d-flex justify-content-between gap-4 ms-0 me-0">
                        <div className="w-100">
                            <TextField
                                error={fullNameValidation && "error"}
                                id="outlined-basic"
                                type="text"  // Добавьте этот атрибут для ясности
                                label={t("Full Name")}
                                variant="outlined"
                                fullWidth
                                value={fullName}
                                autoComplete="name"  // Добавьте этот атрибут
                                onChange={(e) => handleFullNameChange(e)}
                            />

                            {fullNameValidation &&
                                <Box sx={{
                                    '& > :not(style)': {m: 1, width: '100%'},
                                }}><FormHelperText error={fullNameValidation && "error"}
                                                   id="outlined-weight-helper-text">{t("Full name isrequired")}</FormHelperText></Box>}
                        </div>
                        <div className="w-100">
                            <TextField
                                error={emailValidation && "error"}
                                id="outlined-basic"
                                label={t("E-mail")}
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => handleEmailChange(e)}
                            />
                            {emailValidation &&
                                <Box sx={{
                                    '& > :not(style)': {m: 1, width: '100%'},
                                }}><FormHelperText error={emailValidation && "error"}
                                                   id="outlined-weight-helper-text">{t("E - mail is required(@)")}</FormHelperText></Box>}
                        </div>
                    </div>
                    <TextField
                        error={subjectValidation && "error"}
                        id="outlined-basic"
                        label={t("Subject")}
                        variant="outlined"
                        fullWidth
                        className="ms-0 me-0"
                        value={subject} onChange={(e) => handleSubjectChange(e)}
                    />
                    {subjectValidation &&
                        <FormHelperText error={subjectValidation && "error"} id="outlined-weight-helper-text" className="mt-0">{t("Subject is required")}</FormHelperText>}
                    <TextField
                        error={messageValidation && "error"}
                        id="outlined-basic"
                        label={t("Message")}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        className="ms-0 me-0 message-field"
                        value={message}
                        onChange={(e) => handleMessageChange(e)}
                    />
                    {messageValidation &&
                        <FormHelperText error={messageValidation && "error"} id="outlined-weight-helper-text" className="mt-0">{t("Message is required")}</FormHelperText>}
                    <div className="d-flex align-items-center justify-content-between ms-0 me-0">
                        <div className="d-flex flex-column align-items-start">
                            <ReCAPTCHA
                                sitekey="6LdpNPYpAAAAADmoiSbORUtVi6U80nr3oQu2SH91"
                                onChange={handleCaptchaChange}
                            />
                            {captchaError && <FormHelperText error="error"
                                                             className="mt-0">{t("Please complete the CAPTCHA")}</FormHelperText>}
                        </div>
                        <Button variant="contained" className={style.send_button} size="large"
                                onClick={handleSubmit}>{t("Send Message")}</Button>
                    </div>
                </Box>
                <div className={style.contact_bottom}>
                    <a href="https://www.google.com/maps/place/C.+del+Consejo+de+Ciento,+383,+1-1,+Eixample,+08009+Barcelona,+Espa%C3%B1a/@41.3939871,2.1694203,144m/data=!3m1!1e3!4m6!3m5!1s0x12a4a2ec261dc557:0xf4245671faeee17b!8m2!3d41.3939724!4d2.1693334!16s%2Fg%2F11q1t0shky?hl=es&entry=ttu"
                       className={style.contact_bottom_item + " d-flex flex-column align-items-center col-sm-4 col-12"}>
                        <div className={style.image}><img src={companyIcon} alt="company"/></div>
                        <div className={style.contact_bottom_item_text}>
                            <div>Hay Conexion SL. NIF B44872653</div>
                            <div>Consell de Cent 383, 1-1</div>
                            <div>08009 Barcelona, Spain</div>
                        </div>
                    </a>
                    <a href="tel:+34617145050" className={style.contact_bottom_item + " d-flex flex-column align-items-center col-sm-4 col-12"}>
                        <div className={style.image}><img src={telIcon} alt="phone"/></div>
                        <div className={style.contact_bottom_item_text}>
                            <div>+34 617-145-050</div>
                        </div>
                    </a>
                    <a href="mailto:tipssi@tipssi.net"
                       className={style.contact_bottom_item + " d-flex flex-column align-items-center col-sm-4 col-12"}>
                        <div className={style.image}><img src={envelopIcon} alt="envelope"/></div>
                        <div className={style.contact_bottom_item_text}>
                            <div>tipssi@tipssi.net</div>
                        </div>
                    </a>
                </div>
            </div>
            <div className={style.map + " col-md-5 col-12"} style={{height: "100%"}}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d627.6261634993793!2d2.169420251989358!3d41.393987142912785!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2ec261dc557%3A0xf4245671faeee17b!2sC.%20del%20Consejo%20de%20Ciento%2C%20383%2C%201-1%2C%20Eixample%2C%2008009%20Barcelona%2C%20Espa%C3%B1a!5e1!3m2!1ses!2sam!4v1722772921469!5m2!1ses!2sam"
                    width={590} height={788} style={{borderWidth: 0, height: "100%"}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}

export default ContactUsComponent;
import React, {useEffect} from 'react';
import style from "../components/profile-components/profile-content/ProfileContent.module.scss";
import ProfileEditContent from "../components/profile-components/profile-edit/ProfileEditContent";
import ProfileHeader from "../components/profile-components/profile-header/ProfileHeader";
import {useLocation} from "react-router-dom";

function ProfileEdit() {
    const location = useLocation();

    useEffect(() => {
        document.title = "Edit - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);

    return (
        <section className={style.profile_content}>
            <div className="container">
                <ProfileHeader/>
                <ProfileEditContent/>
            </div>
        </section>
    );
}

export default ProfileEdit;
import React from 'react';
import style from './ItIsConvenient.module.scss';
import clockImage from '../../assets/images/its-convenient-clock.png'
import convenientPrivacyImage from '../../assets/images/its-convenient-privacy.png'
import convenientPrintImage from '../../assets/images/its-convenient-print.png'
import convenientControlImage from '../../assets/images/its-convenient-control.png'
import convenientSecurityImage from '../../assets/images/its-convenient-security.png'
import convenientMobileAppImage from '../../assets/images/its-convenient-mobile-app.png'
import {useSelector} from "react-redux";
import ItIsConvenientItem from "./ItIsConvenientItem";
import {useTranslation} from "react-i18next";
function ItIsConvenient({bgc, itemsBG}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation()
    return (
        <section className={darkMode ? style.it_is_convenient + " dark-section-bg light-text" : style.it_is_convenient} style={{backgroundColor: bgc}}>
            <div className="container">
                <div className="row">
                    <div className="section_title text-center" data-aos="fade-down" data-aos-duration="1500">{t("It is convenient")}</div>
                    <div className={style.it_is_convenient_items}>
                        <ItIsConvenientItem title="Immediate withdrawal" text="The system is fully automated, no wait" image={clockImage} itemsBG={itemsBG}/>
                        <ItIsConvenientItem title="Privacy" text="The payer does not see your personal data, only the QR code" image={convenientPrivacyImage} itemsBG={itemsBG}/>
                        <ItIsConvenientItem title="Printed materials" text="Download and print ready-made business card and sticker layouts" image={convenientPrintImage} itemsBG={itemsBG}/>
                        <ItIsConvenientItem title="Visibility. Control" text="All receipts are immediately visible: when and how many tips you got" image={convenientControlImage} itemsBG={itemsBG}/>
                        <ItIsConvenientItem title="Payment security" text="All payments are protected" image={convenientSecurityImage} itemsBG={itemsBG}/>
                        <ItIsConvenientItem title="Mobile app" text="For the tip recipient. Push notifications and transaction history" image={convenientMobileAppImage} itemsBG={itemsBG}/>
                        {/*<div className={darkMode ? style.it_is_convenient_item + " dark-block-bg" : style.it_is_convenient_item} data-aos="zoom-in" data-aos-duration="1500" style={{backgroundColor: itemsBG}}>*/}
                        {/*    <div className={style.it_is_convenient_item_image}><img src={clockImage} alt="Timer Icon"/></div>*/}
                        {/*    <div className={style.it_is_convenient_item_content}>*/}
                        {/*        <div className={darkMode ? " section_description fw-bold mb-2 light-text" : "section_description fw-bold mb-2"}>Immediate withdrawal</div>*/}
                        {/*        <div className={darkMode ? " section_description light-text" : "section_description"}></div>*/}
                        {/*    </div>*/}
                        {/*</div >*/}
                        {/*<div className={darkMode ? style.it_is_convenient_item + " dark-block-bg" : style.it_is_convenient_item} data-aos="zoom-in" data-aos-duration="1500" style={{backgroundColor: itemsBG}}>*/}
                        {/*    <div className={style.it_is_convenient_item_image}><img src={convenientPrivacyImage} alt="Timer Icon"/></div>*/}
                        {/*    <div className={style.it_is_convenient_item_content}>*/}
                        {/*        <div className={darkMode ? " section_description fw-bold mb-2 light-text" : "section_description fw-bold mb-2"}></div>*/}
                        {/*        <div className={darkMode ? " section_description light-text" : "section_description"}></div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={darkMode ? style.it_is_convenient_item + " dark-block-bg" : style.it_is_convenient_item} data-aos="zoom-in" data-aos-duration="1500" style={{backgroundColor: itemsBG}}>*/}
                        {/*    <div className={style.it_is_convenient_item_image}><img src={convenientPrintImage} alt="Timer Icon"/></div>*/}
                        {/*    <div className={style.it_is_convenient_item_content}>*/}
                        {/*        <div className={darkMode ? " section_description fw-bold mb-2 light-text" : "section_description fw-bold mb-2"}>Printed materials</div>*/}
                        {/*        <div className={darkMode ? " section_description light-text" : "section_description"}>Download and print ready-made business card and sticker layouts</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={darkMode ? style.it_is_convenient_item + " dark-block-bg" : style.it_is_convenient_item} data-aos="zoom-in" data-aos-duration="1500" style={{backgroundColor: itemsBG}}>*/}
                        {/*    <div className={style.it_is_convenient_item_image}><img src={convenientControlImage} alt="Timer Icon"/></div>*/}
                        {/*    <div className={style.it_is_convenient_item_content}>*/}
                        {/*        <div className={darkMode ? " section_description fw-bold mb-2 light-text" : "section_description fw-bold mb-2"}>Visibility. Control</div>*/}
                        {/*        <div className={darkMode ? " section_description light-text" : "section_description"}>All receipts are immediately visible: when and how many tips you got</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={darkMode ? style.it_is_convenient_item + " dark-block-bg" : style.it_is_convenient_item} data-aos="zoom-in" data-aos-duration="1500" style={{backgroundColor: itemsBG}}>*/}
                        {/*    <div className={style.it_is_convenient_item_image}><img src={convenientSecurityImage} alt="Timer Icon"/></div>*/}
                        {/*    <div className={style.it_is_convenient_item_content}>*/}
                        {/*        <div className={darkMode ? " section_description fw-bold mb-2 light-text" : "section_description fw-bold mb-2"}>Payment security</div>*/}
                        {/*        <div className={darkMode ? " section_description light-text" : "section_description"}>All payments are protected</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={darkMode ? style.it_is_convenient_item + " dark-block-bg" : style.it_is_convenient_item} data-aos="zoom-in" data-aos-duration="1500" style={{backgroundColor: itemsBG}}>*/}
                        {/*    <div className={style.it_is_convenient_item_image}><img src={convenientMobileAppImage} alt="Timer Icon"/></div>*/}
                        {/*    <div className={style.it_is_convenient_item_content}>*/}
                        {/*        <div className={darkMode ? " section_description fw-bold mb-2 light-text" : "section_description fw-bold mb-2"}>Mobile app</div>*/}
                        {/*        <div className={darkMode ? " section_description light-text" : "section_description"}>For the tip recipient. Push notifications and transaction history</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ItIsConvenient;
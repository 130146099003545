import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import {CheckModalOpen} from "../../../redux/slices/paymentSlice";
import {Fragment} from "react";
import logo from "../../../assets/images/logo.png"
import {Box} from "@mui/material";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import {FaRegFilePdf} from "react-icons/fa6";
import CheckTemplate from "./CheckTemplate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function Check({senderPhone, senderName, amount, recipientPhone, recipientName, comment, comicion, documentNumber, template}) {
    const {checkModalOpen} = useSelector((state) => state.currency);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(CheckModalOpen(false));
    };
    const date = new Date();
    const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).replace(',', '');
    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={checkModalOpen}
                maxWidth="xs"
                fullWidth={true}
                style={{marginTop: "73px"}}
            >
                <DialogContent dividers sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2}}>
                        <img src={logo} alt="logo" width={100}/>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15, marginTop: 1}}>Recibo de la transacción</Typography>
                        <Typography sx={{
                            color: "#9e9e9e",
                            fontSize: 15,
                            marginTop: 0.5,
                            borderBottom: "1px dashed #9e9e9e",
                            width: "100%",
                            textAlign: "center",
                            paddingBottom: 1
                        }}>{formattedDate}</Typography>
                    </Box>
                    <Box sx={{marginBottom: 0.5}}>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15}}>Operación</Typography>
                        <Typography sx={{color: "#000000c7", fontSize: 18, fontWeight: 600}}>Transferir al cliente TipsSi</Typography>
                    </Box>
                    <Box sx={{marginBottom: 0.5}}>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15}}>Recipiente</Typography>
                        <Typography sx={{color: "#000000c7", fontSize: 18, fontWeight: 600}}>{recipientName}</Typography>
                    </Box>
                    <Box sx={{marginBottom: 3}}>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15}}>Cuenta</Typography>
                        <Typography sx={{color: "#000000c7", fontSize: 18, fontWeight: 600}}>{recipientPhone}</Typography>
                    </Box>
                    <Box sx={{marginBottom: 0.5}}>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15}}>Titular</Typography>
                        <Typography sx={{color: "#000000c7", fontSize: 18, fontWeight: 600}}>{senderName}</Typography>
                    </Box>
                    <Box sx={{marginBottom: 0.5}}>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15}}>Cuenta</Typography>
                        <Typography sx={{color: "#000000c7", fontSize: 18, fontWeight: 600}}>{senderPhone}</Typography>
                    </Box>
                    <Box sx={{marginBottom: 0.5}}>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15}}>Importe</Typography>
                        <Typography sx={{color: "#000000c7", fontSize: 18, fontWeight: 600}}>{amount}€</Typography>
                    </Box>
                    <Box sx={{marginBottom: 0.5}}>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15}}>Comicion</Typography>
                        <Typography sx={{color: "#000000c7", fontSize: 18, fontWeight: 600}}>{comicion}</Typography>
                    </Box>
                    <Box sx={{marginBottom: 0.5}}>
                        <Typography sx={{color: "#8c8c8c", fontSize: 15}}>Numero de Documento</Typography>
                        <Typography sx={{color: "#000000c7", fontSize: 18, fontWeight: 600}}>{documentNumber}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <PDFDownloadLink
                        document={template}
                        // fileName={id + ".pdf"}
                    >
                        <Button
                            variant="contained"
                            startIcon={<FaRegFilePdf fontSize={23}/>}
                            style={{width: "100%"}}
                            className="btn"
                        >
                            Download
                        </Button>
                    </PDFDownloadLink>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const userApiAuth = createApi({
    reducerPath: 'userApiAuth',
    baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:8000/" }),
    endpoints: (builder) => ({
        userRegister: builder.mutation({
            query: (data) => ({
                url: 'auth/registration',
                method: 'POST',
                body: data
            }),
        }),
        userConfirm: builder.mutation({
            query: (data) => ({
                url: 'auth/confirm',
                method: 'POST',
                body: data
            }),
        }),
        getUser: builder.query({
            query: () => ({
                url: 'auth/get-user',
            })
        }),
        getCurrentUser: builder.query({
            query: (id) => ({
                url: 'auth/get-current-user',
                params: id
            })
        }),
        getCurrentUserWidthPhone: builder.query({
            query: (phone) => ({
                url: 'auth/get-current-user',
                params: phone
            })
        }),
        userLogin: builder.mutation({
            query: (data) => ({
                url: 'auth/login',
                method: 'POST',
                body: data
            }),
        })
    }),
});
// Export hooks generated from the API service
export const {
    useUserRegisterMutation,
    useUserLoginMutation,
    useUserConfirmMutation,
    useGetUserQuery,
    useGetCurrentUserQuery,
    useGetCurrentUserWidthPhoneQuery
} = userApiAuth;

import React from 'react';
import style from './ForWhomThisService.module.scss';
import image1 from '../../../assets/images/business-owners-about.png';
import image2 from '../../../assets/images/staff-about-us.png';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
function ForWhomThisService(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <section className={darkMode ? style.for_whom_this_service + " dark-section-bg light-text" : style.for_whom_this_service}>
            <div className="container">
                <div className="row">
                    <div className={style.for_whom_this_service_title} data-aos="fade-down" data-aos-duration="1500">{t("For whom this service?")}</div>
                    <div className={style.for_whom_this_service_items + " d-flex justify-content-center align-items-start gap-4 flex-wrap"}>
                        <div className={darkMode ? style.for_whom_this_service_item + " col-md-4 col-12 dark-block-bg light-text" : style.for_whom_this_service_item + " col-md-4 col-12"} data-aos="fade-right" data-aos-duration="1500">
                            <div className={style.for_whom_this_service_item_img}><img src={image1} alt="Business owners image"/></div>
                            <div className={style.for_whom_this_service_item_title}>{t("Business owners")}</div>
                            <div className={darkMode ? " section_description light-text" : "section_description"}>{t("You will be able to track the amount of incoming money, the frequency of debits, and evaluate the efficiency and productivity ofeach connected employee. For this purpose, the service has an \"Administrator\" role.")}</div>
                        </div>
                        <div className={darkMode ? style.for_whom_this_service_item + " col-md-4 col-12 dark-block-bg light-text" : style.for_whom_this_service_item + " col-md-4 col-12"} data-aos="fade-left" data-aos-duration="1500">
                            <div className={style.for_whom_this_service_item_img}><img src={image2} alt="Staff of the institution image"/></div>
                            <div className={style.for_whom_this_service_item_title}>{t("Staff of the institution")}</div>
                            <div className={darkMode ? " section_description light-text" : "section_description"}>{t("Accept tips from guests in seconds without installing an app or complicated registration. All the customer needs to do is scan your individual QR code and enter the amount. The money is transferred instantly.")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ForWhomThisService;
import React from 'react';
import SignInComponent from "../components/get-started-components/sign-in/SignInComponent";
import {useDispatch, useSelector} from "react-redux";
import SignUpComponent from "../components/get-started-components/sign-up/SignUpComponent";
import ForgotPasswordGetUser from "../components/get-started-components/forgot-password-get-user/ForgotPasswordGetUser";
import SendSMSComponent from "../components/get-started-components/send-sms/SendSMSComponent";
import ForgotPasswordInputs from "../components/get-started-components/forgot-password-inputs/ForgotPasswordInputs";

function GetStarted(props) {
    const {isRegistration, isConfirmCode, isLogin, isForgetPass, isForgetPassInputs} = useSelector(state => state.getStarted);
    const darkMode = useSelector((state) => state.settings.darkMode);
    return (
        <div className={darkMode ? "get-started bg-white dark-section-bg light-text" : "get-started bg-white"}>
            <div className="container">
                <div className="row">
                    {isLogin && <SignInComponent/>}
                    {isRegistration && <SignUpComponent/>}
                    {isConfirmCode && <SendSMSComponent/>}
                    {isForgetPass && <ForgotPasswordGetUser/>}
                    {isForgetPassInputs && <ForgotPasswordInputs/>}
                </div>
            </div>
        </div>
    );
}
export default GetStarted;
import React from 'react';
import style from "./ItIsConvenient.module.scss";
import clockImage from "../../assets/images/its-convenient-clock.png";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function ItIsConvenientItem({image, title, text, itemsBG}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return <div className={darkMode ? style.it_is_convenient_item + " dark-block-bg" : style.it_is_convenient_item} data-aos="zoom-in" data-aos-duration="1500" style={{backgroundColor: itemsBG}}>
            <div className={style.it_is_convenient_item_image}><img src={image} alt={title + " icon"}/></div>
            <div className={style.it_is_convenient_item_content}>
                <div className={darkMode ? " section_description fw-bold mb-2 light-text" : "section_description fw-bold mb-2"}>{t(title)}</div>
                <div className={darkMode ? " section_description light-text" : "section_description"}>{t(text)}</div>
            </div>
        </div>
}
import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function TransferIcon(props) {
    const location = useLocation()
    const currentURL2 = location.pathname.split("/")[2];
    const {darkMode} = useSelector((state) => state.settings);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 650 505"
            {...props}
            height={25}
        >
            <style type="text/css">
                {`.st0 { fill: ${currentURL2 === 'transfer' ? '#0666EB' : darkMode ? "#fff" : "000"}; stroke-width: 40`}
            </style>
            <g>
                <g>
                    <path className="st0" d="M309.8,509.9c-48.7,0-85.5-8-113.3-21.8c-32.6-16.2-52.5-42.5-59.2-78.3c-3.5-19.1-3.4-39.9,0.4-63.7    c7-43.8,23.8-85.6,49.8-124.2c21.4-31.8,45-54.6,72.1-69.7c43.2-24,86.9-22.8,129.9,3.6c24.1,14.8,45.4,36,65.1,64.9    c21.3,31.1,36.7,65.4,45.9,102.2c6.6,26.5,8.9,50.4,6.9,73c-3.7,42.8-24.1,73.8-60.6,92C428.1,497.3,371,509.9,309.8,509.9z     M319.6,488c42.4,0,100.9-11.8,117.4-20c29.6-14.8,45.4-39,48.4-74.1c1.7-20.1-0.3-41.6-6.4-65.7c-8.5-34.2-22.9-66.2-42.7-95    c-18.2-26.6-36.8-45.2-58.4-58.5c-36.2-22.2-71.3-23.2-107.5-3.1c-24.3,13.5-44.8,33.4-64.5,62.7c-24.2,35.9-39.7,74.7-46.2,115.3    c-3.5,21.5-3.6,39.3-0.5,56.1c5.4,29,20.8,49.4,47.2,62.5C230.9,480.4,261,488,319.6,488z"/>
                    <path className="st0" d="M278.8,120.7l-16.9-56.1c-3.2-10.6-6.4-21.1-9.5-31.7c-2.2-7.3-1.7-14.1,1.5-19.6c2.7-4.7,7.3-8.1,12.8-9.4    c5.5-1.3,12.3-0.5,17.7,2.2c11.4,5.6,23.1,11.8,37,19.5c11.6-6.3,23.8-12.7,37.4-19.8c7.1-3.7,15-3.9,21.5-0.4    c6.2,3.3,10.3,9.3,11.2,16.6c0.5,4-0.2,7.7-1,10.1c-7,23.3-14.1,46.5-21.2,69.8l-2,6.5c-0.5,1.7-1.3,2.9-1.7,3.5l-4.4,7.6    l-8.6-2.2c-21-6.4-41.7-6.3-63.3,0.2L278.8,120.7z M273.4,25.6c0.1,0.3,0.1,0.6,0.2,0.9c3.1,10.5,6.3,21.1,9.5,31.6l10.6,35.3    c18.4-4,36.9-4.1,55.1-0.1c6.9-22.6,13.8-45.1,20.6-67.7c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.5,0.2    c-14,7.3-26.4,13.8-37.9,20.1c-6.6,3.6-12.9,3.6-19.3,0.1C297.8,37.9,286,31.6,274.6,26C274.3,25.8,273.8,25.7,273.4,25.6"/>
                    <path className="st0" d="M321.4,432.5c-52,0-94.3-42.3-94.3-94.3c0-0.8,0-1.6,0.1-2.4l0-0.8l22.2,0.8l-0.1,1.5c0,0.3,0,0.6,0,0.9    c0,39.8,32.3,72.1,72.1,72.1c39.8,0,72.1-32.3,72.1-72.1c0-0.3,0-0.5,0-0.8c0-0.6-0.1-1.1-0.1-1.7l22.2-0.6l0,0.8    c0,0.8,0.1,1.6,0.1,2.4C415.7,390.2,373.4,432.5,321.4,432.5"/>
                </g>
                <path className="st0" d="M414.2,86.9h91.5v-52c0-10.5,6.3-20,16.1-24.1s20.9-1.8,28.4,5.6l78.1,78.1c4.9,4.9,7.6,11.5,7.6,18.4   s-2.8,13.5-7.6,18.4l-78.1,78.1c-7.5,7.5-18.6,9.7-28.4,5.6s-16.1-13.5-16.1-24.1v-52l-91.5,0.2c-14.4,0-26-11.6-26-26   S399.8,86.9,414.2,86.9z"/>
                <path className="st0" d="M224.6,86.9h-91.5v-52c0-10.5-6.3-20-16.1-24.1S96.1,9,88.6,16.4L10.5,94.5c-4.9,4.9-7.6,11.5-7.6,18.4   s2.8,13.5,7.6,18.4l78.1,78.1c7.5,7.5,18.6,9.7,28.4,5.6s16.1-13.5,16.1-24.1v-52l91.5,0.2c14.4,0,26-11.6,26-26   S239,86.9,224.6,86.9z"/>
            </g>
        </svg>
    );
}
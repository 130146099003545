import React from 'react';
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

export default function ProfileIcon(props) {
    const {fixedMenuTabValue, darkMode} = useSelector((state) => state.settings);
    const location = useLocation();
    const pathName = location.pathname;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 538.6 512"
            {...props}
            height={24}
        >
            <style type="text/css">
                {`.st10 { fill: ${fixedMenuTabValue === '1' && pathName === "/profile/edit" ? '#0666EB' : darkMode ? "#fff" : "000"} }
                  .st11 { fill: #FFFFFF; }
                  .st12 { fill: none; stroke: ${darkMode && pathName === "/profile/edit" ? "#fff" : "000"}; stroke-width: 15.9134; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 3; }`}
            </style>
            <path className="st10"
                  d="M309.9,130.6c0-43.4-35.2-78.6-78.6-78.6s-78.6,35.2-78.6,78.6s35.2,78.6,78.6,78.6S309.9,174,309.9,130.6z
                  M105.6,130.6c0-69.4,56.3-125.8,125.8-125.8s125.8,56.3,125.8,125.8s-56.3,125.8-125.8,125.8S105.6,200.1,105.6,130.6z
                  M59.7,460.7h343.4c-8.7-62.2-62.2-110-126.7-110h-89.8C122,350.7,68.6,398.5,59.7,460.7L59.7,460.7z
                  M11.3,478.7c0-96.8,78.4-175.2,175.2-175.2h89.8c96.8,0,175.2,78.4,175.2,175.2c0,16.1-13.1,29.2-29.2,29.2H40.5
                  C24.4,507.9,11.3,494.8,11.3,478.7z"/>
            <g>
                <circle className="st10" cx="433.5" cy="241.5" r="71.5"/>
                <circle className="st11" cx="433" cy="208.8" r="12.2"/>
                <polyline className="st12" points="418.9,239.1 435.3,239.1 435.3,277.3"/>
                <line className="st12" x1="447" y1="277.1" x2="418.5" y2="277.1"/>
            </g>
        </svg>
    );
}

import React, {useEffect, useState} from 'react';
import '../style/GetStarted.scss';
import 'react-international-phone/style.css';
import {useDispatch, useSelector} from "react-redux";
import {IsForgetPassInputs, IsLogin} from "../../../redux/slices/getStartedSlice";
import axios from "axios";
import config from '../../../config'
import {FormHelperText, IconButton, InputAdornment, OutlinedInput} from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
function ForgotPasswordInputs() {
    const [isShow, setIsShow] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    const [error, setError] = useState(false);
    const [notification, setNotification] = useState('');
    const {forgotUser} = useSelector(state => state.user);
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation()
    const navigate = useNavigate()
    const changePass = async () => {
        try {
            const data = {
                password: newPassword,
                id: forgotUser[0].id,
            }
            if(newPassword === newPasswordRepeat){
                await axios.post(`${config.BACKEND_URL}forgot-password`, data)
               navigate('/sign-in')
            }else {
                setError(true);
                setNotification("Password mismatch");
            }
        }catch (e){
            setError(true);
            setNotification(e.response?.data?.message);
        }
    }
    const passShow = () => {
        setIsShow(!isShow);
    }
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                changePass();
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [changePass]);

    return (
        <div className="get-started">
            <div className="get-started_title text-center col-md-5 col-12">{t("Add new password")}</div>
            <div className="get-started_form d-flex flex-column col-lg-3 col-md-6 col-sm-8 col-12">
                <div className={darkMode ? "dark-input" : ""}>
                    {/*<Form.Control*/}
                    {/*    type={!isShow ? "password" : "text"}*/}
                    {/*    placeholder="New password"*/}
                    {/*    onChange={(e) => setNewPassword(e.target.value)}*/}
                    {/*    value={newPassword}*/}
                    {/*    className="get-started_form_input"*/}
                    {/*/>*/}
                    <FormControl sx={{width: '100%'}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">{t("Password")}</InputLabel>
                        <OutlinedInput
                            // error={error && "error"}
                            id="outlined-adornment-password"
                            type={isShow ? 'text' : 'password'}
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={passShow}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShow ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t("Password")}
                        />
                    </FormControl>
                    {/*{!isShow ? <FaEye onClick={passShow} className="eye" fontSize={20}/> : <FaEyeSlash onClick={passShow} className="eye" fontSize={20}/>}*/}
                </div>
                <div className={darkMode ? "dark-input" : ""}>
                    {/*<Form.Control*/}
                    {/*    error={error && "error"}*/}
                    {/*    type={!isShow ? "password" : "text"}*/}
                    {/*    placeholder="Repeat new password"*/}
                    {/*    onChange={(e) => setNewPasswordRepeat(e.target.value)}*/}
                    {/*    value={newPasswordRepeat}*/}
                    {/*    className="get-started_form_input"*/}
                    {/*/>*/}
                    <FormControl sx={{width: '100%'}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">{t("Repeat new password")}</InputLabel>
                        <OutlinedInput
                            error={error && "error"}
                            id="outlined-adornment-password"
                            type={isShow ? 'text' : 'password'}
                            onChange={(e) => setNewPasswordRepeat(e.target.value)}
                            value={newPasswordRepeat}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={passShow}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShow ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t("Password")}
                        />
                    </FormControl>
                    {error && <FormHelperText error={error && "error"} id="outlined-weight-helper-text">{notification}</FormHelperText>}
                </div>
                {/*{error && <Notification message={notification} BGcolor="#D90012" isActive={error ? "active" : ""}/>}*/}
                <Button variant="primary send-button mb-0" onClick={changePass}>{t("Change")}</Button>
            </div>
        </div>
    );
}

export default ForgotPasswordInputs;
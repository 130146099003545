import React from 'react';
import ProfileHeader from "../components/profile-components/profile-header/ProfileHeader";
import EmployeeRatingContent from "../components/profile-components/company-companents/employee-rating-component/EmployeeRatingContent";

function EmployeeRating(props) {
    return (
        <section className="employee-rating pt-0">
            <div className="container">
                <ProfileHeader/>
                <EmployeeRatingContent/>
            </div>
        </section>
    );
}

export default EmployeeRating;
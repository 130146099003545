import React from 'react';
import EmployeeRatingContentData from "./employee-rating-body/EmployeeRatingContentData";
import EmployeeRatingsTable from "./employee-rating-body/EmployeeRatingsTable";

function EmployeeRatingContent(props) {
    return (
        <div className="d-flex">
            <EmployeeRatingContentData/>
            <EmployeeRatingsTable/>
        </div>
    );
}

export default EmployeeRatingContent;
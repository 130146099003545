import React from 'react';
import {useSelector} from "react-redux";
import style from './WhereToSetupAQRCode.module.scss';
import {useTranslation} from "react-i18next";

function WhereToSetupAqrCodeItem({image, text}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <div className={darkMode ? style.where_to_setup_qr_code_item + " dark-block-bg" : style.where_to_setup_qr_code_item} data-aos="zoom-in" data-aos-duration="1500">
            <div className={style.where_to_setup_qr_code_item_image}><img src={image} alt="set-up-qr-code"/></div>
            <div className={darkMode ? style.where_to_setup_qr_code_item_text + " dark-block-bg" : style.where_to_setup_qr_code_item_text}>{t(text)}</div>
        </div>
    );
}

export default WhereToSetupAqrCodeItem;
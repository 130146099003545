import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    fullName: "",
    email: "",
    subject: "",
    message: ""
}

const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        SendFullName: (state, action) => {
            state.fullName = action.payload;
        },
        SendEmail: (state, action) => {
            state.email = action.payload;
        },
        SendSubject: (state, action) => {
            state.subject = action.payload;
        },
        SendMessage: (state, action) => {
            state.message = action.payload;
        },
    }
})

export const {
    SendEmail,
    SendFullName,
    SendSubject,
    SendMessage
} = contactSlice.actions

export default contactSlice.reducer
import React, {useEffect} from 'react';
import Banner from "../components/banner/Banner";
import image from "../assets/images/how-to-start-get.png"
import ItIsConvenient from "../components/it-is-convenient/ItIsConvenient";
import HowMuchDoesItCost from "../components/how-much-it-cost/HowMuchDoesItCost";
import DoYouWantToAcceptTips from "../components/do-you-want-to-accept-tips/DoYouWantToAcceptTips";
import RegistrationQRPayment from "../components/start-page-components/RegistrationQRPayment";
import {useLocation} from "react-router-dom";
function Start(props) {
    const location = useLocation();
    useEffect(() => {
        document.title = "How To Start - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);
    return (
        <div className="start">
            <Banner bgc="#F0F2F9" image={image} title="How to begin accepting tips on the card" subtitle="Often guests don't have cash or small denomination bills. With our service you can accept tips from guests by card or via QR payment systems."/>
            <RegistrationQRPayment bgc="#F0F2F9"/>
            <ItIsConvenient bgc="#fff"/>
            <HowMuchDoesItCost bgc="#F0F2F9"/>
            <DoYouWantToAcceptTips bgc="#fff"/>
        </div>
    );
}

export default Start;
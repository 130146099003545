import React, {useEffect, useState} from 'react';
import '../style/GetStarted.scss';
import {PhoneInput} from "react-international-phone";
import 'react-international-phone/style.css';
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {IsLogin, IsRegistration} from "../../../redux/slices/getStartedSlice";
import AuthService from "../../../services/AuthService";
import {IsLoading} from "../../../redux/slices/userSlice";
import {
    Box,
    Checkbox,
    FormControlLabel, FormGroup,
    FormHelperText,
    IconButton,
    InputAdornment,
    OutlinedInput,
    TextField, Typography
} from "@mui/material";
import style from "../../profile-components/profile-edit/ProfileEdit.module.scss";
import InputLabel from "@mui/material/InputLabel";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function SignUpComponent(props) {
    const [isShow, setIsShow] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();
    const [isValidate, setValidate] = useState(false);
    const [email, setEmail] = useState('');
    const [isNotification, setIsNotification] = useState(false);
    const [error, setError] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [phoneError, setPhoneError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [personalData, setPersonalData] = useState(false);
    const [offer,setOffer] = useState(false);

    useEffect(() => {
        switch (error) {
            case `User with phone ${phone} already exists`:
                document.querySelector(".react-international-phone-input").style.borderColor = "#eb0606";
                setPhoneError(`User with phone ${phone} already exists`)
                setIsConfirmPasswordValid(false);
                setIsEmailValid(false);
                setIsPasswordValid(false);
                setIsNumberValid(true)
                break
            case 'Incorrect phone number':
                document.querySelector(".react-international-phone-input").style.borderColor = "#eb0606";
                setPhoneError('Incorrect phone number')
                setIsConfirmPasswordValid(false);
                setIsEmailValid(false);
                setIsPasswordValid(false);
                setIsNumberValid(true)
                break
            case `User with email ${email} already exists`:
                document.querySelector(".react-international-phone-input").style.borderColor = "#DCDCDC";
                setIsConfirmPasswordValid(false);
                setEmailError(`User with email ${email} already exists`)
                setIsEmailValid(true);
                setIsPasswordValid(false);
                break
            case "Incorrect email":
                document.querySelector(".react-international-phone-input").style.borderColor = "#DCDCDC";
                setIsConfirmPasswordValid(false);
                setEmailError("Incorrect email")
                setIsEmailValid(true);
                setIsPasswordValid(false);
                break
            case `User with phone ${phone} already exists,User with email ${email} already exists`:
                document.querySelector(".react-international-phone-input").style.borderColor = "#eb0606";
                setPhoneError(`User with phone ${phone} already exists`)
                setEmailError(`User with email ${email} already exists`)
                setIsConfirmPasswordValid(false);
                setIsEmailValid(true);
                setIsPasswordValid(false);
                setIsNumberValid(true)
                break
            case "Incorrect password":
                document.querySelector(".react-international-phone-input").style.borderColor = "#DCDCDC";
                setIsConfirmPasswordValid(true);
                setIsEmailValid(false);
                setIsPasswordValid(true);
                break
            case "Password mismatch":
                document.querySelector(".react-international-phone-input").style.borderColor = "#DCDCDC";
                setIsConfirmPasswordValid(true);
                setIsEmailValid(false);
                setIsPasswordValid(true);
                setPasswordError("Password mismatch")
                break
            case "Incorrect phone number,Incorrect email":
                document.querySelector(".react-international-phone-input").style.borderColor = "#eb0606";
                setIsConfirmPasswordValid(false);
                setIsNumberValid(true)
                setIsEmailValid(true);
                setPhoneError("Incorrect phone")
                setEmailError("Incorrect email")
                setIsPasswordValid(false);
                break
            case "Incorrect phone number,Incorrect email,Incorrect password":
                document.querySelector(".react-international-phone-input").style.borderColor = "#eb0606";
                setIsConfirmPasswordValid(false);
                setIsEmailValid(true);
                setIsPasswordValid(true);
                break
            case "Incorrect phone number,Incorrect password":
                document.querySelector(".react-international-phone-input").style.borderColor = "#eb0606";
                setIsConfirmPasswordValid(false);
                setIsEmailValid(false);
                setIsPasswordValid(true);
                break
            default:
                setIsNumberValid(false);
                setIsConfirmPasswordValid(false);
                setIsEmailValid(false);
                break
        }
    }, [error])
    const yesAccount = () => {
        dispatch(IsRegistration(false))
        dispatch(IsLogin(true))
    }
    const passShow = () => {
        setIsShow(!isShow);
    }
    useEffect(() => {
        if (password !== "" && confirmPassword!== "") {
            if (password === confirmPassword) {
                setValidate(true);
            } else {
                setValidate(false);
            }
        }
    }, [password, confirmPassword]);
    useEffect(() => {
        document.querySelector('.react-international-phone-country-selector-button').setAttribute('disabled', "disabled");
    }, []);
    const register = async () => {
        dispatch(IsLoading(true))
        try {
            if (!isValidate) {
                setIsNotification(true);
                setError('Password mismatch');
                return;
            }
            if(personalData && offer && password !== "" && confirmPassword !== ""){
                await AuthService.registration(phone, email ,password)
                // const id = response.data.userData.user.userID;
                // const session = response.data.session;
                // localStorage.setItem('user', id);
                // localStorage.setItem('session', session);
                setPhone('')
                setPassword('')
                setConfirmPassword('')
                setEmail('')
                navigate("/confirm")
            }

        }catch(error) {
            setIsNotification(true)
            setError(error.response?.data?.message)
        }finally {
            dispatch(IsLoading(false))
        }
    }
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                register();
            }
        };

        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [register]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value.replace(/\s/g, ''));  // Prevent spaces
    };

    const handlePhoneChange = (value) => {
        setPhone(value.replace(/\s/g, ''));  // Prevent spaces
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value.replace(/\s/g, ''));  // Prevent spaces
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value.replace(/\s/g, ''));  // Prevent spaces
    };



    return (
        <div className="get-started">
            <div className="get-started_title text-center col-lg-4 col-md-6 col-sm-8 col-12">{t("Register with")} <span style={{color: "#0666EB"}}>Tips</span>Si {t("to receive tips")}</div>
            <div className="get-started_form d-flex flex-column col-lg-3 col-md-6 col-sm-8 col-12">
                <div className={darkMode ? "dark-input" : ""}>
                    <PhoneInput
                        defaultCountry="es"
                        value={phone}
                        onChange={handlePhoneChange}
                        required={true}
                        hideDropdown
                        forceDialCode
                        disableFocusAfterCountrySelect={false}
                        disableCountryGuess
                    />
                    {isNumberValid && <FormHelperText error={isNumberValid && "error"} id="outlined-weight-helper-text">{phoneError}</FormHelperText>}
                </div>
                <div className={darkMode ? "get-started_form-email dark-input" : "get-started_form-password"}>
                    <Box component="form" noValidate autoComplete="on" sx={{ width: "100%", maxWidth: '100%' }}>
                        <TextField
                            error={isEmailValid && "error"}
                            id="outlined-basic"
                            type="email"
                            className={style.profile_content_edit_item}
                            label={t("E-mail")}
                            variant="outlined"
                            fullWidth
                            value={email}
                            name="email"  // Добавьте этот атрибут
                            autoComplete="email"
                            onChange={(e) => handleEmailChange(e)}
                        />

                        {isEmailValid && <FormHelperText error={isEmailValid && "error"} id="outlined-weight-helper-text">{emailError}</FormHelperText>}
                    </Box>
                </div>
                <div className={darkMode ? "get-started_form-password dark-input" : "get-started_form-password"}>
                    <FormControl sx={{width: '100%'}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">{t("Password")}</InputLabel>
                        <OutlinedInput
                            error={isPasswordValid && "error"}
                            id="outlined-adornment-password"
                            type={isShow ? 'text' : 'password'}
                            onChange={(e) => handlePasswordChange(e)}
                            value={password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={passShow}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShow ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t("Password")}
                        />
                        <FormHelperText id="outlined-weight-helper-text" error={isPasswordValid && "error"}>{t("The password length must be 8 to 16 characters")}</FormHelperText>
                    </FormControl>
                </div>
                <div className={darkMode ? "get-started_form-password dark-input" : "get-started_form-password"}>
                    <FormControl sx={{width: '100%'}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-confirmPassword">{t("Confirm the password")}</InputLabel>
                        <OutlinedInput
                            error={isConfirmPasswordValid && "error"}
                            id="outlined-adornment-confirmPassword"
                            type={isShow ? 'text' : 'password'}
                            onChange={(e) => handleConfirmPasswordChange(e)}
                            value={confirmPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={passShow}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShow ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t("Confirm the password")}
                        />
                        {isPasswordValid && <FormHelperText id="outlined-weight-helper-text" error={isPasswordValid && "error"}>{passwordError}</FormHelperText>}
                    </FormControl>
                </div>
                <FormGroup>
                    <FormControlLabel
                        className={darkMode ? "get-started_form-check dark-input mb-3" : "get-started_form-check mb-3"}
                        required
                        control={<Checkbox onChange={() => setPersonalData(!personalData)} checked={personalData}/>}
                        label={
                            <Typography>
                                {t("I have read the Consent to the processing of personal data and the tipssi.net ")}
                                <Link to='/privacy-policy' style={{color: "#0666EB", fontWeight: 700, textTransform: "capitalize"}}>
                                    {t("policy ")}
                                </Link>
                                {t("regarding the processing of personal data. Marking a “✓” signifies my written acceptance of the terms of these documents and my agreement to abide by them.")}
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        className={darkMode ? "get-started_form-check dark-input" : "get-started_form-check"}
                        required
                        control={<Checkbox onChange={() => setOffer(!offer)} checked={offer}/>}
                        label={
                            <Typography>
                                {t("I have read the ")}
                                <Link to='/terms-and-conditions' style={{color: "#0666EB", fontWeight: 700}}>
                                    {t("Offer Agreement ")}
                                </Link>
                                {t(". Marking “✓” means my written agreement with the terms of the contract and my agreement to comply with them.")}
                            </Typography>
                        }
                    />
                </FormGroup>
                <Button
                    variant="contained"
                    className={!offer || !personalData ? "send-button disabled" : "send-button"}
                    // onClick={register}
                    disabled={!offer || !personalData} // Использование логического OR для определения disabled состояния
                >
                    {t("Register")}
                </Button>
                <div className="dont-have-account">{t("Already registered?")}&nbsp;&nbsp;<Link to="/sign-in" variant="text" onClick={yesAccount} style={{fontWeight: 700, color:"#0666EB", textTransform: "uppercase"}}>{t("Sign in")}</Link></div>
            </div>
        </div>
    );
}

export default SignUpComponent;
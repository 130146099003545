import React from 'react';
import style from './JoinOurService.module.scss'
import CustomLink from "../../CustomLink";
import {useTranslation} from "react-i18next";
function JoinOurService(props) {
    const {t} = useTranslation();
    return (
        <section className={style.join_our_service}>
            <div className="container">
                <div className="row">
                    <div className="col-12" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="section_small_title text-center mb-5">{t("Join Our Service")}</div>
                        <div className="small_title text-center mb-5">{t("Sign up with TipsSi to receive tips")}</div>
                        <CustomLink className={style.btn} link="sign-in" text="Get Started" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default JoinOurService;
import React from 'react';
import ProfileQR from "../profile-qr/ProfileQR";
import style from "./ProfileContent.module.scss";
import ProfileTransactions from "../profile-transactions/ProfileTransactions";
function ProfileContent() {
    return (
        <div className={style.profile_content + " flex-lg-row flex-md-column flex-column"}>
            <ProfileQR/>
            <ProfileTransactions/>
        </div>
    );
}

export default ProfileContent;
import React from 'react';
import style from "./OurVisionAndMission.module.scss";
import image from "../../../assets/images/check.png";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function OurVisionAndMissionItem({text}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <div className={darkMode ? style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12 dark-block-bg light-text" : style.our_vision_and_mission_item + " col-sm-6 col-lg-5 col-12"} data-aos="zoom-in" data-aos-duration="1500">
            <div className={style.our_vision_and_mission_item_img + " col-lg-1 col-2 d-flex justify-center"}><img src={image} alt="Check Circle"/></div>
            <div className="col-lg-11 col-10">{t(text)}</div>
        </div>
    );
}
import * as React from 'react';
import dayjs from 'dayjs';
import "dayjs/locale/en-gb";
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from "react";
import style from "./TransactionsContent.module.scss";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import averageIcon from "../../../assets/images/average-rating.png";
import { useDispatch, useSelector } from "react-redux";
import PaymentService from "../../../services/PaymentService";
import TransactionItem from "./transaction-item/TransactionItem";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import PropTypes from "prop-types";
import Pagination from "./Pagination";
import { IsLoading } from "../../../redux/slices/userSlice";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useTranslation} from "react-i18next";

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function TransactionsContent(props) {
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());
    const { user } = useSelector(state => state.user);
    const { id } = user || {};
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [value, setValue] = useState('1');
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation()
    const date = new Date();
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    const today = date.toLocaleDateString('en-US', options);
    const month = date.toLocaleDateString('en-US', options).split(" ")[0].replace(/,/g, ' ');
    const startOfWeek = dayjs().startOf('week');
    const endOfWeek = dayjs().endOf('week');

    const formatDateToday = (date) => {
        return date.toLocaleDateString('en-US', options);
    };
    const isSameWeek = (date1, date2) => {
        return dayjs(date1).isSame(date2, 'week');
    };
    const filterByTipToday = transactions.filter((t) => {
        return t.transactionType === "tip" && formatDateToday(new Date(t.transactionDate)) === today && t.transactionUser === id;
    });
    const filterByTransferToday = transactions.filter((t) => {
        return t.transactionType === "transfer" && formatDateToday(new Date(t.transactionDate)) === today && t.transactionUser === id;
    });
    const filterByTipWeek = transactions.filter((t) => {
        return t.transactionType === "tip" && isSameWeek(new Date(t.transactionDate), date) && t.transactionUser === id;
    });
    const filterByTransferWeek = transactions.filter((t) => {
        return t.transactionType === "transfer" && isSameWeek(new Date(t.transactionDate), date) && t.transactionUser === id;
    });
    const filterByTipMonth = transactions.filter((t) => {
        return t.transactionType === "tip" && formatDateToday(new Date(t.transactionDate)).split(" ")[0].replace(/,/g, ' ') === month && t.transactionUser === id;
    });
    const filterByTransferMonth = transactions.filter((t) => {
        return t.transactionType === "transfer" && formatDateToday(new Date(t.transactionDate)).split(" ")[0].replace(/,/g, ' ') === month && t.transactionUser === id;
    });
    const filterByDateRange = transactions.filter((t) => {
        const transactionDate = dayjs(t.transactionDate);
        return transactionDate.isAfter(fromDate) - 1 && transactionDate.isBefore(toDate) && t.transactionUser === id;
    });
    const filterByAll = transactions.filter((t) => t.transactionUser === id);
    const [alignment, setAlignment] = useState('all');
    const handleFilterChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactions.length) : 0;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getTransactions = async () => {
        dispatch(IsLoading(true));
        try {
            if (id) {
                const response = await PaymentService.getTransaction();
                setTransactions(response.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(IsLoading(false));
        }
    };
    useEffect(() => {
        getTransactions();
    }, [id]);
    const filteredTransactions = () => {
        let filtered = [];

        if (value === '1') {
            filtered = alignment === 'tip' ? filterByTipToday : alignment === 'transfer' ? filterByTransferToday : filterByAll;
        } else if (value === '2') {
            filtered = alignment === 'tip' ? filterByTipWeek : alignment === 'transfer' ? filterByTransferWeek : filterByAll;
        } else if (value === '3') {
            filtered = alignment === 'tip' ? filterByTipMonth : alignment === 'transfer' ? filterByTransferMonth : filterByAll;
        }

        return filtered.filter(t => {
            const transactionDate = dayjs(t.transactionDate);
            return transactionDate.isAfter(fromDate) - 1 && transactionDate.isBefore(toDate);
        });
    };

    return (
        <div className="d-flex align-items-start flex-lg-row flex-md-column flex-column">
            <div className={darkMode ? style.calendar + " col-lg-4 col-md-12 col-sm-12 col-12 bg-white dark-block-bg dark-input" : style.calendar + " col-sm-12 col-md-6 col-lg-4 col-12 bg-white"}>
                <div className={style.calendar_title}>{t("Date")}</div>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb" className={darkMode && "dark-input"}>
                    <DemoContainer components={['DatePicker']} >
                        <DatePicker
                            label={t("Date From")}
                            value={fromDate}
                            onChange={(newValue) => setFromDate(newValue)}
                        />
                        <DatePicker
                            label={t("Date To")}
                            value={toDate}
                            onChange={(newValue) => setToDate(newValue)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            className="transactions-filter"
                        >
                            <Tab label={t("Today")} value="1" />
                            <Tab label={t("Week")} value="2" />
                            <Tab label={t("Month")} value="3" />
                        </TabList>
                    </TabContext>
                </Box>
                <div className={`${style.calendar_title} mt-5`}>{t("Type")}</div>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleFilterChange}
                    aria-label="Filter"
                >
                    <ToggleButton value="tip">Tip</ToggleButton>
                    <ToggleButton value="transfer">{t("Transfer")}</ToggleButton>
                    <ToggleButton value="all">{t("All")}</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className={style.profile_transactions + " col-lg-8 col-md-12 col-sm-12 col-12"}>
                <div className={darkMode ? style.profile_transactions_content + " dark-block-bg light-text" : style.profile_transactions_content}>
                    <div className={style.profile_transactions_content_title}>{t("Transactions")}</div>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{minWidth: 650}} aria-label="custom pagination table"
                            className={darkMode && "dark-block-bg light-text"}
                        >
                            <TableHead>
                                <TableRow style={{backgroundColor: darkMode && "#373737"}}>
                                    <TableCell className={darkMode && "light-text"}>{t("Type")}</TableCell>
                                    <TableCell className={darkMode && "light-text"}>{t("Date")}</TableCell>
                                    {/*<TableCell className={darkMode && "light-text"}>{t("Time")}</TableCell>*/}
                                    <TableCell className={darkMode && "light-text"}>{t("Amount")}</TableCell>
                                    <TableCell className={darkMode && "light-text"}>{t("Rating")}</TableCell>
                                    {/*<TableCell className={darkMode && "light-text"}>{t("Comment")}</TableCell>*/}
                                    <TableCell className={darkMode && "light-text"}>{t("Survey")}</TableCell>
                                    {/*<TableCell className={darkMode && "light-text"}>{t("Status")}</TableCell>*/}
                                    {/*<TableCell className={darkMode && "light-text"}>{t("Action")}</TableCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                        ? filteredTransactions().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : filteredTransactions()
                                ).map((transaction) => (
                                    <TransactionItem
                                        key={transaction.id}
                                        amount={transaction.transactionAmount}
                                        date={transaction.transactionDate}
                                        time={transaction.transactionTime}
                                        status={transaction.transactionStatus}
                                        type={transaction.transactionType}
                                        rating={transaction.transactionRating}
                                        comment={transaction.transactionComment}
                                        goodDrink={transaction.goodDrink}
                                        cleanPremises={transaction.cleanPremises}
                                        goodFood={transaction.goodFood}
                                        goodMusic={transaction.goodMusic}
                                        goodService={transaction.goodService}
                                        likeEuro={transaction.likeEuro}
                                    />
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 53 * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                                        colSpan={7}
                                        count={filteredTransactions().length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={Pagination}
                                        style={{color: darkMode && "#fff", borderBottom: 0}}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
}

export default TransactionsContent;

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/globalStyles.scss'
import Home from "./pages/Home";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useState } from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import URLParse from 'url-parse';
import config from "./config";
import About from "./pages/About";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Start from "./pages/Start";
import Staff from "./pages/Staff";
import Business from "./pages/Business";
import GetStarted from "./pages/GetStarted";
import Profile from "./pages/Profile";
import Payment from "./pages/Payment";
import Transactions from "./pages/Transactions";
import ProfileEdit from "./pages/ProfileEdit";
import ThankYou from "./pages/ThankYou";
import Transfer from "./pages/Transfer";
import Print from "./pages/Print";
import EmployeeRatings from "./pages/EmployeeRatings";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ContactUS from "./pages/ContactUS";
import PaymentFailed from "./pages/PaymentFailed";
import SelectAvatarModal from "./components/modal/SelectAvatarModal";
import SelectCompanyLogoModal from "./components/modal/SelectCompanyLogoModal";

import UserService from "./services/UserService";

import {
    addUser,
    Auth,
    BirthDate,
    City,
    CompanyName,
    Country,
    Email,
    EmailNotification,
    FirstName,
    ImagePath,
    IsLoading,
    IsReview,
    LastName,
    Profession,
    refreshAddUser,
    Region,
    AvatarModal,
    CompanyLogoModal,
    StreetLine1,
    StreetLine2,
    ThankYouPhrase,
    UserFacebook,
    UserInstagram,
    UserLinkedin,
    UserName,
    UserRating,
    UserTikTok,
    UserWhatsapp,
    UserYoutube,
    WelcomePhrase,
    CompanyImagePath,
    UserPhone,
    UserVerification,
    UserTotalAmount,
    UserCollected,
    IsRating,
    IsGoodDrinksPaymentPage,
    IsCleanPremisesPaymentPage,
    IsGoodFoodPaymentPage, IsGoodMusicPaymentPage, IsGoodServicePaymentPage, IsLikeEuroPaymentPage, UserMinimumAmount
} from "./redux/slices/userSlice";

import {DarkMode, FixedMenuTabValue} from "./redux/slices/accountSettingsSlice";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import SendSMS from "./pages/SendSMS";
import ForgotPasswordGetUser from "./components/get-started-components/forgot-password-get-user/ForgotPasswordGetUser";
import ForgotPasswordInputs from "./components/get-started-components/forgot-password-inputs/ForgotPasswordInputs";
import PdfViewer from "./pages/PDFViewerPage";
import Error404 from "./pages/Error404";
import TipssiQR from "./pages/TipssiQR";
import { Box, IconButton} from "@mui/material";
import { IoSettingsOutline } from "react-icons/io5";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { GoKey } from "react-icons/go";
import { isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";
import { TbArrowsRightLeft } from "react-icons/tb";
import TransferIcon from "./components/profile-components/TransferIcon";
import ProfileIcon from "./components/profile-components/ProfileIcon";

function App() {
    const dispatch = useDispatch();
    const { user, auth, imagePath, avatarModal, companyLogoModal, companyImagePath } = useSelector(state => state.user);
    const {darkMode, fixedMenuTabValue} = useSelector((state) => state.settings);
    const location = useLocation()
    const currentURL = location.pathname.split("/")[1];
    const currentURL2 = location.pathname.split("/")[2];
    const mode = JSON.parse(localStorage.getItem('darkMode'));
    const id = localStorage.getItem('user');
    const session = localStorage.getItem('session')
    const navigate = useNavigate()
    const {t} = useTranslation()
    const pathName = location.pathname;
    const handleChange = (value) => {
        dispatch(FixedMenuTabValue(value))
        navigate("profile/edit")
    }
    const handleTransaction = () => {
        dispatch(FixedMenuTabValue("0"))
        navigate("profile/transactions")
    }
    const handleTransfer = () => {
        dispatch(FixedMenuTabValue("0"))
        navigate("profile/transfer")
    }
    const checkAuth = async () => {
        try {
            const response = await axios.post(`${config.BACKEND_URL}refresh`, { id: session }, { withCredentials: true });
            // Проверяем, есть ли данные в ответе и их длина больше 0
            if(response.data.length > 0){
                dispatch(Auth(true))
            }
        } catch (e) {
            localStorage.removeItem("user");
            localStorage.removeItem("session");
            dispatch(Auth(false))
            if(window.location.href !== config.FRONTEND_URL + "/" && !auth){
                window.location.replace("/")
            }
            console.error(e.response?.data?.message);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkAuth();
        }, 3 * 60 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const fetchUser = async () => {
        dispatch(IsLoading(true));
        try {
            const response = await UserService.fetchUser(id);
            dispatch(addUser(response.data[0]));
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(IsLoading(false));
        }
    };

    const handleAvatarModalClose = () => {
        dispatch(AvatarModal(false));
    };

    const handleCompanyLogoModalClose = () => {
        dispatch(CompanyLogoModal(false));
    };

    useEffect(() => {
        AOS.init({
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,
            disableMutationObserver: false,
            debounceDelay: 50,
            throttleDelay: 99,
            offset: 120,
            delay: 0,
            duration: 400,
            easing: 'ease',
            once: false,
            mirror: false,
            anchorPlacement: 'top-bottom',
        });
    }, []);

    useEffect(() => {
        if (id || imagePath || companyImagePath) {
            fetchUser();
            dispatch(Auth(true));
        }
    }, [imagePath, companyImagePath]);

    useEffect(() => {
        if (user) {
            const parsedUrl = new URLParse(user.userWhatsapp, false);
            const whatsapp = parsedUrl.pathname.split("/")[1];
            dispatch(ImagePath(user.userImageName));
            dispatch(CompanyImagePath(user.userCompanyLogo));
            dispatch(FirstName(user.userFirstName));
            dispatch(LastName(user.userLastName));
            dispatch(Email(user.userEmail));
            dispatch(City(user.userCity));
            dispatch(Country(user.userCountry || "Spain"));
            dispatch(BirthDate(user.userBirthDate));
            dispatch(UserName(user.userName));
            dispatch(Profession(user.userProfession));
            dispatch(CompanyName(user.userCompanyName));
            dispatch(Region(user.userRegion));
            dispatch(StreetLine1(user.userStreetLine_1));
            dispatch(StreetLine2(user.userStreetLine_2));
            dispatch(WelcomePhrase(user.userWelcomePhrase));
            dispatch(ThankYouPhrase(user.userThankYouPhrase));
            dispatch(UserFacebook(user.userFacebook));
            dispatch(UserInstagram(user.userInstagram));
            dispatch(UserWhatsapp(whatsapp));
            dispatch(UserLinkedin(user.userLinkedin));
            dispatch(UserTikTok(user.userTikTok));
            dispatch(UserYoutube(user.userYoutube));
            dispatch(UserRating(user.userRating));
            dispatch(UserPhone(user.userPhone));
            dispatch(UserVerification(user.userVerified));
            dispatch(EmailNotification(user.userIsEmailNotification === 1));
            dispatch(IsReview(user.userIsReview === 1));
            dispatch(IsRating(user.userIsRating === 1));
            dispatch(DarkMode(mode));
            dispatch(UserTotalAmount(user.totalAmount));
            dispatch(UserCollected(user.collected));
            dispatch(IsGoodDrinksPaymentPage(user.good_drinks));
            dispatch(IsCleanPremisesPaymentPage(user.clean_premises));
            dispatch(IsGoodFoodPaymentPage(user.good_food));
            dispatch(IsGoodMusicPaymentPage(user.good_music));
            dispatch(IsGoodServicePaymentPage(user.good_service));
            dispatch(IsLikeEuroPaymentPage(user.like_euro));
            dispatch(UserMinimumAmount(user.minAmount));
            // dispatch(WithdrawalAmount(user.minAmount));
            // dispatch(Amount(user.minAmount));
        }
    }, [user, id]);

    useEffect(() => {
        if(currentURL === "payment" && currentURL === "thankyou"){
            localStorage.setItem("darkMode", "false")
        }else {
            localStorage.setItem("darkMode", darkMode)
        }
    }, [currentURL]);
    console.log(currentURL)
    return (
        <div className="app" style={{backgroundColor: !auth && !darkMode ? "#fff" : ""}}>
            {isMobile && currentURL === "profile" && (
                <Box sx={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    backgroundColor: darkMode ? "#151515" : "#F0F2F9",
                    boxShadow: "0px 0px 8px 0px rgba(166,166,166,1)",
                    zIndex: 10,
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: darkMode ? "#fff" : "#000",
                    padding: "0 15px",
                    fontSize: "24px",
                }}>
                    <IconButton
                        onClick={(e) => handleChange("1")}
                        color="inherit"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "inherit",
                            color: (fixedMenuTabValue === "1" && pathName === "/profile/edit") ? "#0666EB" : "inherit"
                        }}
                    >
                        <ProfileIcon fontSize="inherit"/>
                        <span style={{fontSize: "14px"}}>{t("Profile")}</span>
                    </IconButton>
                    <IconButton
                        onClick={handleTransaction}
                        color="inherit"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "inherit",
                            color: currentURL2 === "transactions" && "#0666EB"
                        }}
                    >
                        <TbArrowsRightLeft fontSize="inherit"/>
                        <span style={{fontSize: "14px"}}>{t("Transactions")}</span>
                    </IconButton>
                    <IconButton
                        onClick={handleTransfer}
                        color="inherit"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "inherit",
                            color: currentURL2 === "transfer" && "#0666EB"
                        }}
                    >
                        <TransferIcon />
                        <span style={{fontSize: "14px"}}>{t("Transfer")}</span>
                    </IconButton>
                    <IconButton
                        onClick={(e) => handleChange("3")}
                        color="inherit"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "inherit",
                            color: (fixedMenuTabValue === "3" && pathName === "/profile/edit") ? "#0666EB" : "inherit"
                        }}
                    >
                        <IoSettingsOutline fontSize="inherit" strokeWidth={50}/>
                        <span style={{fontSize: "14px"}}>{t("Settings")}</span>
                    </IconButton>
                    <IconButton
                        onClick={(e) => handleChange("2")}
                        color="inherit"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "inherit",
                            color: (fixedMenuTabValue === "2" && pathName === "/profile/edit") ? "#0666EB" : "inherit"
                        }}
                    >
                        <GoKey fontSize="inherit" />
                        <span style={{fontSize: "14px"}}>{t("Accesses")}</span>
                    </IconButton>
                </Box>
            )}
            <SelectAvatarModal
                show={avatarModal}
                onHide={handleAvatarModalClose}
                backdrop="static"
                id={user?.id}
            />
            <SelectCompanyLogoModal
                show={companyLogoModal}
                onHide={handleCompanyLogoModalClose}
                backdrop="static"
                id={user?.id}
            />
            {currentURL !== "payment" && currentURL !== "thankyou" && currentURL2 !== "pdf" && <Header/>}
            <div className={
                    darkMode
                        ? "main dark-section-bg light-text"
                        : currentURL === "payment"
                            ? "main pt-0"
                            : currentURL2 === "pdf"
                                ? "main pt-0"
                                : "main"
                }
                style={{backgroundColor: currentURL === "profile" || currentURL === "payment" && !darkMode ? "#F0F2F9" : "#fff", paddingBottom: isMobile && currentURL === "profile" ? "62px" : "0"}}
            >
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/start" element={<Start/>}/>
                    <Route path="/staff" element={<Staff/>}/>
                    <Route path="/business" element={<Business/>}/>
                    <Route path="/sign-up" element={<SignUp/>}/>
                    <Route path="/sign-in" element={<SignIn/>} />
                    <Route path="/confirm" element={<SendSMS/>}/>
                    <Route path="/forgot" element={<ForgotPasswordGetUser/>}/>
                    <Route path="/forgot/change" element={<ForgotPasswordInputs/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/profile/edit" element={<ProfileEdit/>}/>
                    <Route path="/profile/transactions" element={<Transactions/>}/>
                    <Route path="/profile/transfer" element={<Transfer/>}/>
                    <Route path="/profile/print" element={<Print/>}/>
                    <Route path="/contact-us" element={<ContactUS/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                    <Route path="/thankyou/:userId/:rating/:comment/:goodDrink/:cleanPremises/:goodFood/:goodMusic/:goodService/:likeEuro" element={<ThankYou/>}/>
                    <Route path="/payment-failed" element={<PaymentFailed/>}/>
                    <Route path="/company/ratings" element={<EmployeeRatings/>}/>
                    <Route path="/payment/:id" element={<Payment/>}/>
                    <Route path="/qr" element={<TipssiQR/>}/>
                    <Route path="/profile/pdf/:accountId/:phone/:filename" element={<PdfViewer />} />
                    <Route path="*" element={<Error404 />} />
                </Routes>
            </div>
            {currentURL2 !== "pdf" && currentURL !== "profile" && currentURL !== "payment" && currentURL !== "thankyou" && <Footer/>}
        </div>
    );
}

export default App;

import React from 'react';
import style from "./WhyYouShouldUseOurService.module.scss";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function WhyYouShouldUseOurServiceItem({image, text}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    return (
        <div className={style.why_you_should_use_our_service_item_block + " col-sm-6 col-md-3 col-12"} data-aos="zoom-in" data-aos-duration="1500">
            <div className={darkMode ? style.why_you_should_use_our_service_item + " dark-block-bg light-text" : style.why_you_should_use_our_service_item}>
                <div className={style.why_you_should_use_our_service_item_img}><img src={image} alt="Check Circle"/></div>
                <div className={style.why_you_should_use_our_service_item_text}>{t(text)}</div>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import * as monei from '@monei-js/components';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../config"
import { IsLoading } from "../../../redux/slices/userSlice";
import { useLocation } from "react-router-dom";
import style from "./Payment.module.scss";
import {Box} from "@mui/material";
import {PaymentStatusModalOpen} from "../../../redux/slices/paymentSlice";
import {LoadingButton} from "@mui/lab";
const Payment = ({goodDrinkCount, cleanPremisesCount, goodFoodCount, goodMusicCount, goodServiceCount, likeEuroCount, comment}) => {
    const [payID, setPayID] = useState("");
    const { email, rating, amount } = useSelector(state => state.currency);
    const dispatch = useDispatch();
    const location = useLocation();
    const userId = location.pathname.split("/")[2];
    const [isCardPayment, setIsCardPayment] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cardInput, setCardInput] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const getPayment = async () => {
        dispatch(IsLoading(true));
        const data = {
            amount,
            email,
            userId,
            rating,
            comment,
            goodDrinkCount,
            cleanPremisesCount,
            goodFoodCount,
            goodMusicCount,
            goodServiceCount,
            likeEuroCount
        };

        try {
            const result = await axios.post(`${config.BACKEND_URL}payment`, { data });
            if (result && result.data && result.data.id && result.data.status) {
                setPayID(result.data.id);
                setPaymentMethods(true)
                setLoading(false)
            }
        } catch (e) {
            console.error('Ошибка при выполнении запроса на сервер:', e);
        } finally {
            dispatch(IsLoading(false));
        }
    };

    // const handeShowPaymentMethods = () => {
    //     if(rating > 0 && amount > 0){
    //         setLoading(true)
    //         getPayment()
    //     }
    //     // else {
    //     //     alert("¡Se requiere calificación!")
    //     // }
    // }
    // Google Pay and Apple Pay
    // useEffect(() => {
    //     getPayment();
    // }, [amount, rating, comment]);

    useEffect(() => {
        const paymentRequest = monei.PaymentRequest({
            paymentId: payID,
            onSubmit(result) {
                handlePaymentToken(result.token);
            },
            onError(error) {
                console.error('Ошибка при запросе оплаты:', error);
            }
        });

        const renderPaymentButton = async () => {
            dispatch(IsLoading(true));
            try {
                const container = document.querySelector('#payment_request_container');
                if (container) {
                    container.innerHTML = '';
                }
                await paymentRequest.render(container);
            } catch (error) {
                console.error('Ошибка при отображении запроса оплаты:', error);
            } finally {
                dispatch(IsLoading(false));
            }
        };

        renderPaymentButton();

        // return () => {
        //     // Cleanup function to destroy payment request on component unmount
        //     if (paymentRequest && typeof paymentRequest.destroy === 'function') {
        //         paymentRequest.destroy();
        //     }
        // };
    }, [payID, amount]);
    const handlePaymentToken = (token) => {
        if (!payID) {
            console.error('ID платежа отсутствует.');
            return;
        }
        monei.confirmPayment({ paymentId: payID, paymentToken: token })
            .then(result => {
                const completeURL = result.nextAction.redirectUrl;
                if (completeURL) {
                    window.location.href = completeURL;
                }
            })
            .catch(error => {
                console.error('Ошибка подтверждения оплаты:', error);
                dispatch(PaymentStatusModalOpen(true));
                setPaymentMethods(false)
            });
    };
    // Google Pay and Apple Pay

    // Card Pay
    // useEffect(() => {
    //     const cardContainer = document.querySelector('#card-input');
    //     const errorText = document.querySelector('#card-error');
    //     if(payID) {
    //         const cardInputInstance = monei.CardInput({
    //             paymentId: payID,
    //             onChange: function (event) {
    //                 // Handle real-time validation errors.
    //                 if (event.isTouched && event.error) {
    //                     cardContainer.classList.add('is-invalid');
    //                     if(event.error === "CVC недействителен"){
    //                         setError("CVC is invalid");
    //                     }else if(event.error === "Год истечения срока действия не может быть в прошлом"){
    //                         setError("The expiration year cannot be in the past");
    //                     }else {
    //                         setError("Invalid card number");
    //                     }
    //                 } else {
    //                     cardContainer.classList.remove('is-invalid');
    //                     setError('');
    //                 }
    //             }
    //         });
    //         if(cardContainer.childNodes.length >= 1) {
    //             cardContainer.innerHTML = ""
    //             setIsCardPayment(false)
    //         }
    //         cardInputInstance.render(cardContainer);
    //         setCardInput(cardInputInstance);
    //         return () => {
    //             if (cardInputInstance && typeof cardInputInstance.destroy === 'function') {
    //                 cardInputInstance.destroy();
    //             }
    //         };
    //     }
    // }, [payID, sendAmount]);
    // const handleCardPaymentToken = (token) => {
    //     monei.confirmPayment({
    //         paymentId: payID,
    //         paymentToken: token,
    //     })
    //         .then(result => {
    //             if(result.status === "FAILED"){
    //                 sendTransaction(result.status)
    //                 alert("Payment error, please try again or change card")
    //             }else {
    //                 sendTransaction(result.status)
    //                 window.location.href = result.nextAction.redirectUrl;
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // };
    // const handleCardSubmit = async (event) => {
    //     event.preventDefault();
    //     setIsSubmitting(true);
    //     try {
    //         const result = await monei.createToken(cardInput);
    //         if (result.error) {
    //             // Inform the user if there was an error.
    //             document.getElementById('card-input').classList.add('is-invalid');
    //             if(result.error === "CVC недействителен"){
    //                 setError("CVC is invalid");
    //             }else if(result.error === "Год истечения срока действия не может быть в прошлом"){
    //                 setError("The expiration year cannot be in the past");
    //             }else {
    //                 setError("Invalid card number");
    //             }
    //         } else {
    //             // Send the token to MONEI.
    //             handleCardPaymentToken(result.token);
    //         }
    //     } catch (error) {
    //         setError(error.message);
    //     } finally {
    //         setIsSubmitting(false);
    //     }
    // };
    // const showCardPayment = () => {
    //     setIsCardPayment(!isCardPayment);
    // }
    // Card

    return (
        <div className={style.payment_methods}>
            {!paymentMethods && (
                <Box className={style.card}>
                    <LoadingButton
                        variant="contained"
                        onClick={getPayment}
                        fullWidth
                        loading={loading}
                        sx={{backgroundColor: "#0068F4", color: "#fff"}}
                    >
                        Proceder al pago
                    </LoadingButton>
                </Box>
            )}

            {paymentMethods && (
                <>
                    <div id="payment_request_container" className={style.universal_payment}></div>
                    {/*<Box className={style.card}><Button variant="contained" fullWidth onClick={showCardPayment}>Card Payment</Button></Box>*/}
                    {/*<div id="payment-form" className={isCardPayment ? style.card_content + " " + style.show : style.card_content}>*/}
                    {/*    <div className="card-field">*/}
                    {/*        <div id="card-input"></div>*/}
                    {/*    </div>*/}
                    {/*    <div id="card-error">{error}</div>*/}
                    {/*    <Box sx={{color: '#fff'}} className={style.card}>*/}
                    {/*        <Button variant="contained" onClick={handleCardSubmit} fullWidth>*/}
                    {/*            {isSubmitting ? <CircularProgress color="inherit"/> : "Pay"}*/}
                    {/*        </Button>*/}
                    {/*    </Box>*/}
                    {/*</div>*/}
                </>
            )}
        </div>

    )
};

export default Payment;
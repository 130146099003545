import React from 'react';
import style from "./Privacy.module.scss";
import {useSelector} from "react-redux";

function TermsAndConditionsComponent(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);

    return (
        <div className={darkMode ? style.policy + " dark-block-bg light-text col-12" :  style.policy + " col-12"}>
                <div className={style.big_title}>TÉRMINOS Y CONDICIONES</div>
                <div className={style.title}>1. Introducción</div>
                <p className={style.text}>1.1 La Plataforma de gestión es proporcionada por HAY CONEXION, S.L. (En
                        adelante TipsSI) con domicilio social en Barcelona, Consell de Cent 383, 1-1 08009, con CIF
                        44872653.</p>
                <p className={style.text}>1.2 Estos términos de uso afectan al sitio web de la plataforma ("Términos y
                        condiciones") se aplican a todos los usuarios de TipsSi, que está disponible a través de sitios
                        web https://TIPSSI.net/, //Tipsi.net, APP tIPSSI y de otras tecnologías que ponemos a
                        disposición (en adelante “La Plataforma”).</p>
                <p className={style.text}>Estos Términos de la plataforma deben leerse junto con nuestro Aviso de
                        privacidad, además de nuestros Términos del cliente, Términos del cliente y/o Acuerdo del
                        operador (según corresponda), que juntos conforman el "Acuerdo" entre nosotros.</p>
                <p className={style.text}>1.3 Al acceder y utilizar la Plataforma, usted acepta y está de acuerdo con
                        los Términos. Si no comprende o no está de acuerdo con los Términos, no debe usar la
                        Plataforma.</p>
                <p className={style.text}>1.4 Esta plataforma actualiza los términos y condiciones frecuentemente, estos
                        se pondrán a su disposición en su dirección de correo electrónico o mediante un mensaje SMS a su
                        número de registro. Si continúa utilizando la Plataforma después de que realicemos los cambios,
                        se considerará que está de acuerdo con los cambios. Si no está de acuerdo con los cambios, no
                        deberá usar la Plataforma.</p>
                <div className={style.title}>2. Acceso al servicio, datos de registro</div>
                <p className={style.text}>2.1 Un requisito previo para que el Cliente tenga acceso a los Servicios de
                        TipsSI es que se registre como usuario de la plataforma. TipsSI se reserva el derecho a rechazar
                        cualquier solicitud de registro</p>
                <p className={style.text}>2.2 Debe asegurarse de que los datos proporcionados por usted en el registro o
                        en cualquier momento sean correctos y completos. Debe informarnos de cualquier cambio en la
                        información que proporciona al registrarse mediante la actualización de sus datos
                        personales.</p>
                <p className={style.text}>2.3 Los nombres, las direcciones de correo electrónico y las imágenes de
                        perfil no deben: (i) ser obscenos u ofensivos, (ii) infringir los derechos de terceros o (iii)
                        considerarse inapropiados a nuestra absoluta discreción.</p>
                <p className={style.text}>2.4 Puede cancelar su registro y/o solicitarnos que desactivemos su cuenta en
                        cualquier momento enviando una solicitud de soporte a <a
                            href="mailto:tipssi@tipssi.net">tipssi@tipssi.net</a>.</p>
                <p className={style.text}>Eliminaremos su cuenta dentro de los 30 días posteriores a la recepción de su
                        solicitud (sujeto a ciertos datos requeridos para fines de mantenimiento de registros
                        financieros y legales). Una vez que hayamos eliminado su cuenta y cualquier otra información, no
                        se podrá recuperar.</p>
                <div className={style.title}>3. Contraseñas y Seguridad</div>
                <p className={style.text}>3.1 Cuando se registre en la Plataforma, se le pedirá que cree una contraseña,
                        que debe mantener confidencial y no divulgar ni compartir con nadie. Tendremos derecho a tratar
                        cualquier acción realizada a través de su cuenta como realizada por usted. Debe notificarnos de
                        inmediato si tiene motivos para creer que los detalles de su cuenta pueden haber sido
                        comprometidos o utilizados por otra persona.</p>
                <p className={style.text}>3.2 Si tenemos razones para creer que hay o es probable que haya un uso
                        indebido de la Plataforma o una violación de la seguridad, podemos solicitarle que cambie su
                        contraseña y/o suspenda su cuenta.</p>
                <div className={style.title}>4. Uso de la Plataforma</div>
                <p className={style.text}>4.1 Usted es responsable de cumplir con los requisitos necesarios para acceder
                        a la Plataforma. En particular, usted es responsable de asegurarse de que su computadora y/o
                        dispositivo portátil sea compatible con la Plataforma, y su uso de la Plataforma no entrará en
                        conflicto con las leyes de su lugar de residencia.</p>
                <div className={style.title}>5. Uso prohibido</div>
                <p className={style.text}>5.1 No debe (y no debe hacer que otra persona lo haga):</p>
                <p className={style.text}>5.1.1 Usar cualquier sistema o software automatizado para extraer contenido o
                        datos de la Plataforma con fines comerciales, excepto cuando usted o cualquier tercero aplicable
                        haya celebrado un acuerdo por escrito con nosotros que permita dicha actividad.</p>
                <p className={style.text}>5.1.2 Interferir, dañar o interrumpir la Plataforma o cualquier servidor o red
                        conectada a la Plataforma, incluso mediante la transmisión de gusanos, virus, malware, spyware o
                        cualquier otro código de naturaleza destructiva, maliciosa o disruptiva. No puede inyectar
                        contenido o código o alterar o interferir de otra manera con la forma en que cualquier página de
                        la Plataforma se presenta o se muestra en el navegador o dispositivo de un usuario.</p>
                <p className={style.text}>5.1.3 Acceder a la Plataforma a través de medios no autorizados por escrito
                        por adelantado por nosotros, incluidos, entre otros, dispositivos automatizados, scripts, bots,
                        arañas, rastreadores o raspadores (excepto las tecnologías de motores de búsqueda estándar).</p>
                <p className={style.text}>5.1.4 Intentar restringir el uso o disfrute de la Plataforma por parte de otro
                        usuario de la Plataforma y no debe fomentar ni facilitar el incumplimiento de estos Términos de
                        la Plataforma por parte de otros.</p>
                <p className={style.text}>5.1.5 Usar la Plataforma para fines ilegales o no autorizados o de alguna
                        manera que defienda, promueva o ayude a cualquier acto ilegal como (solo a modo de ejemplo) la
                        infracción de derechos de autor, el mal uso de la computadora o la recopilación o recolección de
                        cualquier información o datos de nuestro sistemas o servidores.</p>
                <p className={style.text}>5.1.6 Usar la Plataforma de cualquier manera o que (i) sea difamatorio de
                        cualquier otra persona, (ii) sea obsceno u ofensivo, (iii) promueva la discriminación por
                        motivos de raza, sexo, religión, nacionalidad, discapacidad, orientación sexual o edad , o (iv)
                        es probable que hostigue, moleste, avergüence, alarme o moleste a cualquier otra persona.</p>
                <p className={style.text}>5.1.7 Cambiar, modificar, adaptar o alterar la Plataforma o cambiar, modificar
                        o alterar otro sitio web para implicar incorrectamente una asociación con la Plataforma o con
                        nosotros.</p>
                <div className={style.title}>6. Propiedad Intelectual</div>
                <p className={style.text}>6.1 La Plataforma y toda la información, música, imágenes, fotografías, videos
                        y otro contenido que se muestra en la Plataforma (los "Materiales") están protegidos por ciertos
                        derechos. Estos derechos incluyen todas las patentes, derechos de invención, derechos de autor,
                        derechos de bases de datos, derechos de propiedad del artista intérprete o ejecutante, derechos
                        morales, marcas registradas y marcas de servicio, nombres comerciales y nombres de dominio,
                        fondo de comercio y derecho a demandar por falsificación o competencia desleal, derechos en
                        diseños y todos los demás derechos de propiedad intelectual y de propiedad, en cada caso
                        registrados o no registrados (los "Derechos"). Estos Derechos nos pertenecen directamente o
                        están autorizados para nosotros por sus respectivos propietarios o licenciantes.</p>
                <p className={style.text}>6.2 Solo puede ver, imprimir, usar, citar y citar la Plataforma y los
                        Materiales para su uso personal, no comercial y con la condición de que nos dé el reconocimiento
                        apropiado cuando corresponda.</p>
                <p className={style.text}>6.3 Nos reservamos expresamente todos los Derechos en y para nuestra
                        Plataforma y los Materiales. Su uso de nuestra Plataforma y los Materiales está sujeto a las
                        siguientes restricciones. No debes:</p>
                <p className={style.text}>6.3.1 copiar la Plataforma, excepto cuando dicha copia sea incidental al uso
                        normal de la Plataforma, o cuando sea necesaria con fines de respaldo o seguridad operativa;</p>
                <p className={style.text}>6.3.2 sublicenciar o poner a disposición la Plataforma en su totalidad o en
                        parte (incluidos el objeto y el código fuente), en cualquier forma a cualquier persona sin
                        nuestro consentimiento previo por escrito;</p>
                <p className={style.text}>6.3.3 eliminar cualquier derecho de autor u otros avisos de propiedad
                        contenidos en los Materiales;</p>
                <p className={style.text}>6.3.4 usar cualquier Material de cualquier manera que pueda infringir
                        cualquiera de nuestros Derechos o los Derechos de un tercero;</p>
                <p className={style.text}>6.3.5 usar los Materiales de cualquier manera que pueda ser ilegal o violar
                        estos Términos de la plataforma;</p>
                <p className={style.text}>6.3.6 reproducir, modificar, editar, mezclar o remezclar, aplicar cualquier
                        voz en off o comentario, mostrar, realizar, publicar, distribuir, difundir, transmitir,
                        enmarcar, comunicar al público o circular a terceros o explotar nuestra Plataforma y/o los
                        Materiales de ninguna manera para ningún propósito comercial, sin nuestro consentimiento previo
                        por escrito;</p>
                <p className={style.text}>6.3.7 desensamblar, descompilar, aplicar ingeniería inversa o crear trabajos
                        derivados basados en la totalidad o parte de la Plataforma o intentar hacer tal cosa; o</p>
                <p className={style.text}>6.3.8 usar la Plataforma o los Materiales de cualquier manera que contravenga
                        cualquier ley o regulación aplicable o de cualquier manera que viole la privacidad, la
                        publicidad o los derechos personales de otros o en cualquier material difamatorio, obsceno,
                        amenazante, abusivo u odioso incluido, entre otros, el uso de la Plataforma o cualquier Material
                        en conexión o asociación con cualquier material obsceno, difamatorio, ilegal, pornográfico o en
                        relación con productos y servicios relacionados con armas de fuego, apuestas, productos
                        farmacéuticos, pornografía o tabaco o de otra manera que no sea de acuerdo con los estándares
                        normales de decencia.</p>
                <p className={style.text}>6.4 Cualquier uso de nuestra Plataforma o los Materiales de una manera no
                        permitida expresamente por estos Términos de la Plataforma puede constituir una infracción de
                        nuestros Derechos y/o los Derechos de nuestros licenciantes. Nosotros y nuestros licenciantes
                        nos reservamos el derecho de ejercer todos los derechos y recursos disponibles con respecto a
                        cualquier infracción de los Derechos en nuestra Plataforma o los Materiales accesibles en
                        ella.</p>
                <div className={style.title}>7. Cargar su contenido en nuestra plataforma</div>
                <p className={style.text}>7.1 En ciertas partes de nuestra Plataforma, se le puede invitar a cargar
                        materiales en nuestra Plataforma y/o ponerse en contacto con otros usuarios. Si elige cargar
                        dichos materiales, su contenido no debe:</p>
                <p className={style.text}>7.2 contenga cualquier material que sea o pueda razonablemente considerarse
                        amenazante, difamatorio, obsceno, indecente, ofensivo, pornográfico, abusivo, susceptible de
                        incitar al odio racial, discriminatorio, amenazante, escandaloso, incendiario, blasfemo o que
                        infrinja la confidencialidad;</p>
                <p className={style.text}>7.3 ser ilegal o infringir los Derechos de cualquier tercero, en cualquier
                        país del mundo.</p>
                <p className={style.text}>7.4 ser técnicamente dañino (a modo de ejemplo, no debe incluir virus
                        informáticos, bombas lógicas, caballos de Troya, gusanos, componentes dañinos, datos dañados u
                        otro software malicioso o datos dañinos).</p>
                <p className={style.text}>7.5 Al cargar su contenido en la Plataforma, nos otorga a nosotros y a
                        nuestros designados, junto con los usuarios de nuestra Plataforma, una licencia irrevocable no
                        exclusiva (que es ilimitada en el tiempo) para ver y usar su contenido sin restricciones,
                        incluso con fines comerciales.</p>
                <div className={style.title}>8. Enlaces a sitios web de terceros</div>
                <p className={style.text}>8.1 La Plataforma (y los correos electrónicos que le enviamos en relación con
                        la Plataforma) pueden incluir enlaces a sitios web, aplicaciones y/u otras propiedades digitales
                        de terceros ("Propiedades de terceros") que son controlados y mantenidos por terceros. Si decide
                        visitar cualquier Propiedad de terceros o utilizar un servicio disponible a través de, o comprar
                        productos a través de una Propiedad de terceros, lo hace bajo su propio riesgo. Es su
                        responsabilidad asegurarse de la reputación del tercero relevante y los servicios que ofrece. No
                        somos responsables del contenido, la precisión ni las opiniones expresadas en las Propiedades de
                        terceros. Los enlaces que ponemos a disposición no implican que nosotros, o nuestra Plataforma,
                        estén afiliados o asociados con dichos sitios web o servicios.</p>
                <p className={style.text}>8.2 Su interacción con cualquier Propiedad de terceros está sujeta a los
                        propios términos y políticas del tercero correspondiente. En particular, terceros procesarán sus
                        datos personales de acuerdo con sus propios avisos de privacidad. Lea todos los términos y
                        políticas aplicables del tercero correspondiente antes de usar una Propiedad de terceros y/o
                        usar un servicio disponible o comprar productos a través de una Propiedad de terceros.</p>
                <div className={style.title}>9. Disponibilidad y descargos de responsabilidad</div>
                <p className={style.text}>9.1 La Plataforma se proporciona "tal cual" y "según disponibilidad". No
                        garantizamos que la Plataforma esté libre de defectos y/o fallas, que los defectos se corregirán
                        o que la Plataforma o el servidor que la pone a disposición estén libres de virus o cualquier
                        otra cosa que pueda ser dañina o destructiva. En la medida máxima permitida por la ley,
                        excluimos todas las garantías que no se establezcan expresamente en estos Términos, incluidas,
                        entre otras, las garantías implícitas de idoneidad para un propósito particular, precisión de la
                        información, compatibilidad y calidad satisfactoria.</p>
                <p className={style.text}>9.2 Podemos actualizar parte o la totalidad de la Plataforma y/o cualquiera de
                        los servicios y/o contenido disponible a través de ella en cualquier momento y por cualquier
                        motivo, por ejemplo, para mejorar el rendimiento, mejorar la funcionalidad, reflejar cambios en
                        los sistemas operativos o abordar la seguridad. asuntos. Alternativamente, podemos pedirle que
                        actualice partes de la Plataforma por estos motivos. Si elige no instalar dichas actualizaciones
                        o si opta por no recibir actualizaciones automáticas (cuando corresponda), es posible que no
                        pueda continuar usando la Plataforma o que la funcionalidad se vea afectada.</p>
                <p className={style.text}>9.3 Si bien haremos todos los esfuerzos razonables para garantizar que la
                        Plataforma sea segura y esté libre de errores, virus y otro malware, no ofrecemos ninguna
                        garantía al respecto y usted es responsable de su propia seguridad, la de sus datos personales y
                        la de sus computadoras. . No aceptamos ninguna responsabilidad por cualquier interrupción o
                        falta de disponibilidad de la Plataforma. No tenemos ninguna obligación de actualizar la
                        información en la Plataforma.</p>
                <p className={style.text}>9.4 Nos reservamos el derecho de modificar, suspender o descontinuar cualquier
                        parte (o la totalidad) de la Plataforma. Estos Términos seguirán aplicándose a cualquier versión
                        modificada de la Plataforma a menos que se indique expresamente lo contrario.</p>
                <p className={style.text}>9.5 El acceso a la Plataforma está permitido de forma temporal y nos
                        reservamos el derecho de retirar el acceso a la Plataforma por cualquier motivo y sin previo
                        aviso.</p>
                <div className={style.title}>10. Responsabilidad</div>
                <p className={style.text}>10.1 No excluimos nuestra responsabilidad por muerte o lesiones personales
                        causadas por negligencia.</p>
                <p className={style.text}>10.2 No seremos responsables ante usted con respecto a las pérdidas que surjan
                        de cualquier evento o circunstancia fuera de nuestro control razonable.</p>
                <p className={style.text}>10.3 Usted será responsable de todos los reclamos, responsabilidades, daños,
                        costos y gastos sufridos o incurridos por nosotros como resultado de su incumplimiento de estos
                        Términos o incumplimiento de sus obligaciones.</p>
                <p className={style.text}>10.4 Salvo lo establecido en la cláusula 10.1, y en la medida máxima permitida
                        por la ley, no seremos responsables de ninguno de los siguientes:</p>
                <p className={style.text}>10.5 cualquier pérdida comercial, como pérdida de ganancias, ingresos,
                        ingresos, ahorros anticipados, negocios, contratos, fondo de comercio u oportunidades
                        comerciales;</p>
                <p className={style.text}>10.6 pérdida o corrupción de cualquier dato, base de datos o software; 10.7
                        cualquier pérdida o daño especial, indirecto o consecuente.</p>
                <p className={style.text}>10.7 Salvo que se indique en la cláusula 10.1 o que se establezca expresamente
                        de otro modo en los Términos del cliente, los Términos del cliente o el Acuerdo del operador
                        (según corresponda), nuestra máxima responsabilidad hacia usted con respecto a su uso de nuestra
                        Plataforma o cualquier asunto que surja en relación con esta Plataforma. Los términos son
                        100£.</p>
                <div className={style.title}>11. Disposiciones generales</div>
                <p className={style.text}>11.1 Si cualquier tribunal o autoridad competente determina que cualquier
                        disposición de estos Términos de la plataforma (o parte de cualquier disposición) es inválida,
                        ilegal o inaplicable, esa disposición o parte de la disposición, en la medida requerida, se
                        considerará eliminada, y la la validez y aplicabilidad de las demás disposiciones de estos
                        Términos de la plataforma no se verán afectadas.</p>
                <p className={style.text}>11.2 No puede ceder, sublicenciar ni transferir ningún derecho en virtud de
                        estos Términos de la plataforma.</p>
                <p className={style.text}>11.3 Nada en estos Términos de la plataforma establecerá ninguna sociedad o
                        empresa conjunta entre nosotros, o (salvo que se indique expresamente) significará que cualquier
                        parte de este Acuerdo se convierte en el agente de otro tercero, ni el Acuerdo (excepto que se
                        indique expresamente) autoriza cualquier parte a contraer compromisos para o en nombre de
                        cualquier otro tercero.</p>
                <p className={style.text}>11.4 Si no ejercemos ningún derecho o recurso en virtud de nuestros Términos
                        de la plataforma, nuestro incumplimiento no constituye una renuncia a ese derecho o recurso.
                        Cualquier renuncia debe ser por escrito y firmada por nosotros.</p>
                <div className={style.title}>12. Ley aplicable y jurisdicción</div>
                <p className={style.text}>12.1 Estos Términos se regirán e interpretarán de acuerdo con las leyes de
                        España y todas las disputas que surjan en virtud de los Términos (incluidas las disputas o
                        reclamaciones no contractuales) estarán sujetas a la jurisdicción exclusiva de los tribunales de
                        Barcelona.</p>
                <div className={style.title}>13. Quejas</div>
                <p className={style.text}>13.1 Si tiene alguna solicitud o queja sobre la Plataforma, envíe una
                        solicitud de soporte a tipssi@tipssi.net.</p>
                <div className={style.big_title + " mt-5"}>TÉRMINOS Y CONDICIONES DE LOS PROFESIONALES</div>
                <div className={style.title}>TÉRMINOS Y CONDICIONES DE LOS PROFESIONALES</div>
                <p className={style.text}>1.1 La Plataforma de gestión es proporcionada por HAY CONEXION, S.L. (En
                        adelante TipsSI) con domicilio social en Barcelona, Consell de Cent 383, 1-1 08009, con CIF
                        44872653.</p>
                <p className={style.text}>1.2 Estos términos de uso afectan al sitio web de la plataforma ("Términos y
                        condiciones") se aplican a todos los usuarios de TipsSi, que está disponible a través de sitios
                        web https://TIPSSI.net/, //Tipsi.net, APP tIPSSI y de otras tecnologías que ponemos a
                        disposición (en adelante “La Plataforma”).</p>
                <p className={style.text}>Estos Términos de la plataforma deben leerse junto con nuestro Aviso de
                        privacidad, además de nuestros Términos del cliente, Términos del cliente y/o Acuerdo del
                        operador (según corresponda), que juntos conforman el "Acuerdo" entre nosotros.</p>
                <p className={style.text}>1.3 Al acceder y utilizar la Plataforma, usted acepta y está de acuerdo con
                        los Términos. Si no comprende o no está de acuerdo con los Términos, no debe usar la
                        Plataforma.</p>
                <p className={style.text}>1.4 Esta plataforma actualiza los términos y condiciones frecuentemente, estos
                        se pondrán a su disposición en su dirección de correo electrónico o mediante un mensaje SMS a su
                        número de registro. Si continúa utilizando la Plataforma después de que realicemos los cambios,
                        se considerará que está de acuerdo con los cambios. Si no está de acuerdo con los cambios, no
                        deberá usar la Plataforma.</p>
                <div className={style.title}>2. Objeto</div>
                <p className={style.text}>2.1 Usted nos designa para proporcionar medios técnicos para recopilar
                        opiniones y calificaciones de sus clientes y proporcionarle información sobre dichas opiniones y
                        calificaciones.</p>
                <p className={style.text}>2.2 Nos designa como su agente comercial no exclusivo para recopilar sus
                        sugerencias en los términos establecidos en estos Términos, además de proporcionar información
                        sobre reseñas y calificaciones. En este sentido, actuamos como agente comercial solo para usted
                        y no como agente del Cliente y/o de terceros.</p>
                <p className={style.text}>2.3 Usted nos autoriza a realizar transacciones con Clientes para cobrar sus
                        propinas en su nombre, lo que incluye su autoridad para vincularse contractualmente con respecto
                        a esas transacciones.</p>
                <p className={style.text}>2.4 Usted acepta que solo tendrá un recurso contra nosotros (y solo seremos
                        responsables ante usted) si cobramos propinas en su nombre, pero no se las transferimos cuando
                        vencen (sujeto a la deducción de nuestra comisión y otros cargos / costes que tenemos derecho a
                        cobrar).</p>
                <div className={style.title}>3. Nuestras Obligaciones</div>
                <p className={style.text}>3.1 Como proveedor de la Plataforma, le proporcionaremos acceso a la
                        Plataforma con el fin de:</p>
                <p className={style.text}>3.1.1 registro;</p>
                <p className={style.text}>3.1.2 crear una cuenta;</p>
                <p className={style.text}>3.1.3 obtener información sobre reseñas y calificaciones;</p>
                <p className={style.text}>3.1.4 autorizarnos a concluir transacciones con Clientes y recopilar propinas
                        en su nombre junto con la obtención de reseñas y calificaciones;</p>
                <p className={style.text}>3.1.5 presentar una variedad de opciones a los Clientes en cuanto a la
                        cantidad de propina que desean pagar;</p>
                <p className={style.text}>3.1.6 con el acuerdo del Cliente, permitirle ver el nombre del Cliente que lo
                        ha revisado y le ha dado una propina;</p>
                <p className={style.text}>3.1.7 brindar al Cliente la opción de aumentar su propina para cubrir nuestra
                        comisión y costos;</p>
                <p className={style.text}>3.1.8 cobrar y retener todo el dinero que se le debe como resultado de las
                        propinas que cobramos en su nombre;</p>
                <p className={style.text}>3.1.9 le brinda un medio para acceder a registros relevantes para las reseñas,
                        calificaciones y sugerencias que hemos recopilado en su nombre.</p>
                <p className={style.text}>3.2 Podemos utilizar terceros (incluidos procesadores de pago de terceros) en
                        relación con la prestación de nuestros servicios.</p>
                <p className={style.text}>3.3 A excepción de lo establecido en estos Términos, la Plataforma se
                        proporciona sin garantía de ningún tipo. Excluimos todas las condiciones, garantías,
                        representaciones y términos implícitos por ley, costumbre o de otro modo en la mayor medida
                        permitida por la ley.</p>
                <p className={style.text}>3.4 Usted reconoce que podemos modificar o descontinuar ciertas
                        funcionalidades o características de la Plataforma de vez en cuando y podemos hacerlo, sin
                        previo aviso.</p>
                <div className={style.title}>4. Sus obligaciones</div>
                <p className={style.text}>4.1 Como usuario de la Plataforma y como nuestro principal en la relación de
                        agencia comercial, usted acepta: 4.1.1 actuar de buena fe;</p>
                <p className={style.text}>4.1.2 tratar con prontitud cualquier queja o consulta del Cliente relacionada
                        con reseñas, calificaciones y consejos;</p>
                <p className={style.text}>4.1.3 no permitir que ninguna otra persona acceda a su cuenta de la Plataforma
                        ni retire fondos de su cuenta de la Plataforma; y 4.1.4 cumplir con todas las leyes
                        aplicables.</p>
                <p className={style.text}>4.2 Usted confirma que:</p>
                <p className={style.text}>4.2.1 tener el derecho legal de recibir propinas en el territorio de su
                        residencia;</p>
                <p className={style.text}>4.2.2 tener una cuenta bancaria válida registrada a su nombre;</p>
                <p className={style.text}>4.2.3 son legalmente capaces de celebrar contratos vinculantes;</p>
                <p className={style.text}>4.2.4 utilizará la Plataforma solo para el cobro de propinas que los Clientes
                        le paguen directamente a usted;</p>
                <p className={style.text}>4.2.5 seguir siendo responsable de informar correcta y puntualmente las
                        propinas que los Clientes le paguen a través de la Plataforma como ganancias a la autoridad
                        fiscal correspondiente;</p>
                <p className={style.text}>4.2.6 seguirá siendo personalmente responsable de los pagos de todos los
                        impuestos aplicables a las cantidades pagadas a usted a través de la Plataforma;</p>
                <p className={style.text}>4.2.7 nos ha proporcionado información precisa y completa en nuestro proceso
                        de registro.</p>
                <p className={style.text}>4.3 Usted acepta reembolsarnos por todos y cada uno de los reclamos, daños y/o
                        pérdidas que surjan de los reclamos realizados por un tercero contra nosotros como resultado de
                        su incumplimiento de estos Términos.</p>
                <div className={style.title}>5. Recopilación de reseñas, calificaciones y consejos</div>
                <p className={style.text}>5.1 Puede recopilar reseñas, calificaciones y consejos directamente de los Clientes utilizando el enlace de su Plataforma y el código QR.</p>
                <p className={style.text}>5.2 Cuando recolectemos propinas de los Clientes en su nombre, las pagaremos en su cuenta de la Plataforma (menos nuestra comisión y cualquier tarifa/costo que tengamos derecho a deducir de acuerdo con estos Términos).</p>
                <p className={style.text}>5.3 Su cuenta de la Plataforma es personal y se puede acceder a ella iniciando sesión en la Plataforma.</p>
                <p className={style.text}>5.4 Puede retirar sumas que se hayan acumulado en su Cuenta de plataforma solicitando un pago. El pago se realizará a la cuenta bancaria registrada a su nombre que haya adjuntado a su Cuenta de la Plataforma, y haremos todo lo posible para realizar dicho pago con efecto inmediato, aunque en algunas circunstancias esto puede demorar hasta 30 días dependiendo de su banco.</p>
                <p className={style.text}>5.5 Limitaciones de las propinas</p>
                <p className={style.text}>5.5.1 La cantidad de una sola propina que reciba no puede exceder los 50 euros. Si el importe de la propina supera los 50 euros, debe dividirse en varios pagos.</p>
                <p className={style.text}>5.5.2 No puede recibir más de 5 propinas consecutivas de la misma tarjeta de pago durante el período de 24 horas. Si el número de propinas recibidas de la misma tarjeta bancaria supera los 5 durante un período de 24 horas, no podrá recibir más propinas de esta tarjeta bancaria hasta que hayan transcurrido 24 horas desde la recepción de su primera propina de la misma tarjeta bancaria.</p>
                <p className={style.text}>5.5.3 No puede recibir más de 500 euros al mes en propinas totales de la misma tarjeta de pago durante un período de 30 días. Si el importe de sus propinas recibidas de la misma tarjeta bancaria supera los 500 euros, no podrá recibir más propinas de esta tarjeta bancaria hasta que hayan transcurrido 30 días desde la recepción de su primera propina de la misma tarjeta bancaria.</p>
                <p className={style.text}>5.6 Consejos para compartir</p>
                <p className={style.text}>5.6.1 Puede tener la opción de compartir sus consejos con compañeros de trabajo en su equipo que están trabajando al mismo tiempo. Si usted es el gerente, debe solicitar a los miembros del equipo que accedan a dichos datos.</p>
                <p className={style.text}>5.6.2 No somos responsables de ningún error, omisión o comportamiento de otros usuarios que decidan unirse a un Esquema de intercambio de equipos para compartir Consejos con usted.</p>
                <div className={style.title}>6. Nuestra Comisión</div>
                <p className={style.text}>6.1 Usted acepta pagarnos nuestra comisión y ciertos otros cargos y costos especificados en esta cláusula.</p>
                <p className={style.text}>6.2 Con respecto a cada propina deduciremos:</p>
                <p className={style.text}>6.2.1 Nuestra comisión (más cualquier IVA aplicable o impuesto sobre las ventas equivalente); y</p>
                <p className={style.text}>6.2.2 los costes en los que incurramos en relación con el procesamiento de pagos con tarjeta para el cobro de la propina del Cliente y la transferencia del saldo a su cuenta de la Plataforma.</p>
                <p className={style.text}>6.3 Podemos darle al Cliente la opción de cubrir nuestra comisión y los costos de procesar el pago con tarjeta para la transacción de la propina (efectuando un pago además de la propina prevista). Si el Cliente está de acuerdo con esto, recibirá una suma equivalente al 100% de la propina prevista. Si el Cliente no está de acuerdo con esto, nuestra comisión y los costos de transacción se deducirán de la propina.</p>
                <p className={style.text}>6.4 También podemos cobrarle ciertas tarifas de instalación, suscripción y/o actualización para usar la Plataforma.</p>
                <div className={style.title}>7. Confidencialidad</div>
                <p className={style.text}>7.1 Cada uno de nosotros acepta en todo momento (i) mantener la confidencialidad y no divulgar a ninguna persona ninguna Información confidencial y (ii) solo utilizar dicha Información confidencial con el fin de cumplir con nuestras obligaciones o ejercer nuestros derechos en virtud de estos Términos.</p>
                <p className={style.text}>7.2 Podemos divulgar Información confidencial a nuestro personal, subcontratistas, representantes y/o asesores en relación con el cumplimiento de nuestras obligaciones y/o el ejercicio de nuestros derechos en virtud de estos Términos.</p>
                <p className={style.text}>7.3 La información confidencial incluye toda la información (independientemente de cómo se registre o conserve) divulgada por cualquiera de las partes a la otra parte en relación con (a) los negocios, asuntos, clientes, clientes, proveedores, planes, intenciones u oportunidades de mercado de la parte divulgadora; (b) operaciones, procesos, información de productos, know-how, diseños, secretos comerciales o software; y (c) cualquier información desarrollada bajo estos Términos.</p>
                <div className={style.title}>8. Terminación</div>
                <p className={style.text}>8.1 Puede dejar de usar la Plataforma en cualquier momento enviando una solicitud de soporte. Cuando nos pida esto, haremos lo siguiente:</p>
                <p className={style.text}>8.1.1 Suspender su capacidad de recopilar reseñas, calificaciones y consejos utilizando la Plataforma;</p>
                <p className={style.text}>8.2 Podemos rescindir estos Términos enviándole una notificación por escrito (incluso por correo electrónico):</p>
                <p className={style.text}>8.2.1 inmediatamente si incumple estos Términos; o</p>
                <p className={style.text}>8.2.2 cuando ya no podamos (o deseemos) brindar el servicio de la Plataforma, en cuyo caso nos esforzaremos por avisarle con al menos 7 días de anticipación.</p>
                <p className={style.text}>8.3 La rescisión de estos Términos no afectará ningún derecho acumulado (incluido nuestro derecho a recibir pagos o su derecho a recibir propinas) a la fecha de rescisión.</p>
                <div className={style.title}>9. Derechos del Consumidor</div>
                <p className={style.text}>9.1 Al usar la Plataforma, usted tiene ciertos derechos como consumidor bajo la ley aplicable al consumidor. Nada en estos Términos resta valor a esos derechos.</p>
                <div className={style.title}>10. Consecuencias de la rescisión</div>
                <p className={style.text}>10.1 Al finalizar, debe interrumpir inmediatamente el uso de la Plataforma. Cualquier uso, acceso o intento de uso o acceso continuado por parte de usted a la Plataforma hará que cualquier aviso por su parte sea ineficaz y constituirá su aceptación continua de estos Términos.</p>
                <p className={style.text}>10.2 Le pagaremos los montos finales retenidos en su nombre en su cuenta de la Plataforma (menos nuestra comisión y cualquier tarifa/costo que tengamos derecho a deducir de acuerdo con estos Términos).</p>
                <div className={style.title}>11. Circunstancias fuera de nuestro control</div>
                <p className={style.text}>11.1 No seremos responsables de ninguna falla o demora en el cumplimiento de nuestras obligaciones (incluida la falta de pago de los montos adeudados) que resulten de cualquier causa fuera de nuestro control razonable. Le mantendremos informado de cualquier retraso y acción correctiva.</p>
                <div className={style.title}>12. Generalidades</div>
                <p className={style.text}>12.1 Si cualquier tribunal o autoridad competente determina que cualquier disposición de los Términos (o parte de cualquier disposición) es inválida, ilegal o inaplicable, esa disposición o parte de la disposición, en la medida requerida, se considerará eliminada y la validez y la aplicabilidad de las demás disposiciones de los Términos no se verá afectada.</p>
                <p className={style.text}>12.2 Nada en los Términos establecerá ninguna asociación o empresa conjunta entre nosotros, o (salvo que se indique expresamente) significa que cualquier parte de estos Términos se convierte en el agente de otro tercero, ni los Términos (salvo que se indique expresamente) autorizan a cualquier parte para contraer cualquier compromiso para o en nombre de cualquier otro tercero.</p>
                <p className={style.text}>12.3 No puede transferir ninguno de sus derechos u obligaciones bajo estos Términos a ninguna otra persona. Podemos transferir nuestros derechos bajo estos términos y condiciones cuando creamos razonablemente que sus derechos no se verán afectados.</p>
                <div className={style.title}>13. Ley aplicable y jurisdicción</div>
                <p className={style.text}>13.1 Estos Términos se regirán e interpretarán de acuerdo con las leyes de España y todas las disputas que surjan en virtud de los Términos (incluidas las disputas o reclamaciones no contractuales) estarán sujetas a la jurisdicción exclusiva de los tribunales de Barcelona.</p>
                <div className={style.big_title + " mt-5"}>TÉRMINOS Y CONDICIONES DE LOS CLIENTES</div>
                <div className={style.title}>1. Introducción</div>
                <p className={style.text}>1.1 La Plataforma de gestión es proporcionada por HAY CONEXION, S.L. (En adelante TipsSI) con domicilio social en Barcelona, Consell de Cent 383, 1-1 08009, con CIF 44872653.</p>
                <p className={style.text}>1.2 Estos términos de uso afectan al sitio web de la plataforma ("Términos y condiciones") se aplican a todos los usuarios de TipsSi, que está disponible a través de sitios web https://TIPSSI.net/, //Tipsi.net, APP tIPSSI y de otras tecnologías que ponemos a disposición (en adelante “La Plataforma”).</p>
                <p className={style.text}>Estos Términos de la plataforma deben leerse junto con nuestro Aviso de privacidad, además de nuestros Términos del cliente, Términos del cliente y/o Acuerdo del operador (según corresponda), que juntos conforman el "Acuerdo" entre nosotros.</p>
                <p className={style.text}>1.3 Al acceder y utilizar la Plataforma, usted acepta y está de acuerdo con los Términos. Si no comprende o no está de acuerdo con los Términos, no debe usar la Plataforma.</p>
                <p className={style.text}>1.4 Esta plataforma actualiza los términos y condiciones frecuentemente, estos se pondrán a su disposición en su dirección de correo electrónico o mediante un mensaje SMS a su número de registro. Si continúa utilizando la Plataforma después de que realicemos los cambios, se considerará que está de acuerdo con los cambios. Si no está de acuerdo con los cambios, no deberá usar la Plataforma.</p>
                <div className={style.title}>2. Sus derechos y responsabilidades</div>
                <p className={style.text}>2.1 Se le proporciona acceso a la Plataforma para permitirle proporcionar reseñas, calificaciones y consejos de pago, y usted acepta que no utilizará la Plataforma para ningún otro propósito.</p>
                <p className={style.text}>2.2 Cuando utiliza la Plataforma para proporcionar una reseña, calificar o dar un consejo, confirma que:</p>
                <p className={style.text}>2.2.1 su intención es ofrecer una propina directamente al receptor y, en ausencia de la Plataforma, lo habría hecho utilizando efectivo u otros medios de pago; y</p>
                <p className={style.text}>2.2.2 su propina es un pago de gratificación voluntario ofrecido por usted al receptor correspondiente.</p>
                <p className={style.text}>2.3 Cuando le das una propina a un destinatario que usa la Plataforma, actuamos como agentes comerciales autorizados del destinatario para la transacción de la propina, lo que significa que cobraremos el monto de la propina de ti en nombre del destinatario y se lo pasaremos directamente (sujeto a deduciendo nuestra comisión y otros cargos/costos que tenemos derecho a cobrar al síndico).</p>
                <p className={style.text}>2.4 Es posible que le demos la opción de cubrir nuestra comisión y los costos de procesar el pago con tarjeta para la transacción de la propina (efectuando un pago además de la propina prevista). Si acepta esto, el receptor recibirá una suma equivalente al 100% de la propina prevista. Si no está de acuerdo con esto, nuestra comisión y los costos de transacción se deducirán de la propina.</p>
                <p className={style.text}>2.5 Usted reconoce que los destinatarios tienen derecho a hacer lo que elijan con las sugerencias que recopilan de usted utilizando la Plataforma.</p>
                <div className={style.title}>3. Limitaciones de las propinas</div>
                <p className={style.text}>3.1 Usted acepta que la cantidad de una sola propina que puede pagar a nuestro Cliente no puede exceder los 50 euros. Si el importe de la propina supera los 50 euros, debe dividirse en varios pagos.</p>
                <p className={style.text}>3.2 No puede pagar más de 5 propinas consecutivas de la misma tarjeta de pago durante el período de 24 horas. Si el número de propinas enviadas desde la misma tarjeta bancaria supera las 5 transacciones durante un período de 24 horas, no podrá enviar más propinas desde esta tarjeta bancaria hasta que hayan transcurrido 24 horas desde su primer pago de propinas desde la misma tarjeta bancaria.</p>
                <p className={style.text}>3.3 No puede pagar más de 500 euros al mes en propinas totales de la misma tarjeta de pago durante un período de 30 días. Si el importe de sus propinas pagadas con la misma tarjeta bancaria supera los 500 euros, no podrá pagar más propinas con esta tarjeta bancaria hasta que hayan transcurrido 30 días desde la recepción de su primera propina con la misma tarjeta bancaria.</p>
                <div className={style.title}>4. Nuestros derechos y responsabilidades</div>
                <p className={style.text}>4.1 Actuamos como agente comercial para los receptores de propinas que utilizan la Plataforma para recolectar y recibir propinas. Esto significa que estamos autorizados por ellos para concluir transacciones de propinas y recolectar propinas en su nombre.</p>
                <p className={style.text}>4.2 No actuamos como su agente (o actuamos en su nombre) en ninguna capacidad. Nada en estos Términos establecerá ninguna asociación o empresa conjunta entre nosotros, ni estos Términos autorizan a ninguna parte a contraer compromisos para o en nombre de ningún otro tercero.</p>
                <p className={style.text}>4.3 Brindamos servicios tecnológicos a los destinatarios que pueden poner a su disposición para permitirle realizar transacciones con ellos.</p>
                <p className={style.text}>4.4 Nos reservamos el derecho a:</p>
                <p className={style.text}>4.4.1 aceptar o rechazar cualquier consejo ofrecido por usted a través de la Plataforma; o</p>
                <p className={style.text}>4.4.2 imponer límites relacionados con la cantidad de veces que puede usar la Plataforma por día/semana/mes.</p>
                <p className={style.text}>4.5 Nosotros:</p>
                <p className={style.text}>4.6 proporcionarle acceso a la Plataforma con el fin de proporcionar una reseña, calificar o pagar una propina a un receptor;</p>
                <p className={style.text}>4.7 presentarle una variedad de opciones en cuanto a la cantidad de propina que desea pagar; y 4.8 con su acuerdo, permita que el receptor vea su nombre cuando dé una propina.</p>
                <p className={style.text}>4.9 Podemos utilizar terceros (incluido un procesador de pagos) en relación con la prestación de nuestros servicios.</p>
                <p className={style.text}>4.10 Usted reconoce que podemos modificar o descontinuar ciertas funcionalidades o características de la Plataforma de vez en cuando.</p>
                <p className={style.text}>4.11 En ningún caso TipsSI aceptará reembolsos o devoluciones pues entendemos que va contra la naturaleza de la empresa.</p>
                <div className={style.title}>5. Indemnización y Responsabilidad</div>
                <p className={style.text}>5.1 El acceso a la Plataforma se proporciona "tal cual" y no se infiere ni implica ninguna garantía en cuanto a la disponibilidad de cualquier función ofrecida en cualquier momento.</p>
                <p className={style.text}>5.2 En la medida máxima permitida por la ley aplicable, no seremos responsables ante usted en relación con cualquier pérdida o daño que sufra que surja de o en relación con el uso o mal uso de la Plataforma por parte de usted o un receptor.</p>
                <div className={style.title}>6. Generalidades</div>
                <p className={style.text}>6.1 Estos Términos pueden ser modificados por nosotros de vez en cuando. Dichos términos revisados se aplicarán a partir de la fecha de publicación. Consulte los Términos regularmente para asegurarse de familiarizarse con la versión actual en ese momento.</p>
                <p className={style.text}>6.2 Si cualquier tribunal o autoridad competente determina que cualquier disposición de los Términos (o parte de cualquier disposición) es inválida, ilegal o inaplicable, esa disposición o parte de la disposición se considerará eliminado, en la medida requerida, y la validez y aplicabilidad de las demás disposiciones de estos Términos no se verán afectadas.</p>
                <p className={style.text}>6.3 No puede transferir ninguno de sus derechos u obligaciones en virtud de estos Términos a ninguna otra persona. Podemos transferir nuestros derechos bajo estos términos y condiciones cuando creamos razonablemente que sus derechos no se verán afectados.</p>
                <p className={style.text}>6.4 A menos que se acuerde lo contrario, ningún retraso, acto u omisión de una parte en el ejercicio de cualquier derecho o recurso se considerará una renuncia a ese derecho o recurso oa cualquier otro.</p>
                <div className={style.title}>7. Ley aplicable y jurisdicción</div>
                <p className={style.text}>7.1 Estos Términos se regirán e interpretarán de acuerdo con las leyes del Reino Unido y todas las disputas que surjan en virtud de los Términos (incluidas las disputas o reclamaciones no contractuales) estarán sujetas a la jurisdicción exclusiva de los tribunales del Reino Unido.</p>
                <div className={style.big_title + " mt-5"}>TÉRMINOS DEL OPERADOR</div>
                <div className={style.title}>1. Definiciones e interpretación</div>
                <p className={style.text}>En este Acuerdo, a menos que el contexto requiera lo contrario, las siguientes expresiones tienen los siguientes significados:</p>
                <p className={style.text}>1.1 "Acuerdo": este acuerdo para la prestación de los Servicios (como se define a continuación);</p>
                <p className={style.text}>1.2 "Día Hábil" - significa, cualquier día (que no sea sábado o domingo) en el que los bancos ordinarios estén abiertos para su gama completa de negocios normales en el Reino;</p>
                <p className={style.text}>1.3 "Información confidencial": en relación con cualquiera de las partes, cualquier información (ya sea que se declare o no confidencial o esté marcada como tal) que esa parte revela a la otra, o que la otra parte obtiene de cualquier información que esa parte le revela. , ya sea oralmente o por escrito o por cualquier otro medio, en virtud o en relación con este Acuerdo;</p>
                <p className={style.text}>1.4 "Fecha de entrada en vigor" significa la fecha en que comenzará la prestación de los Servicios.</p>
                <p className={style.text}>1.5 “Equipo” significa un equipo de dos o más empleados del Operador que han elegido voluntariamente unirse a la opción de compartir el equipo.</p>
                <p className={style.text}>1.6 "Compartir equipo" significa una funcionalidad opcional en la plataforma TipsSi que permite a los usuarios compartir Propinas con sus compañeros de trabajo.</p>
                <div className={style.title}>2. Duración</div>
                <p className={style.text}>2.1 Se considerará que este Acuerdo ha entrado en vigor en la Fecha de entrada en vigencia y (sujeto a las disposiciones para la terminación anticipada en este Acuerdo) continuará durante la duración del plazo.</p>
                <div className={style.title}>3. Prestación de Servicios</div>
                <p className={style.text}>3.1 Durante el Plazo, TipsSi proporcionará los Servicios al Operador en los términos establecidos en este Acuerdo. TipsSi prestará los Servicios con cuidado y habilidad razonables, y de acuerdo con:</p>
                <p className={style.text}>3.1.1 buenas prácticas de la industria; y</p>
                <p className={style.text}>3.1.2 todas las leyes y reglamentos aplicables, incluidos los relacionados con (i) la lucha contra el soborno y la corrupción, y (ii) la protección de datos.</p>
                <p className={style.text}>3.2 Obligaciones del Operador. El Operador se compromete a:</p>
                <p className={style.text}>3.2.1 cooperar y actuar de buena fe con la Plataforma TipsSi en todos los asuntos relacionados con los Servicios;</p>
                <p className={style.text}>3.2.2 proporcionar, de manera oportuna, toda la información y los datos que TipsSi pueda requerir razonablemente (incluida la información sobre las horas de turno trabajadas por los empleados del Operador en un Equipo para permitir que TipsSi confirme qué miembros del Equipo estaban trabajando en el momento en que se recopilan las Propinas) y se asegurará de que toda la información y los datos que proporcione a TipsSi sean precisos en todos los aspectos importantes;</p>
                <p className={style.text}>3.2.3 Obtener y mantener todas las licencias y consentimientos de terceros necesarios y cumplir con toda la legislación pertinente en relación con los Servicios;</p>
                <p className={style.text}>3.2.4 permitir que todos los empleados recolecten Propinas utilizando la plataforma TipsSi; y</p>
                <p className={style.text}>3.2.5 promover activamente la plataforma TipsSi entre los empleados como medio para recolectar Propinas.</p>
                <p className={style.text}>3.3 El Operador reconoce que TipsSi firmará contratos separados con los empleados del Operador que decidan utilizar TipsSi y, cuando lo hagan, designarán a TipsSi para que actúe como su agente comercial no exclusivo para el cobro de Propinas de los clientes.</p>
                <p className={style.text}>3.4 El Operador acepta que no ejercerá ninguna influencia, ni tendrá ningún control, sobre la distribución de Propinas recolectadas por los empleados del Operador en un Equipo.</p>
                <p className={style.text}>3.5 El Operador reconoce que el uso de TipsSi debe seguir siendo opcional para todos los empleados. Si bien el Operador promoverá TipsSi como un método opcional para que los empleados cobren Propinas, el Operador no obligará a los empleados a usar TipsSi para recolectar propinas (o hacer que esto sea una condición de su empleo y/o compromiso).</p>
                <p className={style.text}>3.6 El Operador garantiza y acepta que:</p>
                <p className={style.text}>3.6.1 deberá cumplir con todas las leyes aplicables;</p>
                <p className={style.text}>3.6.2 ha obtenido todos los permisos, autorizaciones, licencias y consentimientos necesarios para cumplir con sus deberes y obligaciones en virtud del presente Acuerdo.</p>
                <div className={style.title}>4. Cargos y Pago</div>
                <p className={style.text}>4.1 En contraprestación por la prestación de los Servicios por parte de TipsSi, el Operador pagará los Cargos a TipsSi.</p>
                <p className={style.text}>4.2 Cuando venza una cuota acordada, TipsSi facturará al Operador los Cargos que sean entonces pagaderos, junto con los costos de cualquier Equipo, materiales y/o gastos que no hayan sido incluidos expresamente en el precio fijado y cualquier IVA aplicable.</p>
                <p className={style.text}>4.3 El Operador pagará cada factura que le presente TipsSi, en su totalidad y con fondos compensados, dentro de los 30 días posteriores a la fecha de la factura en una cuenta bancaria designada por escrito por TipsSi (la "Fecha de vencimiento").</p>
                <p className={style.text}>4.4 Sin perjuicio de cualquier otro derecho o recurso que pueda tener, si el Operador no paga Propinas Si en la Fecha de Vencimiento:</p>
                <p className={style.text}>4.4.1 El Operador pagará intereses sobre el monto vencido a una tasa del 4% anual sobre la tasa base del Banco de Inglaterra en el momento pertinente. Dicho interés se acumulará diariamente desde la fecha de vencimiento hasta el pago real del monto vencido, ya sea antes o después del juicio. TipsSi puede optar por cobrar los intereses legales adeudados. El Operador pagará los intereses junto con el monto vencido; y</p>
                <p className={style.text}>4.4.2 TipsSi podrá suspender todos los Servicios hasta que se haya realizado el pago en su totalidad.</p>
                <p className={style.text}>4.5 A la terminación de este Acuerdo, todas las sumas pagaderas a TipsSi vencerán inmediatamente (sin perjuicio de cualquier otra disposición de este Acuerdo).</p>
                <p className={style.text}>4.6 El Operador pagará todos los montos adeudados en virtud de este Acuerdo en su totalidad sin ninguna deducción o retención, excepto según lo exija la ley, y ninguna de las partes tendrá derecho a hacer valer ningún crédito, compensación o reconvención contra la otra para justificar la retención del pago de cualquier cantidad adeudada, total o parcialmente.</p>
                <div className={style.title}>5. Responsabilidad y Seguro</div>
                <p className={style.text}>5.1 Si el cumplimiento de las obligaciones de TipsSi en virtud de este Acuerdo se ve impedido o retrasado por cualquier acto u omisión del Operador, sus agentes, subcontratistas, consultores o empleados, TipsSi no será responsable de ningún costo, cargo o pérdida sufrida o incurrida por el Operador que se deriven directa o indirectamente de dicha prevención o retraso.</p>
                <p className={style.text}>5.2 Nada en este Acuerdo limita o excluye la responsabilidad de cualquiera de las partes por muerte o lesiones personales causadas por su negligencia;</p>
                <p className={style.text}>5.3 Ninguna de las partes será responsable ante la otra parte, ya sea por contrato, agravio (incluida la negligencia), por incumplimiento de obligaciones legales o de otro modo, que surja de o en relación con este Acuerdo por:</p>
                <p className={style.text}>5.3.1 lucro cesante</p>
                <p className={style.text}>5.3.2 pérdida de ventas o negocios;</p>
                <p className={style.text}>5.3.3 pérdida de acuerdos o contratos;</p>
                <p className={style.text}>5.3.4 pérdida de ahorros anticipados;</p>
                <p className={style.text}>5.3.5 pérdida o daño del fondo de comercio;</p>
                <p className={style.text}>5.3.6 pérdida de uso o corrupción de software, datos o información; o</p>
                <p className={style.text}>5.3.7 cualquier pérdida indirecta o consecuente.</p>
                <div className={style.title}>6. Confidencialidad</div>
                <p className={style.text}>6.1 Cada parte solo utilizará la Información confidencial para cumplir con sus obligaciones en virtud del Acuerdo y no causará ni permitirá que se divulgue la información, excepto:</p>
                <p className={style.text}>6.1.1 cuando lo exija la ley, una orden judicial o cualquier organismo gubernamental o regulador;</p>
                <p className={style.text}>6.1.2 a cualquiera de sus empleados, funcionarios, subcontratistas, representantes o asesores que necesiten conocer la información para cumplir con sus obligaciones en virtud del Acuerdo y acuerden usar la información solo para ese propósito y no causar ni permitir la divulgación de esa información;</p>
                <p className={style.text}>6.1.3 cuando la información esté generalmente disponible para el público (que no sea como resultado de la divulgación en incumplimiento del Acuerdo por parte de la parte o cualquiera de sus empleados, funcionarios, subcontratistas, representantes o asesores);</p>
                <p className={style.text}>6.1.4 cuando la información estaba disponible o era conocida de manera no confidencial antes de ser divulgada en virtud del Acuerdo; o</p>
                <p className={style.text}>6.1.5 cuando la información fue desarrollada por o para él independientemente del Acuerdo y es recibida por personas que no son la parte divulgadora.</p>
                <div className={style.title}>7. Propiedad Intelectual</div>
                <p className={style.text}>7.1 Sujeto a la cláusula a continuación, TipsSi se reserva y posee todos los Derechos de Propiedad Intelectual que puedan subsistir o surgir en relación con la prestación de los Servicios por parte de la plataforma TipsSi. TipsSi se reserva el derecho de tomar las medidas que sean apropiadas para restringir o prevenir la infracción de tales Derechos de Propiedad Intelectual.</p>
                <p className={style.text}>7.2 TipsSi otorga licencias de todos esos derechos al Operador de manera no exclusiva, no sublicenciable y no asignable en la medida en que sea necesario para permitir que el Operador haga un uso razonable de la Plataforma y los Servicios de acuerdo con este Acuerdo.</p>
                <p className={style.text}>7.3 Si se rescinde este Acuerdo, esta licencia se rescindirá automáticamente.</p>
                <div className={style.title}>8. Circunstancias fuera del control de cualquiera de las partes</div>
                <p className={style.text}>8.1 Ninguna de las partes será responsable de ningún incumplimiento o retraso en el cumplimiento de sus obligaciones cuando dicho incumplimiento o retraso resulte de cualquier causa que esté más allá del control razonable de esa parte.</p>
                <p className={style.text}>8.2 Dichas causas incluyen, pero no se limitan a: fallas en el suministro eléctrico, fallas en el proveedor de servicios de Internet, actos de Dios, epidemias, pandemias, disturbios civiles, incendios, inundaciones, sequías, tormentas, terremotos, derrumbe de edificios, explosiones o accidentes, actos de terrorismo, actos de guerra, acción gubernamental, cualquier ley o acción tomada por un gobierno o autoridad pública, incluida, entre otras, la imposición de una restricción, cuota o prohibición de exportación o importación, o cualquier otro evento que esté fuera del control de la parte en cuestión.</p>
                <p className={style.text}>8.3 La parte afectada por una circunstancia ajena a su control hará todos los esfuerzos razonables para mitigar el efecto de la fuerza mayor en el cumplimiento de sus obligaciones.</p>
                <p className={style.text}>8.4 Las obligaciones correspondientes de la otra parte quedarán suspendidas en la misma medida que las de la parte afectada por un evento de fuerza mayor.</p>
                <p className={style.text}>8.5 Si la demora continúa por un período de 90 días o más, cualquiera de las partes podrá rescindir o cancelar los Servicios que se llevarán a cabo bajo este Acuerdo.</p>
                <div className={style.title}>9. Terminación</div>
                <p className={style.text}>9.1 Cualquiera de las partes puede rescindir este Acuerdo dando a la otra parte un aviso por escrito con 90 días de anticipación, dicho aviso no vencerá antes del Plazo inicial.</p>
                <p className={style.text}>9.2 En caso de que el Operador entregue un aviso, el Operador reconoce que TipsSi informará a sus usuarios que el Operador ha entregado el aviso y especificará el período de aviso que se ha entregado.</p>
                <p className={style.text}>9.3 Una parte puede rescindir el Acuerdo de inmediato mediante notificación por escrito a la otra parte si esa otra parte:</p>
                <p className={style.text}>9.4 no paga ninguna suma adeudada en virtud del Acuerdo dentro de los 30 días posteriores a la fecha de vencimiento del pago;</p>
                <p className={style.text}>9.5 comete un incumplimiento sustancial del Acuerdo que, si es posible subsanar, no lo subsana dentro de los 30 días posteriores a la notificación por escrito que especifica todos los detalles del incumplimiento y solicita su subsanación;</p>
                <p className={style.text}>9.6 incumple persistentemente cualquier término del Acuerdo;</p>
                <p className={style.text}>9.7 se disuelve, deja de realizar sustancialmente todos sus negocios o se vuelve incapaz de pagar sus deudas a su vencimiento;</p>
                <p className={style.text}>9.8 (si un individuo) muere o como resultado de una enfermedad o incapacidad se vuelve incapaz de manejar sus propios asuntos.</p>
                <div className={style.title}>10. Consecuencias de la rescisión</div>
                <p className={style.text}>10.1 A la terminación o expiración de este Acuerdo:</p>
                <p className={style.text}>10.1.1 el Operador deberá pagar inmediatamente a TipsSi todas las facturas pendientes de pago e intereses y, con respecto a los Servicios prestados pero para los cuales no se haya presentado ninguna factura, TipsSi podrá presentar una factura, que será pagadera inmediatamente después de la recepción;</p>
                <p className={style.text}>10.1.2 TipsSi continuará cumpliendo con su obligación de pasarles las Propinas y las Gratificaciones recolectadas por los usuarios.</p>
                <div className={style.title}>11. Ley aplicable y jurisdicción</div>
                <p className={style.text}>11.1 Estos Términos se regirán e interpretarán de acuerdo con las leyes de España y todas las disputas que surjan en virtud de los Términos (incluidas las disputas o reclamaciones no contractuales) estarán sujetas a la jurisdicción exclusiva de los tribunales de Barcelona.</p>
        </div>
    );
}
export default TermsAndConditionsComponent;
import React from 'react';
import style from './WithdrawalOfFunds.module.scss'
import image from '../../assets/images/withdraw-funds.png'
import {useSelector} from "react-redux";
import SectionSmallTitle from "../SectionSmallTitle";
import SectionDescription from "../SectionDescription";
function WithdrawalOfFunds(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    return (
        <section className={darkMode ? style.withdrawal_of_funds + " dark-section-bg light-text" : style.withdrawal_of_funds}>
            <div className="container">
                <div className={darkMode ? style.withdrawal_of_funds_items + " row d-flex  justify-content-between dark-block-bg light-text" : style.withdrawal_of_funds_items + " row d-flex  justify-content-between"}>
                    <div className={style.withdrawal_of_funds_content + " col-md-6 col-12"} data-aos="zoom-in" data-aos-duration="1500">
                        <SectionSmallTitle title="Withdrawal of funds" marginBottom={32}/>
                        <SectionDescription text="Money is withdrawn instantly. You will be asked to provide us with your Card Number"/>
                    </div>
                    <div className={"col-md-6 col-12 p-0 " + style.withdrawal_of_funds_image} data-aos="fade-left" data-aos-duration="1500"><img src={image} alt="Payment"/></div>
                </div>
            </div>
        </section>
    );
}

export default WithdrawalOfFunds;
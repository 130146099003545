import React from 'react';
import {useSelector} from "react-redux";

export default function GoodFood({active, scale, width}) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const fillColor = darkMode ? '#fff' : '#1F3150';
    const fillColor2 = active ? "#0666EB" : '#A3ABB7';

    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512" width={width} style={{transform: `scale(${scale})`, transition: ".2s"}}>
            <g>
                <path style={{ fill: fillColor2 }} d="M270,155.3c-56.4,0-102.1,45.7-102.1,102.1c0,56.4,45.7,102.1,102.1,102.1s102.1-45.7,102.1-102.1   C372.1,201,326.4,155.3,270,155.3z"/>
                <g>
                    <path style={{ fill: fillColor2 }} d="M175.9,168.6c23.6-25,57-40.7,94-40.7c50.3,0,93.9,28.9,115.4,70.9v-24.6c0-16.9,3.4-32.4,8.8-46.3    c-32.3-31-76-50.1-124.2-50.1c-39.9,0-76.7,13.2-106.5,35.3C168.1,134.8,173.2,157.8,175.9,168.6z"/>
                    <path style={{ fill: fillColor2 }} d="M430.7,332.6c-18.2,0-32.4-12.8-39.8-29.2c-18.6,48.8-65.7,83.6-121,83.6c-71.4,0-129.6-58.1-129.6-129.6    c0-1,0.1-2,0.2-3c-5.6,3.3-12,6.3-19.6,8.7v94.7c32.3,47.8,87,79.4,149,79.4c72.2,0,134.4-42.9,163-104.5H430.7z"/>
                </g>
                <g>
                    <path style={{ fill: fillColor }} d="M488.9,65c-13.2,2.4-78.5,41.6-78.5,109.2c0,28.8,0,88.9,0,103.5c0,14.7,9.5,29.8,20.3,29.8    c10.9,0,42.6,0,42.6,0s0.9,97.9,0.9,120.1c0,25.4,36.9,26.1,36.9,0.7c0-18.9,0-328.9,0-344.5S502.1,62.6,488.9,65z"/>
                    <path style={{ fill: fillColor }} d="M128.6,68.8c-0.7-5-11-6-11.7,0.4s-7.1,105-7.1,105H95.4C92.1,148.4,83.1,73.4,82.5,68.8    c-0.7-5-11-6-11.7,0.4c-0.6,5.3-7.5,80.6-9.8,105H45c0,0-6.4-98.6-7.1-105c-0.7-6.4-11-5.3-11.7-0.4c-0.7,5-19.1,91.8-24.1,109.9    c-4.6,16.6,1.2,57.7,57.4,64.3v186.4c0,10,8.1,18.2,18.2,18.2s18.2-8.1,18.2-18.2V242.9c55.6-6.8,61.3-47.6,56.8-64.2    C147.7,160.6,129.3,73.7,128.6,68.8z"/>
                </g>
            </g>
        </svg>
    );
}

import React from 'react';

function TipsSiToBizumIcon({color, iconColor}) {
    return (
        <svg
            version="1.1"
            viewBox="0 0 87.9 42.5"
            height={33}
        >
            <style type="text/css">
                {`
                .st7{fill-rule:evenodd;clip-rule:evenodd;fill:${iconColor};}
                .st5{fill:${color};}
                .st8{fill:#FFFFFF;}
                .st9{fill:#B2B2B2;}
                `}
            </style>
            <g>
                <path className="st7" d="M65.5,17c1.3,1,3.1,0.7,4.1-0.6l3.4-4.7c1-1.3,0.7-3.1-0.6-4.1c-1.3-1-3.1-0.7-4.1,0.6l-3.5,4.7 C63.9,14.2,64.2,16.1,65.5,17z M81.2,10.3c-1.3-1-3.1-0.7-4.1,0.6L63.3,29.9c-1,1.3-0.7,3.1,0.6,4.1c1.3,1,3.1,0.7,4.1-0.6 l13.8-18.9C82.8,13.1,82.5,11.3,81.2,10.3z M64.2,8.8c1-1.3,0.7-3.1-0.6-4.1c-1.3-1-3.1-0.7-4.1,0.6c-1,1.3-0.7,3.1,0.6,4.1 C61.4,10.4,63.3,10.1,64.2,8.8z M85.1,34.9c-1.3-1-3.1-0.7-4.1,0.6c-1,1.3-0.7,3.1,0.6,4.1c1.3,1,3.1,0.7,4.1-0.6 C86.7,37.6,86.4,35.8,85.1,34.9z M79.7,27.3c-1.3-1-3.1-0.7-4.1,0.6l-3.5,4.7c-1,1.3-0.7,3.1,0.6,4.1c1.3,1,3.1,0.7,4.1-0.6 l3.5-4.7C81.3,30.1,81.1,28.3,79.7,27.3z"/>
                <g>
                    <path className="st5" d="M14.7,41.9c-0.6-0.1-1.1-0.1-1.7-0.2c-2.3-0.2-4.5-0.6-6.6-1.6c-2.6-1.3-4.1-3.3-4.6-6.1 c-0.3-1.7-0.2-3.5,0-5.2c0.6-3.8,2-7.2,4.2-10.4c1.6-2.3,3.4-4.4,5.9-5.8c3.5-1.9,6.9-1.8,10.3,0.3c2.2,1.4,3.9,3.2,5.4,5.4 c1.8,2.6,3.1,5.5,3.9,8.6c0.5,2,0.8,4,0.6,6c-0.3,3.3-1.8,5.7-4.7,7.2c-1.7,0.8-3.5,1.3-5.3,1.5c-0.9,0.1-1.8,0.2-2.7,0.3 c-0.1,0-0.2,0-0.3,0.1C17.6,41.9,16.1,41.9,14.7,41.9z"/>
                    <path className="st5" d="M19.8,8.7c-2-0.6-4-0.6-6.1,0c-0.3-0.9-0.5-1.8-0.8-2.7c-0.4-1.4-0.8-2.7-1.2-4.1 c-0.2-0.7,0-1.2,0.5-1.3c0.3-0.1,0.6,0,0.9,0.1c1.1,0.5,2.2,1.1,3.3,1.7c0.3,0.2,0.5,0.1,0.7,0c1.1-0.6,2.2-1.2,3.3-1.8 c0.7-0.3,1.4,0,1.4,0.7c0,0.2,0,0.3-0.1,0.5c-0.7,2.2-1.3,4.4-2,6.6C19.9,8.6,19.9,8.6,19.8,8.7z"/>
                    <path className="st8" d="M16.8,36.1c-4.5,0-8.2-3.7-8.2-8.2c0-0.1,0-0.1,0-0.2l0-0.1l1.9,0.1l0,0.1c0,0,0,0,0,0.1 c0,3.5,2.8,6.3,6.3,6.3s6.3-2.8,6.3-6.3c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l1.9-0.1l0,0.1c0,0.1,0,0.1,0,0.2 C25,32.4,21.4,36.1,16.8,36.1z"/>
                </g>
                <path className="st9" d="M56.3,24c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3 c0-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1l-7.5-7.5c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l5,5H38c-0.8,0-1.4,0.6-1.4,1.4 s0.6,1.4,1.4,1.4h13.5l-5,5c-0.6,0.6-0.6,1.5,0,2c0.3,0.3,0.7,0.4,1,0.4s0.7-0.1,1-0.4l7.5-7.5c0,0,0-0.1,0.1-0.1 C56.2,24.2,56.3,24.1,56.3,24z"/>
            </g>
        </svg>
    );
}

export default TipsSiToBizumIcon;

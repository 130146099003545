import React, {useEffect, useState} from 'react';
import Notification from "../../notifications/Notification";
import {PhoneInput} from "react-international-phone";
import {
    IsConfirmCode,
    IsForgetPassGetUser,
    IsLogin,
    IsRegistration
} from "../../../redux/slices/getStartedSlice";
import {useDispatch, useSelector} from "react-redux";
import {forgotUser, IsLoading, SMSUrl} from "../../../redux/slices/userSlice";
import AuthService from "../../../services/AuthService";
import config from "../../../config";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
function ForgotPasswordGetUser(props) {
    const [isNotification, setIsNotification] = useState(false);
    const [notification, setNotification] = useState('');
    const [phone, setPhone] = useState('');
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.settings.darkMode);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const yesAccount = () => {
        dispatch(IsForgetPassGetUser(false))
        dispatch(IsRegistration(false))
        dispatch(IsConfirmCode(false))
        dispatch(IsLogin(true))
    }

    const deliver = async () => {
        dispatch(IsLoading(true))
        try {
            const result = await AuthService.getForgotPasswordUser(phone);
            dispatch(SMSUrl(`${config.BACKEND_URL}confirm-code`))
            navigate("/confirm")
            // dispatch(IsConfirmCode(true));
            // dispatch(IsForgetPassGetUser(false));
            dispatch(forgotUser(result.data[0]))
        }catch (err){
            setIsNotification(true);
            setNotification(err.response?.data?.message);
        }finally {
            dispatch(IsLoading(false))
        }
    }

    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                deliver();
            }
        };

        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [deliver]);

    return (
        <div className="get-started">
            <div className="get-started_title text-center col-md-5 col-12">{t("Forgotten password")}</div>
            <div className="get-started_form d-flex flex-column col-lg-3 col-md-6 col-sm-8 col-12">
                <div className={darkMode ? "dark-input" : ""}>
                    <PhoneInput
                        hideDropdown={true}
                        forceDialCode={true}
                        defaultCountry="es"
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        disableFocusAfterCountrySelect={true}
                        required={true}
                    />
                </div>
                {/*{isNotification && <Notification message={notification} BGcolor="#D90012" isActive={isNotification ? "active" : ""}/>}*/}
                <Button variant="contained" className="send-button mb-0" onClick={deliver}>{t("Deliver")}</Button>
                <div className="dont-have-account">{t("Do you already have an account?")}&nbsp;&nbsp;<Link to="/sign-in" style={{color: "#0666EB"}} >{t("Log in")}</Link></div>
            </div>
        </div>
    );
}

export default ForgotPasswordGetUser;
import React, {useEffect} from 'react';
import SignUpComponent from "../components/get-started-components/sign-up/SignUpComponent"
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
function SignUp(props) {
    const darkMode = useSelector((state) => state.settings.darkMode);
    const location = useLocation(); // Используйте useLocation для получения пути

    useEffect(() => {
        document.title = "Registration - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);

    return (
        <div className={darkMode ? "get-started bg-white dark-section-bg light-text" : "get-started bg-white"}>
            <div className="container">
                <div className="row">
                    <SignUpComponent/>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
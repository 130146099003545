import React, {useEffect} from 'react';
import ProfileHeader from "../components/profile-components/profile-header/ProfileHeader";
import TransferContent from "../components/profile-components/transfer-components/TransferContent";
import {useLocation} from "react-router-dom";

function Transfer(props) {
    const location = useLocation();

    useEffect(() => {
        document.title = "Transfer - TipsSi";
        return () => {
            document.title = "TipsSi";
        };
    }, [location.pathname]);
    return (
        <section className="transfer pt-0">
            <div className="container">
                <ProfileHeader/>
                <TransferContent/>
            </div>
        </section>
    );
}

export default Transfer;